import logo from '../../assets/logo_new.png'
const Header = () => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', alignItems: 'center' }}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          className="logo-large-new"
          src={logo}
          alt="TransforML Logo"
          style={{
            width: '50%',
            height: 'auto',
          }}
        />
      </div>
    </div>
  )
}

export default Header

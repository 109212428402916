import { useState, useEffect } from 'react'
import React, { useCallback } from 'react'
import ReactFlow, { addEdge, Controls, Background, useNodesState, useEdgesState } from 'reactflow'

import ApplicationLayout from '../../components/ApplicationLayout'
import Flow from './Flow'
import { getTeamLeaderList } from '../../api/services/employee.services'

import SelectSingleField from '../../components/CustomMultipleSelect/singleOption'

import { getListOfObjectivesFlow } from '../../api/services/objectives.services'
import InPageLoader from '../../components/InPageLoader/index'

import ActivitesModel from './modelComponent/activitesModel'
import './style.scss'
import CommentModel from './modelComponent/messageModel'
import { has } from 'lodash'
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom'
import { routes } from '../../utils/routes'

export default function ObjectiveMapByLeader({
  objectiveList,
  activitiesModalClickHander,
  setIsObjHistoryOpen,
  setObjId,
  setIsAddEditObjModalOpen,
  setIsL1L2ModalOpen,
  setLastClickedLevel,
  editedObj,
  lastClickedLevel,
  setObjModalMode,
  setObjData,
  setActivitiesModalMode,
  setIsNotificationModalOpen,
  setIsRequestModalOpen,
  setIsViewFilesModalOpen,
  setIsKPIModalOpen,
  setOpenOnSpecificKpi,
  ShowDeleteObjectiveModal,
  ShowCompleteObjectiveModal,
  setIsGanttChartModalOpen,
  fromSetUpAgent,
  setIsAddEditMeetingTopicModalOpen,
  setIsCardModalOpen,
  isReviewMode = false,
  setSelectedTopicId,
  setSelectedTopicType,
  deleteMeetingTopic,
  setSelectedMeetingTopic,
  setIsAddL1Objective,
  setIsRecommendedObjStatementModalOpen,
}) {
  const [dateRange, setDateRange] = useState('')

  const [selectedTeamLeader, setSelectedTeamLeader] = useState([])
  const [teamLeaderList, setTeamLeaderList] = useState([])

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const [isLoading, setIsLoading] = useState(false)

  const [teamLeader, setTeamLeader] = useState([])

  const [objectiveData, setObjectiveData] = useState([])
  const [sharedObjData, setSharedObjData] = useState([])

  const [cardLevel10, setCardLevel10] = useState([])
  const [cardLevel1, setCardLevel1] = useState([])
  const [cardLevel2, setCardLevel2] = useState([])
  const [cardLevel3, setCardLevel3] = useState([])

  const [selectLevel10, setSelectLevel10] = useState([])
  const [selectLevel1, setSelectLevel1] = useState([])
  const [selectLevel2, setSelectLevel2] = useState([])
  const [selectLevel3, setSelectLevel3] = useState([])

  const [level10Value, setLevel10Value] = useState('')
  const [level1Value, setLevel1Value] = useState('')
  const [level2Value, setLevel2Value] = useState('')
  const [level3Value, setLevel3Value] = useState('')
  const [isDirectReport, setIsDirectReport] = useState(false)

  const [totalCardDisplay, setTotalCardDisplay] = useState([])
  const [main, setMain] = useState(0)

  const [objectiveId, setObjectiveId] = useState('')

  const [zoomIndexLevel1, setZoomIndexLevel1] = useState('')
  const [zoomIndexLevel2, setZoomIndexLevel2] = useState('')
  const [isFirstLeaderSelected, setIsFirstLeaderSelected] = useState(false)

  useEffect(() => {
    getLeaderList()
    getObjectiveFlow()
  }, [objectiveList])

  useEffect(() => {
    if (editedObj) {
      let tempCardLevel = []
      if (lastClickedLevel === 1) {
        tempCardLevel = [...cardLevel1]
      } else if (lastClickedLevel === 2) {
        tempCardLevel = [...cardLevel2]
      } else if (lastClickedLevel === 3) {
        tempCardLevel = [...cardLevel3]
      }

      for (let i = 0; i < tempCardLevel.length; i++) {
        if (parseInt(tempCardLevel[i]?.id) === editedObj?.objId) {
          tempCardLevel[i].goal = editedObj.statement
        }
      }

      if (lastClickedLevel === 1) {
        setCardLevel1(tempCardLevel)
      } else if (lastClickedLevel === 2) {
        setCardLevel2(tempCardLevel)
      } else if (lastClickedLevel === 3) {
        setCardLevel3(tempCardLevel)
      }

      setFlowData()
    }
  }, [editedObj])

  const navigate = useNavigate()
  const currentLocation = useLocation()

  // useEffect(() => {
  //   // setNodes([]);
  //   setLevel1Value('');
  //   setLevel2Value('');
  //   setLevel3Value('');
  //   getObjectiveFlow()
  // }, [selectedTeamLeader, dateRange])

  async function getLeaderList() {
    setIsLoading(true)
    const result = await getTeamLeaderList('?level=')
    setIsLoading(false)
    if (result && result?.employees) setTeamLeaderList(result?.employees)
  }

  async function getObjectiveFlow() {
    setIsLoading(true)
    const result = objectiveList
    setIsLoading(false)

    let finalCard = []

    if (result && result.levelCards) {
      setObjectiveData(result.levelCards)
      setSharedObjData(result.sharedCardsGroup)
      for (let x = 0; result.levelCards.length > x; x++) {
        const obj = result.levelCards[x]
        // set drop down data point
        if (obj.level == 1)
          setLevelDropValue(obj.thisLevelSegementDisplayNames, obj.thisLevelSegementIds, obj.level)
        // TODO: If we decide to show level 10 conditionally (not send from backend in some cases),
        // this needs to be made conditional.
        if (obj.level == 10) setLevel10Value(obj.thisLevelSegementIds[0])
        // set cards array.
        if (
          obj.cardDisplayGroups &&
          obj.cardDisplayGroups.length > 0 &&
          obj.cardDisplayGroups[0].cards &&
          obj.cardDisplayGroups[0].cards.length > 0
        ) {
          const cards = obj.cardDisplayGroups
          let temp = []
          for (let y = 0; cards.length > y; y++) {
            temp = temp.concat(cards[y].cards)
            finalCard = finalCard.concat(cards[y].cards)
          }

          if (obj.level == 10) {
            setCardLevel10(temp)
          } else if (obj.level == 1) {
            setCardLevel1(temp)
          } else if (obj.level == 2) {
            setCardLevel2(temp)
          } else if (obj.level == 3) {
            setCardLevel3(temp)
          }
        }
      }
    }
    setTotalCardDisplay(finalCard)

    const finalEdges = []
    // Create Edges
    for (let y = 0; finalCard.length > y; y++) {
      const mainObj = { ...finalCard[y] }
      if (mainObj.parentId != 0) {
        const dataEdges = {
          id: `e2-${mainObj.parentId}-${mainObj.id}`,
          source: `${mainObj.parentId}`,
          target: `${mainObj.id}`,
          // type: "step",
          style: {
            stroke: '#737373',
            strokeWidth: 4,
          },
        }
        finalEdges.push(dataEdges)
      }
    }
    setEdges(finalEdges)
  }

  function setLevelDropValue(titleArray, numberArray, levelType, isDirectReportArray = true) {
    const finalArray = []
    for (let x = 0; titleArray.length > x; x++) {
      const value = isDirectReportArray[x] == false ? titleArray[x] + '*' : titleArray[x]
      const id = numberArray[x]
      const isDirectReport = isDirectReportArray[x]

      finalArray.push({ value, id, isDirectReport })
    }
    if (levelType === 1) {
      setSelectLevel1(finalArray)
    }
    if (levelType === 2) {
      setSelectLevel2(finalArray)
    }
    if (levelType === 3) {
      setSelectLevel3(finalArray)
    }
  }

  useEffect(() => {
    if (fromSetUpAgent) {
      dropDownChangedHandler({ target: { value: cardLevel1[0]?.ownerId } }, 1, 2)
      setIsFirstLeaderSelected(true)
    }
  }, [totalCardDisplay])

  useEffect(() => {
    if (fromSetUpAgent) {
      dropDownChangedHandler({ target: { value: cardLevel2[0]?.ownerId } }, 2, 3)
    }
  }, [isFirstLeaderSelected])

  useEffect(() => {
    setFlowData()
  }, [
    level1Value,
    level2Value,
    level3Value,
    main,
    totalCardDisplay,
    zoomIndexLevel1,
    zoomIndexLevel2,
  ])

  function setFlowData() {
    // If there is level 10, others will move down.
    let levelPositionsY =
      cardLevel10.length > 0
        ? {
            10: 10,
            1: 250,
            2: 500,
            3: 750,
          }
        : {
            1: 10,
            2: 250,
            3: 500,
          }

    let finalNode = []
    const finalEdges = []

    let nodesAdded10 = []
    let nodesAdded1 = []
    let nodesAdded2 = []
    let nodesAdded3 = []

    const cards = [...totalCardDisplay]

    let count = 0

    // if (level10Value !== '') {
    // Level 10
    for (let x = 0; cardLevel10.length > x; x++) {
      const mainObj = { ...cardLevel10[x] }
      mainObj.main = 10
      mainObj.value = level10Value

      // if (mainObj.ownerId == level1Value) {
      const dataNode = {
        id: `${mainObj?.id}`,
        type: 'customNode',
        data: mainObj,
        position: { x: 220 * (count + 1), y: levelPositionsY[10] },
      }
      ++count
      nodesAdded10.push(dataNode)
      // }
    }
    // }

    count = 0
    if (level1Value !== '') {
      // const value = cardLevel1.findIndex(x => x.ownerId == level1Value);

      // if(value >= 0){

      //     const mainObj = cardLevel1[value];
      //     mainObj.main = 1;
      //     mainObj.value = level1Value;
      //     const dataNode = {
      //       id: `${mainObj?.id}`,
      //       type: 'customNode',
      //       data: mainObj,
      //       position: { x: (220 * (count + 1)), y: 10 },

      //     }
      //     ++count
      //     nodesAdded1.push(dataNode);
      // }

      // Level 1
      // We need to reorder cards such that the selected CXO's cards are first.
      let orderedL1Cards = []
      for (let x = 0; cardLevel1.length > x; x++) {
        const mainObj = { ...cardLevel1[x] }
        mainObj.main = 1
        mainObj.value = level1Value

        // mainObj.ShowCommentModel = ShowCommentModel
        mainObj.ShowActivitesModal = ShowActivitesModal
        mainObj.ShowHistoryModal = ShowHistoryModal
        mainObj.ShowOverdueModal = ShowOverdueModal
        mainObj.ShowNotificationsModal = ShowNotificationsModal
        mainObj.ShowEditModal = ShowEditModal
        mainObj.ShowAddModal = ShowAddModal
        mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
        mainObj.setZoomIndex = setZoomIndex
        mainObj.zoomIndexLevel = zoomIndexLevel1
        mainObj.ShowFilesModal = ShowFilesModal
        mainObj.ShowKPIModal = ShowKPIModal
        mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
        mainObj.ShowChangelogPage = ShowChangelogPage
        mainObj.ShowGanttChartModal = ShowGanttChartModal
        mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
        mainObj.isReviewMode = isReviewMode
        mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

        orderedL1Cards.push(mainObj)
      }

      // Sort cards by whether they belong to the selected CXO
      orderedL1Cards = orderedL1Cards.sort((a, b) => {
        if (a.ownerId == level1Value && b.ownerId != level1Value) return -1
        if (a.ownerId != level1Value && b.ownerId == level1Value) return 1
        return 0
      })

      for (let mainObj of orderedL1Cards) {
        const dataNode = {
          id: `${mainObj?.id}`,
          type: 'customNode',
          data: { ...mainObj },
          position: { x: 220 * (count + 1), y: levelPositionsY[1] },
        }

        ++count
        nodesAdded1.push(dataNode)
      }
    }

    // Level 3
    count = 0
    if (level3Value !== '') {
      for (let x = 0; cardLevel3.length > x; x++) {
        const mainObj = { ...cardLevel3[x] }

        mainObj.main = 3
        mainObj.value = level3Value

        //mainObj.ShowCommentModel = ShowCommentModel
        mainObj.ShowActivitesModal = ShowActivitesModal
        mainObj.ShowHistoryModal = ShowHistoryModal
        mainObj.ShowOverdueModal = ShowOverdueModal
        mainObj.ShowNotificationsModal = ShowNotificationsModal
        mainObj.ShowEditModal = ShowEditModal
        mainObj.ShowAddModal = ShowAddModal
        mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
        mainObj.ShowFilesModal = ShowFilesModal
        mainObj.ShowKPIModal = ShowKPIModal
        mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
        mainObj.ShowChangelogPage = ShowChangelogPage
        mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
        mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
        mainObj.ShowGanttChartModal = ShowGanttChartModal
        mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
        mainObj.isReviewMode = isReviewMode
        mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

        if (mainObj.ownerId == level3Value) {
          const dataNode = {
            id: `${mainObj?.id}`,
            type: 'customNode',
            data: mainObj,
            position: { x: 220 * (count + 1), y: levelPositionsY[3] },
          }
          let topicCount = 0
          if (isReviewMode && mainObj.topicList.length > 0) {
            for (let topic of mainObj.topicList) {
              topic.ShowEditTopicModal = ShowEditTopicModal
              topic.ShowCardTopicModal = ShowCardTopicModal
              topic.ShowDeleteTopic = ShowDeleteTopic
              const topicNode = {
                id: `${mainObj?.id}-topic-${topicCount}`,
                type: 'topicNode',
                data: topic,
                position: {
                  x: 220 * (count + 1),
                  y: levelPositionsY[3] + 100 + 150 * (topicCount + 1),
                },
              }
              nodesAdded2.push(topicNode)
              ++topicCount
            }
          }
          ++count
          nodesAdded3.push(dataNode)
        }
      }

      // We also need to add shared objectives to the map.
      // As shared objectives have the same id as the original objective, we need to add a suffix to the id.
      for (const sharedInfo of sharedObjData) {
        const { ownerId, ownerName, objIds } = sharedInfo
        if (ownerId == level3Value) {
          for (const objId of objIds) {
            const sharedCard = cardLevel3.find((card) => card.id == objId)
            if (sharedCard) {
              sharedCard.sharedOwnerId = ownerId
              const updatedCard = { ...sharedCard }
              updatedCard.main = 3
              updatedCard.value = level3Value
              updatedCard.ownerId = ownerId
              updatedCard.ownerName = ownerName
              updatedCard.isShared = true
              updatedCard.ShowActivitesModal = ShowActivitesModal
              updatedCard.ShowHistoryModal = ShowHistoryModal
              updatedCard.ShowOverdueModal = ShowOverdueModal
              updatedCard.ShowNotificationsModal = ShowNotificationsModal
              updatedCard.ShowEditModal = ShowEditModal
              updatedCard.ShowAddModal = ShowAddModal
              updatedCard.ShowRequestUpdateModal = ShowRequestUpdateModal
              updatedCard.ShowFilesModal = ShowFilesModal
              updatedCard.ShowKPIModal = ShowKPIModal
              updatedCard.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
              updatedCard.ShowChangelogPage = ShowChangelogPage
              updatedCard.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
              updatedCard.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
              updatedCard.ShowGanttChartModal = ShowGanttChartModal
              updatedCard.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
              updatedCard.isReviewMode = isReviewMode
              updatedCard.ShowRecommendedObjStatementModalOpen =
                ShowRecommendedObjStatementModalOpen

              const dataNodeShared = {
                id: `${updatedCard?.id}_shared`,
                type: 'customNode',
                data: updatedCard,
                position: { x: 220 * (count + 1), y: levelPositionsY[3] },
              }
              ++count
              nodesAdded3.push(dataNodeShared)
              cardLevel3.push(updatedCard)
            }
          }
        }
      }
    }

    // Level 2
    count = 0
    if (level2Value !== '') {
      if (nodesAdded3.length === 0 && level3Value === '') {
        for (let x = 0; cardLevel2.length > x; x++) {
          const mainObj = { ...cardLevel2[x] }

          mainObj.main = 2
          mainObj.value = level2Value

          //mainObj.ShowCommentModel = ShowCommentModel
          mainObj.ShowActivitesModal = ShowActivitesModal
          mainObj.ShowHistoryModal = ShowHistoryModal
          mainObj.ShowOverdueModal = ShowOverdueModal
          mainObj.ShowNotificationsModal = ShowNotificationsModal
          mainObj.ShowEditModal = ShowEditModal
          mainObj.ShowAddModal = ShowAddModal
          mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
          mainObj.setZoomIndex = setZoomIndex
          mainObj.zoomIndexLevel = zoomIndexLevel2
          mainObj.ShowFilesModal = ShowFilesModal
          mainObj.ShowKPIModal = ShowKPIModal
          mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
          mainObj.ShowChangelogPage = ShowChangelogPage
          mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
          mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
          mainObj.ShowGanttChartModal = ShowGanttChartModal
          mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
          mainObj.isReviewMode = isReviewMode
          mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

          if (mainObj.ownerId == level2Value) {
            const dataNode = {
              id: `${mainObj?.id}`,
              type: 'customNode',
              data: mainObj,
              position: { x: 220 * (count + 1), y: levelPositionsY[2] },
            }

            let topicCount = 0
            if (isReviewMode && mainObj.topicList.length > 0) {
              for (let topic of mainObj.topicList) {
                topic.ShowEditTopicModal = ShowEditTopicModal
                topic.ShowCardTopicModal = ShowCardTopicModal
                topic.ShowDeleteTopic = ShowDeleteTopic
                const topicNode = {
                  id: `${mainObj?.id}-topic-${topicCount}`,
                  type: 'topicNode',
                  data: topic,
                  position: {
                    x: 220 * (count + 1),
                    y: levelPositionsY[2] + 100 + 150 * (topicCount + 1),
                  },
                }
                nodesAdded2.push(topicNode)
                ++topicCount
              }
            }
            ++count
            nodesAdded2.push(dataNode)
          }
        }
      } else {
        for (let y = 0; cardLevel2.length > y; y++) {
          const mainObj = { ...cardLevel2[y] }
          mainObj.main = 2
          mainObj.value = level2Value

          //mainObj.ShowCommentModel = ShowCommentModel
          mainObj.ShowActivitesModal = ShowActivitesModal
          mainObj.ShowHistoryModal = ShowHistoryModal
          mainObj.ShowOverdueModal = ShowOverdueModal
          mainObj.ShowNotificationsModal = ShowNotificationsModal
          mainObj.ShowEditModal = ShowEditModal
          mainObj.ShowAddModal = ShowAddModal
          mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
          mainObj.setZoomIndex = setZoomIndex
          mainObj.zoomIndexLevel = zoomIndexLevel2
          mainObj.ShowFilesModal = ShowFilesModal
          mainObj.ShowKPIModal = ShowKPIModal
          mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
          mainObj.ShowChangelogPage = ShowChangelogPage
          mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
          mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
          mainObj.ShowGanttChartModal = ShowGanttChartModal
          mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
          mainObj.isReviewMode = isReviewMode
          mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

          const value = nodesAdded3.findIndex((x) => x.data.parentId == mainObj.id)

          if (mainObj.ownerId == level2Value && value >= 0) {
            const dataNode = {
              id: `${mainObj?.id}`,
              type: 'customNode',
              data: mainObj,
              position: { x: 220 * (count + 1), y: levelPositionsY[2] },
            }

            let topicCount = 0
            if (isReviewMode && mainObj.topicList.length > 0) {
              for (let topic of mainObj.topicList) {
                topic.ShowEditTopicModal = ShowEditTopicModal
                topic.ShowCardTopicModal = ShowCardTopicModal
                topic.ShowDeleteTopic = ShowDeleteTopic
                const topicNode = {
                  id: `${mainObj?.id}-topic-${topicCount}`,
                  type: 'topicNode',
                  data: topic,
                  position: {
                    x: 220 * (count + 1),
                    y: levelPositionsY[2] + 100 + 150 * (topicCount + 1),
                  },
                }
                nodesAdded2.push(topicNode)
                ++topicCount
              }
            }
            ++count
            nodesAdded2.push(dataNode)
          }
        }
      }
    }

    // sorting level 2 when its populated
    count = 0
    if (nodesAdded1.length > 0 && level2Value != '') {
      var temp_nodesAdded2 = nodesAdded2
      nodesAdded2 = []

      // First added nodes ordered by parent. Unlinked nodes will come after that.
      let orphanL2NodeIds = new Set()

      for (let x = 0; nodesAdded1.length > x; x++) {
        const parentObj = { ...nodesAdded1[x] }
        for (let y = 0; temp_nodesAdded2.length > y; y++) {
          const mainObj = { ...temp_nodesAdded2[y].data }
          mainObj.main = 2
          mainObj.value = level2Value

          //mainObj.ShowCommentModel = ShowCommentModel
          mainObj.ShowActivitesModal = ShowActivitesModal
          mainObj.ShowHistoryModal = ShowHistoryModal
          mainObj.ShowOverdueModal = ShowOverdueModal
          mainObj.ShowNotificationsModal = ShowNotificationsModal
          mainObj.ShowEditModal = ShowEditModal
          mainObj.ShowAddModal = ShowAddModal
          mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
          mainObj.setZoomIndex = setZoomIndex
          mainObj.zoomIndexLevel = zoomIndexLevel2
          mainObj.ShowFilesModal = ShowFilesModal
          mainObj.ShowKPIModal = ShowKPIModal
          mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
          mainObj.ShowChangelogPage = ShowChangelogPage
          mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
          mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
          mainObj.ShowGanttChartModal = ShowGanttChartModal
          mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
          mainObj.isReviewMode = isReviewMode
          mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

          if (mainObj.ownerId == level2Value && parentObj.id == mainObj.parentId) {
            const dataNode = {
              id: `${mainObj?.id}`,
              type: 'customNode',
              data: mainObj,
              position: { x: 220 * (count + 1), y: levelPositionsY[2] },
            }

            let topicCount = 0
            if (isReviewMode && mainObj.topicList.length > 0) {
              for (let topic of mainObj.topicList) {
                topic.ShowEditTopicModal = ShowEditTopicModal
                topic.ShowCardTopicModal = ShowCardTopicModal
                topic.ShowDeleteTopic = ShowDeleteTopic
                const topicNode = {
                  id: `${mainObj?.id}-topic-${topicCount}`,
                  type: 'topicNode',
                  data: topic,
                  position: {
                    x: 220 * (count + 1),
                    y: levelPositionsY[2] + 100 + 150 * (topicCount + 1),
                  },
                }
                nodesAdded2.push(topicNode)
                ++topicCount
              }
            }
            ++count
            nodesAdded2.push(dataNode)
          } else if (mainObj.ownerId == level2Value && mainObj.parentId == 0) {
            // Orphan nodes belonging to the same owner (no linkage to L1 obj).
            orphanL2NodeIds.add(mainObj?.id)
          }
        }
      }

      // Collect all orphan nodes and add them to the end.
      for (let y = 0; temp_nodesAdded2.length > y; y++) {
        if (orphanL2NodeIds.has(temp_nodesAdded2[y].data.id)) {
          const mainObj = { ...temp_nodesAdded2[y].data }

          mainObj.main = 2
          mainObj.value = level2Value

          //mainObj.ShowCommentModel = ShowCommentModel
          mainObj.ShowActivitesModal = ShowActivitesModal
          mainObj.ShowHistoryModal = ShowHistoryModal
          mainObj.ShowOverdueModal = ShowOverdueModal
          mainObj.ShowNotificationsModal = ShowNotificationsModal
          mainObj.ShowEditModal = ShowEditModal
          mainObj.ShowAddModal = ShowAddModal
          mainObj.setZoomIndex = setZoomIndex
          mainObj.zoomIndexLevel = zoomIndexLevel2
          mainObj.ShowFilesModal = ShowFilesModal
          mainObj.ShowKPIModal = ShowKPIModal
          mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
          mainObj.ShowChangelogPage = ShowChangelogPage
          mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
          mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
          mainObj.ShowGanttChartModal = ShowGanttChartModal
          mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
          mainObj.isReviewMode = isReviewMode
          mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

          const dataNode = {
            id: `${mainObj?.id}`,
            type: 'customNode',
            data: mainObj,
            position: { x: 220 * (count + 1), y: levelPositionsY[2] },
          }

          let topicCount = 0
          if (isReviewMode && mainObj.topicList.length > 0) {
            for (let topic of mainObj.topicList) {
              topic.ShowEditTopicModal = ShowEditTopicModal
              topic.ShowCardTopicModal = ShowCardTopicModal
              topic.ShowDeleteTopic = ShowDeleteTopic
              const topicNode = {
                id: `${mainObj?.id}-topic-${topicCount}`,
                type: 'topicNode',
                data: topic,
                position: {
                  x: 220 * (count + 1),
                  y: levelPositionsY[2] + 100 + 150 * (topicCount + 1),
                },
              }
              nodesAdded2.push(topicNode)
              ++topicCount
            }
          }
          ++count
          nodesAdded2.push(dataNode)
        }
      }
    }

    // sorting level 3 when its populated
    // At this point, we may have cards whose parents exist but are not in the current level 2
    // e.g. when this card links to another AL's L2 objective. In that case, we have to include
    // those L2 cards before we sort the array.
    //
    // Scan nodesAdded3 and find cards who link to a different AL.

    let findCardByIdInSet = (id, allCards) => {
      for (let card of allCards) {
        if (card.id == id) return card
      }
      return null
    }

    if (level3Value !== '') {
      for (let y = 0; cardLevel3.length > y; y++) {
        const mainObj3 = { ...cardLevel3[y] }

        if (mainObj3.parentId === 0 || mainObj3.ownerId != level3Value) {
          continue
        }

        let missingInL2 = findCardByIdInSet(`${mainObj3.parentId}`, nodesAdded2) === null

        // If a TL obj maps to a dfferent AL's L2 objective, add that L2 objective here
        if (cardLevel2 !== undefined && missingInL2 && isDirectReport == 'true') {
          let l2CardRaw = findCardByIdInSet(mainObj3.parentId, cardLevel2)
          // If for some reason the L2 card is not in the list, we ignore it.
          if (l2CardRaw == null || l2CardRaw == undefined) {
            continue
          }
          let l2Card = { ...l2CardRaw }
          l2Card.main = 2
          l2Card.value = level2Value

          l2Card.ShowActivitesModal = ShowActivitesModal
          l2Card.ShowHistoryModal = ShowHistoryModal
          l2Card.ShowOverdueModal = ShowOverdueModal
          l2Card.ShowNotificationsModal = ShowNotificationsModal
          l2Card.ShowEditModal = ShowEditModal
          l2Card.ShowAddModal = ShowAddModal
          l2Card.setZoomIndex = setZoomIndex
          l2Card.zoomIndexLevel = zoomIndexLevel2
          l2Card.ShowFilesModal = ShowFilesModal
          l2Card.ShowKPIModal = ShowKPIModal
          l2Card.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
          l2Card.ShowChangelogPage = ShowChangelogPage
          l2Card.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
          l2Card.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
          l2Card.ShowGanttChartModal = ShowGanttChartModal
          l2Card.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
          l2Card.isReviewMode = isReviewMode
          l2Card.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

          const dataNode = {
            id: `${l2Card?.id}`,
            type: 'customNode',
            data: l2Card,
            position: { x: 220 * (count + 1), y: levelPositionsY[2] },
          }

          let topicCount = 0
          if (isReviewMode && l2Card.topicList.length > 0) {
            for (let topic of l2Card.topicList) {
              topic.ShowEditTopicModal = ShowEditTopicModal
              topic.ShowCardTopicModal = ShowCardTopicModal
              topic.ShowDeleteTopic = ShowDeleteTopic
              const topicNode = {
                id: `${l2Card?.id}-topic-${topicCount}`,
                type: 'topicNode',
                data: topic,
                position: {
                  x: 220 * (count + 1),
                  y: levelPositionsY[2] + 100 + 150 * (topicCount + 1),
                },
              }
              nodesAdded2.push(topicNode)
              ++topicCount
            }
          }

          ++count
          nodesAdded2.push(dataNode)
        }
      }
    }

    count = 0
    if (nodesAdded2.length > 0 && level3Value != '') {
      nodesAdded3 = []
      let orphanNodesIdx3 = new Set()

      for (let x = 0; nodesAdded2.length > x; x++) {
        const parentObj = { ...nodesAdded2[x] }
        for (let y = 0; cardLevel3.length > y; y++) {
          const mainObj = { ...cardLevel3[y] }
          mainObj.main = 3
          mainObj.value = level3Value

          //mainObj.ShowCommentModel = ShowCommentModel
          mainObj.ShowActivitesModal = ShowActivitesModal
          mainObj.ShowHistoryModal = ShowHistoryModal
          mainObj.ShowOverdueModal = ShowOverdueModal
          mainObj.ShowNotificationsModal = ShowNotificationsModal
          mainObj.ShowEditModal = ShowEditModal
          mainObj.ShowAddModal = ShowAddModal
          mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
          mainObj.ShowFilesModal = ShowFilesModal
          mainObj.ShowKPIModal = ShowKPIModal
          mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
          mainObj.ShowChangelogPage = ShowChangelogPage
          mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
          mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
          mainObj.ShowGanttChartModal = ShowGanttChartModal
          mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
          mainObj.isReviewMode = isReviewMode
          mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

          if (
            mainObj.parentId === 0 &&
            mainObj.ownerId == level3Value &&
            isDirectReport == 'true'
          ) {
            orphanNodesIdx3.add(y)
          }
          if (mainObj.ownerId == level3Value && parentObj.id == mainObj.parentId) {
            const dataNode = {
              id: `${mainObj?.id}`,
              type: 'customNode',
              data: mainObj,
              position: { x: 220 * (count + 1), y: levelPositionsY[3] },
            }
            let topicCount = 0
            if ((fromSetUpAgent || isReviewMode) && mainObj.topicList.length > 0) {
              for (let topic of mainObj.topicList) {
                topic.ShowEditTopicModal = ShowEditTopicModal
                topic.ShowCardTopicModal = ShowCardTopicModal
                topic.ShowDeleteTopic = ShowDeleteTopic
                const topicNode = {
                  id: `${mainObj?.id}-topic-${topicCount}`,
                  type: 'topicNode',
                  data: topic,
                  position: {
                    x: 220 * (count + 1),
                    y: levelPositionsY[3] + 100 + 150 * (topicCount + 1),
                  },
                }
                nodesAdded2.push(topicNode)
                ++topicCount
              }
            }
            ++count
            nodesAdded3.push(dataNode)
          }
        }
      }

      for (let orphanIdx of orphanNodesIdx3) {
        const mainObj = { ...cardLevel3[orphanIdx] }
        mainObj.main = 3
        mainObj.value = level3Value

        //mainObj.ShowCommentModel = ShowCommentModel
        mainObj.ShowActivitesModal = ShowActivitesModal
        mainObj.ShowHistoryModal = ShowHistoryModal
        mainObj.ShowOverdueModal = ShowOverdueModal
        mainObj.ShowNotificationsModal = ShowNotificationsModal
        mainObj.ShowEditModal = ShowEditModal
        mainObj.ShowAddModal = ShowAddModal
        mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
        mainObj.ShowFilesModal = ShowFilesModal
        mainObj.ShowKPIModal = ShowKPIModal
        mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
        mainObj.ShowChangelogPage = ShowChangelogPage
        mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
        mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
        mainObj.ShowGanttChartModal = ShowGanttChartModal
        mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
        mainObj.isReviewMode = isReviewMode
        mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

        if (mainObj.parentId === 0) {
          const dataNode = {
            id: `${mainObj?.id}`,
            type: 'customNode',
            data: mainObj,
            position: { x: 220 * (count + 1), y: levelPositionsY[3] },
          }
          let topicCount = 0
          if ((fromSetUpAgent || isReviewMode) && mainObj.topicList.length > 0) {
            for (let topic of mainObj.topicList) {
              topic.ShowEditTopicModal = ShowEditTopicModal
              topic.ShowCardTopicModal = ShowCardTopicModal
              topic.ShowDeleteTopic = ShowDeleteTopic
              const topicNode = {
                id: `${mainObj?.id}-topic-${topicCount}`,
                type: 'topicNode',
                data: topic,
                position: {
                  x: 220 * (count + 1),
                  y: levelPositionsY[3] + 100 + 150 * (topicCount + 1),
                },
              }
              nodesAdded2.push(topicNode)
              ++topicCount
            }
          }
          ++count
          nodesAdded3.push(dataNode)
        }
      }
    }

    // Level DropDown Setting
    if (true) {
      let dataNode = {
        id: `main-1`,
        type: 'dropDownEdge',
        data: {
          label: 'objective',
          view: 'objective',
          option: selectLevel1,
          value: fromSetUpAgent ? selectLevel1[0]?.id : level1Value,
          dropDownChangedHandler: dropDownChangedHandler,
          dropDown: true,
          main: 1,
          fromSetUpAgent: fromSetUpAgent,
        },
        position: { x: 10, y: levelPositionsY[1] },
      }
      finalNode.push(dataNode)
    }

    if (level1Value !== '') {
      let dataNode = {
        id: level2Value ? `change-handel-2` : 'main-2',
        type: 'dropDownEdge',
        data: {
          label: 'objective',
          view: 'objective',
          option: selectLevel2,
          value: fromSetUpAgent ? selectLevel2[0]?.id : level2Value,
          // value: level2Value,
          dropDownChangedHandler: dropDownChangedHandler,
          dropDown: true,
          main: 2,
        },
        position: { x: 10, y: levelPositionsY[2] },
      }
      finalNode.push(dataNode)

      let addLevel1Button = {
        id: `addLevel1ObjButton-1`,
        type: 'addLevel1ObjButton',
        view: 'objective',
        data: {
          label: 'button',
          view: 'button',
          addLevel1ObjButtonHandler: addLevel1ObjButtonHandler,
          main: 1,
        },
        position: { x: 10, y: levelPositionsY[1] + 80 },
      }
      finalNode.push(addLevel1Button)
    }

    if (level2Value !== '') {
      let dataNode = {
        id: level3Value ? `main-3` : level3Value,
        type: 'dropDownEdge',
        data: {
          label: 'objective',
          view: 'objective',
          option: selectLevel3,
          value: level3Value,
          dropDownChangedHandler: dropDownChangedHandler,
          dropDown: true,
          main: 3,
        },
        position: { x: 10, y: levelPositionsY[3] },
      }
      finalNode.push(dataNode)
    }

    // Zoom Index
    if (zoomIndexLevel1 != '') {
      let temp2 = []
      for (let y = 0; nodesAdded2.length > y; y++) {
        const mainObj = { ...nodesAdded2[y] }
        if (mainObj.data.parentId == zoomIndexLevel1.id) {
          temp2.push(mainObj)
        }
      }

      // Restart their x-positions:
      let count = 0
      for (let obj of temp2) {
        obj.position.x = 220 * (count + 1)
        ++count
      }
      nodesAdded2 = temp2
    }

    // Zoom Index
    if (zoomIndexLevel2 != '') {
      let temp3 = []
      for (let y = 0; nodesAdded3.length > y; y++) {
        const mainObj = { ...nodesAdded3[y] }
        if (mainObj.data.parentId == zoomIndexLevel2.id) {
          temp3.push(mainObj)
        }
      }
      nodesAdded3 = temp3
    }

    // // remove extra node in level 3 when magnify populated.
    count = 0
    if ((zoomIndexLevel1 != '' || zoomIndexLevel2 != '') && nodesAdded3.length > 0) {
      const temp_nodesAdded3 = nodesAdded3
      nodesAdded3 = []

      for (let y = 0; temp_nodesAdded3.length > y; y++) {
        const mainObj = { ...temp_nodesAdded3[y].data }
        mainObj.main = 3
        mainObj.value = level3Value

        //mainObj.ShowCommentModel = ShowCommentModel
        mainObj.ShowActivitesModal = ShowActivitesModal
        mainObj.ShowHistoryModal = ShowHistoryModal
        mainObj.ShowOverdueModal = ShowOverdueModal
        mainObj.ShowNotificationsModal = ShowNotificationsModal
        mainObj.ShowEditModal = ShowEditModal
        mainObj.ShowAddModal = ShowAddModal
        mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
        mainObj.ShowFilesModal = ShowFilesModal
        mainObj.ShowKPIModal = ShowKPIModal
        mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
        mainObj.ShowChangelogPage = ShowChangelogPage
        mainObj.ShowDeleteObjectiveModal = ShowDeleteObjectiveModal
        mainObj.ShowCompleteObjectiveModal = ShowCompleteObjectiveModal
        mainObj.ShowGanttChartModal = ShowGanttChartModal
        mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
        mainObj.isReviewMode = isReviewMode
        mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

        const value = nodesAdded2.findIndex((x) => x.data.id == mainObj.parentId)

        if (mainObj.ownerId == level3Value && value >= 0) {
          const dataNode = {
            id: `${mainObj?.id}`,
            type: 'customNode',
            data: mainObj,
            position: { x: 220 * (count + 1), y: levelPositionsY[3] },
          }
          ++count
          nodesAdded3.push(dataNode)
        }
      }
    }

    // Add Empty Node Index
    if (nodesAdded2.length === 0 && level2Value != '') {
      const dataNode = {
        id: `e2-1`,
        type: 'emptyNode',
        position: { x: 220 * (0 + 1), y: levelPositionsY[2] },
      }
      nodesAdded2.push(dataNode)
    }

    if ((nodesAdded3.length === 0 && level3Value != '') || cardLevel2.length === 0) {
      const dataNode = {
        id: `e2-2`,
        type: 'emptyNode',
        position: { x: 220 * (0 + 1), y: levelPositionsY[3] },
      }
      nodesAdded3.push(dataNode)
    }

    // If Level2 bigger then level1 (level1 must be centeral)
    count = 0
    if (nodesAdded2.length > nodesAdded1.length) {
      const temp1 = nodesAdded1
      // const startPosition = 220 * (nodesAdded2.length / 2);

      // Temporarily start from 0 because centering takes it too far to the right.
      const startPosition = 220

      nodesAdded1 = []
      for (let y = 0; temp1.length > y; y++) {
        const mainObj = { ...temp1[y].data }
        mainObj.main = 1
        mainObj.value = level1Value

        //mainObj.ShowCommentModel = ShowCommentModel
        mainObj.ShowActivitesModal = ShowActivitesModal
        mainObj.ShowHistoryModal = ShowHistoryModal
        mainObj.ShowOverdueModal = ShowOverdueModal
        mainObj.ShowNotificationsModal = ShowNotificationsModal
        mainObj.ShowEditModal = ShowEditModal
        mainObj.ShowAddModal = ShowAddModal
        mainObj.ShowRequestUpdateModal = ShowRequestUpdateModal
        mainObj.setZoomIndex = setZoomIndex
        mainObj.zoomIndexLevel = zoomIndexLevel1
        mainObj.ShowFilesModal = ShowFilesModal
        mainObj.ShowKPIModal = ShowKPIModal
        mainObj.ShowKPIModalSpecificKpi = ShowKPIModalSpecificKpi
        mainObj.ShowChangelogPage = ShowChangelogPage
        mainObj.ShowGanttChartModal = ShowGanttChartModal
        mainObj.ShowAddEditMeetingTopicModal = ShowAddEditMeetingTopicModal
        mainObj.isReviewMode = isReviewMode
        mainObj.ShowRecommendedObjStatementModalOpen = ShowRecommendedObjStatementModalOpen

        const on = startPosition + count * 220
        const dataNode = {
          id: `${mainObj?.id}`,
          type: 'customNode',
          data: mainObj,
          position: { x: on, y: levelPositionsY[1] },
        }
        ++count
        nodesAdded1.push(dataNode)
      }
    }

    finalNode = finalNode.concat(nodesAdded1)
    finalNode = finalNode.concat(nodesAdded2)
    finalNode = finalNode.concat(nodesAdded3)
    finalNode = finalNode.concat(nodesAdded10)

    setNodes(finalNode)
  }

  function addLevel1ObjButtonHandler() {
    let ownerId = level1Value
    setObjData({ ownerId: ownerId })
    setObjModalMode('add')
    setObjData({ ownerId: ownerId })
    setIsAddL1Objective(true)
    setObjId(null)
    setIsL1L2ModalOpen(true)
  }

  function dropDownChangedHandler(event, level, nextLevel, isDirectReport = undefined) {
    // setNodes([])
    setZoomIndexLevel1('')
    setZoomIndexLevel2('')

    if (level === 1) {
      setLevel1Value(event.target.value)
      setLevel2Value('')
      setLevel3Value('')

      if (objectiveData.length > 1) {
        const value = objectiveData.findIndex((x) => x.level == level)

        const downMappings = objectiveData[value].dropDownMappings
        for (let x = 0; downMappings.length > x; x++) {
          const obj = downMappings[x]
          if (obj.upperLevelPersonId == event.target.value) {
            setLevelDropValue(obj.lowerLevelPersonsTitles, obj.lowerLevelPersonsIds, 2)
            break
          } else {
            setLevelDropValue([], [], 2)
          }
        }
      }
    } else if (level === 2) {
      setLevel2Value(event.target.value)
      setLevel3Value('')

      if (objectiveData.length > 1) {
        const value = objectiveData.findIndex((x) => x.level == level)
        const downMappings = objectiveData[value].dropDownMappings
        for (let x = 0; downMappings.length > x; x++) {
          const obj = downMappings[x]
          if (obj.upperLevelPersonId == event.target.value) {
            setLevelDropValue(
              obj.lowerLevelPersonsTitles,
              obj.lowerLevelPersonsIds,
              3,
              obj.isDirectReport,
            )
            break
          } else {
            setLevelDropValue([], [], 3)
          }
        }
      }
    } else if (level === 3) {
      setLevel3Value(event.target.value)
      setIsDirectReport(isDirectReport)
    }
    setMain(nextLevel)
  }

  function setZoomIndex(data, level) {
    // setNodes([])
    if (level == 1) {
      if (zoomIndexLevel1.id == data.id) {
        setZoomIndexLevel1('')
      } else {
        setZoomIndexLevel1(data)
      }
      setZoomIndexLevel2('')
    } else if (level == 2) {
      if (zoomIndexLevel2.id == data.id) {
        setZoomIndexLevel2('')
      } else {
        setZoomIndexLevel2(data)
      }
    }
  }

  // async function ShowCommentModel(id) {
  // }

  async function ShowActivitesModal(id) {
    setActivitiesModalMode('activities')
    activitiesModalClickHander(true)
    setObjId(id)
  }

  async function ShowHistoryModal(id) {
    setIsObjHistoryOpen(true)
    setObjId(id)
  }

  async function ShowEditModal(id, level) {
    setObjId(id)
    setLastClickedLevel(level)
    setObjModalMode('edit')
    if (level === 1 || level === 2) {
      setIsL1L2ModalOpen(true)
    } else {
      setIsAddEditObjModalOpen(true)
    }
  }

  async function ShowAddModal(data, level) {
    let ownerId = level === 1 ? level2Value : level3Value
    setObjData({ parentId: data.id, topLevelObjectiveId: data.parentId, ownerId: ownerId })

    setLastClickedLevel(level - 1)
    setObjModalMode('add')

    if (level === 1) {
      setIsL1L2ModalOpen(true)
    } else if (level === 2) {
      setIsAddEditObjModalOpen(true)
    }
  }

  async function ShowOverdueModal(id) {
    setActivitiesModalMode('overdue')
    activitiesModalClickHander(true)
    setObjId(id)
  }

  async function ShowNotificationsModal(id) {
    setObjId(id)
    setIsNotificationModalOpen(true)
  }

  async function ShowRequestUpdateModal(id) {
    setObjId(id)
    setIsRequestModalOpen(true)
  }

  async function ShowFilesModal(id) {
    setObjId(id)
    setIsViewFilesModalOpen(true)
  }

  async function ShowKPIModal(id) {
    setObjId(id)
    setIsKPIModalOpen(true)
  }

  async function ShowKPIModalSpecificKpi(id) {
    setObjId(id)
    setIsKPIModalOpen(true)
    setOpenOnSpecificKpi(true)
  }

  async function ShowChangelogPage(id) {
    navigate(`/${routes.changeLog}`, { state: { objId: id } })
  }

  async function ShowGanttChartModal(id) {
    setObjId(id)
    setIsGanttChartModalOpen(true)
  }

  async function ShowAddEditMeetingTopicModal(data) {
    //called from objective node, data is objective data
    setSelectedMeetingTopic({
      topicId: null,
      topicType: null,
      topicText: null,
      topicPriority: null,
      objectiveId: null,
      ownerId: data.ownerId,
      ownerName: data.owner,
      leaderId: data.ownerId,
    })
    setObjId(data.id)
    setIsAddEditMeetingTopicModalOpen(true)
  }

  async function ShowEditTopicModal(data) {
    //called from topic node, data is topic data
    let leaderId = level3Value !== '' ? level3Value : level2Value
    setSelectedMeetingTopic({
      topicId: data.umtId,
      topicType: data.topicType,
      topicText: data.description,
      topicPriority: data.isPriority,
      objectiveId: data.objId,
      ownerId: data.topicOwnerId,
      ownerName: data.topicOwnerName,
      leaderId: leaderId,
    })
    setObjId(data.objId)
    setIsAddEditMeetingTopicModalOpen(true)
  }

  async function ShowCardTopicModal(topicId, topicType) {
    setSelectedTopicId(topicId)
    setSelectedTopicType(topicType)
    setIsCardModalOpen(true)
  }

  async function ShowDeleteTopic(topicId) {
    deleteMeetingTopic(topicId)
  }

  async function ShowRecommendedObjStatementModalOpen(data) {
    setObjData({ ownerId: data.ownerId })
    setObjId(data.id)
    setIsRecommendedObjStatementModalOpen(true)
  }

  if (isLoading == true) {
    return <InPageLoader />
  }

  return (
    <>
      <div className="add-objective">
        {nodes.length > 0 ? (
          <Flow
            initialNodes={nodes}
            initialEdges={edges}
            teamLeader={teamLeader}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
          />
        ) : (
          ''
        )}
      </div>
    </>
  )
}

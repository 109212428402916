import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import './customNode.scss'

function DropDownEdge({ data, isConnectable }) {
  const { t } = useTranslation(['ObjectiveMap'])
  const [selectLevel1, setSelectLevel1] = useState('')

  function dropDownChangedHandler(event, level, nextLevel) {
    let selectedIsDirectReportVal =
      event.target.options[event.target.selectedIndex].getAttribute('data-isDirectReport')

    data.dropDownChangedHandler(event, level, nextLevel, selectedIsDirectReportVal)
    setSelectLevel1(event.target.value)
  }

  // Sort the options alphabetically
  const sortedOptions = [...data.option].sort((a, b) => a.value.localeCompare(b.value))

  return (
    <div className="nodrag text-updater-node">
      {sortedOptions.length > 0 && (
        <div className="custom-box-node-team-flow">
          <div>{t('menu.selectLeader')}</div>
          <select
            className="team-flow-select custom-select"
            id={`change-handel-${data.main}`}
            name="handeLevel"
            value={data.value}
            onChange={(e) => dropDownChangedHandler(e, data.main, data.main + 1)}
          >
            <option key={''} label={t('menu.selectOption')} />
            {sortedOptions.map((data) => (
              <option
                key={data.value}
                value={data.id}
                label={data.value}
                data-isDirectReport={data.isDirectReport}
              />
            ))}
          </select>
          {sortedOptions && sortedOptions.length > 0 && (
            <div>
              {sortedOptions.some(
                (option) => option.isDirectReport !== undefined && !option.isDirectReport,
              ) && (
                <div style={{ fontSize: '0.6rem', textAlign: 'left' }}>
                  {t('menu.indirectReportsText')}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DropDownEdge

export function saveCredsToLocalStorage(data) {
  if (
    data === undefined ||
    data === null ||
    data.roles === undefined ||
    data.roles === null ||
    data.username === undefined ||
    data.username === null ||
    data.name === undefined ||
    data.name === null ||
    data.setup_complete === undefined ||
    data.setup_complete === null
  ) {
    return false
  }
  localStorage.setItem('tfmp', JSON.stringify(data.roles))
  localStorage.setItem('tfmu', data.username)
  localStorage.setItem('tfmnm', data.name)
  localStorage.setItem('setup', data.setup_complete)
  localStorage.setItem('mType', data.m_type)
  localStorage.setItem('dI', data.dI)
  localStorage.setItem('dType', data.d_type)
  localStorage.setItem('rfconf', JSON.stringify(data.rfconf))
  localStorage.setItem('tfmc', data.company_config)
  localStorage.setItem('cfg', data.cfg)
  localStorage.setItem('tfin', data.tfin)
  localStorage.setItem('alive', 'true')
  localStorage.setItem('ilType', data.il_type)
  localStorage.setItem('kshrt', data.show_tl_mei)
  localStorage.setItem('tfei', data.tfei)
  localStorage.setItem('send_to_setup_agent', data.send_to_setup_agent)
  localStorage.setItem('company_setup_wizard_completed', data.company_setup_wizard_completed)
  localStorage.setItem('is_demo', data.is_demo)
  localStorage.setItem('tfci', data.company_id)

  // We store the value of tfuwtl in local storage only if it is not -1.
  // -1 indicates non TL role.
  if (data.tfuwtl !== -1) {
    localStorage.setItem('tfuwtl', data.tfuwtl)
  }

  if (data.tfwert !== -1) {
    localStorage.setItem('tfwert', data.tfwert)
  }

  if (data.dMd === true) {
    localStorage.setItem('dMd', true)
  }

  if (data.tfsse) localStorage.setItem('tfsse', true)

  return true
}

export function clearLocalStorage() {
  localStorage.removeItem('tfmp')
  localStorage.removeItem('tfmu')
  localStorage.removeItem('tfmnm')
  localStorage.removeItem('setup')
  localStorage.removeItem('mType')
  localStorage.removeItem('dI')
  localStorage.removeItem('dMd')
  localStorage.removeItem('dType')
  localStorage.removeItem('tfmc')
  localStorage.removeItem('alive')
  localStorage.removeItem('rfconf')
  localStorage.removeItem('cfg')
  localStorage.removeItem('ilType')
  localStorage.removeItem('messageBubbles')
  localStorage.removeItem('isCollapsed')
  localStorage.removeItem('tfuwtl')
  localStorage.removeItem('tfsse')
}

export function isAuthorizedForResource(resource) {
  let userRoles = localStorage.getItem('tfmp') || null
  let setupCompleted = (localStorage.getItem('setup') || null) === 'true'

  // if (userRoles === null || setupCompleted === null) {
  //     const BASE_URL = String(process.env.REACT_APP_PYTHON_BASE_URL)
  //     const response = await fetch(`${BASE_URL}/f/userlocal`, {
  //         credentials: 'include',
  //         method: 'POST'
  //     })
  //     const data = await response.json()

  //     userRoles = data.roles
  //     setupCompleted = data.setup_complete

  //     localStorage.setItem('tfmp', JSON.stringify(data.roles))
  //     localStorage.setItem('tfmu', data.username)
  //     localStorage.setItem('setup', data.setup_complete)
  // }

  if (userRoles === null) return false

  if (resource.resourceName) {
    const mapping = {
      'Team Wizard': 'team',
      Governance: 'enterprise',
      'Meeting View': 'area',
      'Area Wizard': 'area',
      'Setup Wizard': 'enterprise',
      'My Teams Wizards': 'area',
      'Team Objectives': 'team',
    }
    if (!mapping[resource.resourceName]) {
      return false
    }

    if (!userRoles.includes(mapping[resource.resourceName])) {
      return false
    }

    if (resource.resourceName === 'Setup Wizard') return !setupCompleted
    else return setupCompleted
  }

  return true
}

export function areCredentialsAvailable() {
  let currentUser = localStorage.getItem('tfmu') || null
  let userRoles = localStorage.getItem('tfmp') || null
  let setup = localStorage.getItem('setup') || null

  return currentUser !== null && userRoles !== null && setup !== null
}

export function isSetupComplete() {
  let setup = localStorage.getItem('setup') || null

  return setup === 'true'
}

export function hasRole(role) {
  let userRoles = localStorage.getItem('tfmp') || []
  return userRoles.includes(role)
}

export function getInheritedLevel() {
  let inheritedLevel = localStorage.getItem('ilType') || null
  return inheritedLevel
}

export function getUpdateWizStatus() {
  return localStorage.getItem('tfuwtl') || null
}

export function getHasSetupWizardAction() {
  const value = localStorage.getItem('tfsas')
  return value && value.toLowerCase() === 'true' ? true : false
}

export function getCompanySetupWizardStatus() {
  return localStorage.getItem('company_setup_wizard_completed') || null
}

export function getShowTlMei() {
  return localStorage.getItem('kshrt') || null
}

export function getIsSupportSessionEnabled() {
  return localStorage.getItem('tfsse') || null
}

export function getCompanyConfigSettings(config) {
  try {
    let configSettings = localStorage.getItem('tfmc') || '{}'
    let configVal = JSON.parse(configSettings)[config]
    if (configVal != undefined) {
      return configVal
    } else return false
  } catch (error) {
    return false
  }
}

export function getConsolidatedConfigSettings(config) {
  try {
    let configSettings = localStorage.getItem('cfg') || '{}'
    let configVal = JSON.parse(configSettings)[config]
    if (configVal != undefined) {
      return configVal
    } else return false
  } catch (error) {
    return false
  }
}

export function isAreaLeader() {
  try {
    let configSettings = localStorage.getItem('tfmp') || '{}'
    let configVal = JSON.parse(configSettings)

    for (let value of configVal) {
      if (value === 'area') {
        return true
      }
    }
    return false
  } catch (error) {
    return false
  }
}

export function isTeamLeader() {
  try {
    let configSettings = localStorage.getItem('tfmp') || '{}'
    let configVal = JSON.parse(configSettings)

    for (let value of configVal) {
      if (value === 'team') {
        return true
      }
    }
    return false
  } catch (error) {
    return false
  }
}

export function isCEO() {
  try {
    let configSettings = localStorage.getItem('tfmp') || '{}'
    let configVal = JSON.parse(configSettings)

    for (let value of configVal) {
      if (value === 'ceo') {
        return true
      }
    }
    return false
  } catch (error) {
    return false
  }
}

export function isCXO() {
  try {
    let configSettings = localStorage.getItem('tfmp') || '{}'
    let configVal = JSON.parse(configSettings)

    for (let value of configVal) {
      if (value === 'cxo') {
        return true
      }
    }
    return false
  } catch (error) {
    return false
  }
}

export function isCOS() {
  try {
    let configSettings = localStorage.getItem('tfmp') || '{}'
    let configVal = JSON.parse(configSettings)

    for (let value of configVal) {
      if (value === 'cos') {
        return true
      }
    }
    return false
  } catch (error) {
    return false
  }
}

export function isIC() {
  try {
    let configSettings = localStorage.getItem('tfmp') || '{}'
    let configVal = JSON.parse(configSettings)

    for (let value of configVal) {
      if (value === 'ic') {
        return true
      }
    }
    return false
  } catch (error) {
    return false
  }
}

const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false

export function redirectToHome() {
  if (
    hasRole('cxo') ||
    hasRole('area') ||
    hasRole('ceo') ||
    hasRole('cos') ||
    (hasRole('team') && isDelegateTl)
  ) {
    window.location.href = '/MeetingMultiView'
  } else if ((hasRole('team') && !isDelegateTl) || hasRole('ic')) {
    window.location.href = '/TeamMeetingView'
  } else if (hasRole('enterprise') || hasRole('admin')) {
    window.location.href = '/Governance'
  } else {
    window.location.href = '/ObjectiveMap'
  }
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { getHttpRequest, postHttpRequest } from './../../../api/query/dynamicAPI'
import Toast from '../../../common/toast'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { myNotebookSubRoutes, routes } from '../../../utils/routes'
import MyNotebookDropdownMenu from '../MyNotebookDropdownMenu'
import InPageLoader from '../../../components/InPageLoader'
import AddEditActivityModal from '../AddEditActivityModal'
import AddEditMeetingTopicModal from '../../../components/AddEditMeetingTopicModal'
import { dayMonthFilter } from '../../../utils/time'

const MyNotebookTasks = ({}) => {
  const { t } = useTranslation(['MyNotebook', 'Common'])
  const [isLoading, setIsLoading] = React.useState(false)
  const [addInput, setAddInput] = React.useState('')
  const [tasks, setTasks] = React.useState([])
  const [openConvertToActionModal, setOpenConvertToActionModal] = React.useState(false)
  const [openConvertToMeetingTopicModal, setOpenConvertToMeetingTopicModal] = React.useState(false)
  const [selectedTask, setSelectedTask] = React.useState(null)

  React.useEffect(() => {
    getTasks()
  }, [])

  const getTasks = async (isFirstLoad = false) => {
    try {
      setIsLoading(true)
      let response = await getHttpRequest(`/get_my_notebook_tasks`)
      if (response.myNotebookTasksList) {
        setTasks(response.myNotebookTasksList)
      }

      setIsLoading(false)
    } catch (error) {
      Toast.fire({
        icon: 'error',
      })
    }
  }

  const saveTask = async (taskId, taskDescription, dueDate) => {
    let postObj = {
      task_id: taskId ?? null,
      task_description: taskDescription ?? null,
      due_date: dueDate ?? null,
    }

    postHttpRequest(`/save_my_notebook_task`, postObj)
      .then((response) => {
        if (response.success === true) {
          setAddInput('')
          if (taskId === null) {
            getTasks()
          }
        } else {
          Toast.fire({
            icon: 'error',
          })
        }
      })
      .catch((error) => {})
  }

  const updateAddInput = (e) => {
    setAddInput(e.target.value)
  }

  const deleteTask = async (taskId) => {
    let postObj = {
      task_id: taskId,
    }

    postHttpRequest(`/delete_my_notebook_task`, postObj)
      .then((response) => {
        if (response.success === true) {
          getTasks()
        } else {
          Toast.fire({
            icon: 'error',
          })
          console.log('Error saving note')
        }
      })
      .catch((error) => {})
  }

  const changeTaskStatus = async (taskId, status) => {
    let postObj = {
      task_id: taskId,
      is_completed: !status,
    }

    postHttpRequest(`/update_notebook_task_status`, postObj)
      .then((response) => {
        if (response.success === true) {
          let tempTasks = [...tasks]
          for (let i = 0; i < tempTasks.length; i++) {
            if (tempTasks[i].taskId === taskId) {
              tempTasks[i].isCompleted = !status
              break
            }
          }
          setTasks(tempTasks)
        } else {
          Toast.fire({
            icon: 'error',
          })
        }
      })
      .catch((error) => {})
  }

  return (
    <>
      {openConvertToMeetingTopicModal && (
        <AddEditMeetingTopicModal
          isModalOpen={openConvertToMeetingTopicModal}
          closeModal={() => setOpenConvertToMeetingTopicModal(false)}
          textInputIn={selectedTask.taskDescription}
          fromKanban={false}
          showMineButton={true}
          showToast={true}
        />
      )}
      {openConvertToActionModal && (
        <AddEditActivityModal
          isModalOpen={openConvertToActionModal}
          closeModal={() => setOpenConvertToActionModal(false)}
          actionText={selectedTask?.taskDescription}
        />
      )}

      <div
        className="my-notebook-tasks-container"
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '70vw',
          border: '1px solid rgb(238, 238, 238)',
          justifyContent: isLoading ? 'center' : 'flex-start',
        }}
      >
        {
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            {!isLoading && (
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '2rem', paddingTop: '2rem' }}
              >
                <button
                  type="button"
                  className="btn-card tonedown-blue-btn"
                  style={{
                    width: '1.8rem',
                    height: '1.8rem',
                    marginLeft: '0.5rem',
                    visibility: 'hidden',
                    disabled: true,
                  }}
                  onClick={() => {
                    saveTask(null, addInput, null)
                  }}
                >
                  <span class="material-symbols-outlined icon-size">add</span>
                </button>

                <textarea
                  rows="1"
                  id="addInput"
                  name="addInput"
                  value={addInput}
                  onChange={(e) => {
                    updateAddInput(e)
                  }}
                  placeholder={t('newTask')}
                  style={{
                    resize: 'none',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    padding: '0.5rem',
                    borderRadius: '5px',
                    border: '1px solid lightgray',
                    width: '30rem',
                  }}
                />
                <button
                  type="button"
                  className="btn-card tonedown-blue-btn"
                  style={{ width: '1.8rem', height: '1.8rem' }}
                  onClick={() => {
                    saveTask(null, addInput, null)
                  }}
                >
                  <span class="material-symbols-outlined icon-size">add</span>
                </button>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '2rem',
                gap: '1rem',
              }}
            >
              {!isLoading &&
                tasks.map((task, index) => {
                  return (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
                      <div
                        class={`fitted-button checklist-done tooltip`}
                        id={task.taskId}
                        onClick={(e) => {
                          changeTaskStatus(task.taskId, task.isCompleted)
                        }}
                        style={{
                          fontSize: '1.25rem',
                          cursor: 'pointer',
                          padding: '0',
                          marginLeft: '0.5rem',
                        }}
                      >
                        <span
                          className={`material-symbols-outlined ${task.isCompleted ? 'checklist-done-green' : 'checklist-done-grey'}`}
                        >
                          done
                        </span>
                        <span class="tooltiptext" style={{ fontSize: '16px' }}>
                          {' '}
                          {task.isCompleted
                            ? t('Common:tooltip.uncompleteTask')
                            : t('Common:tooltip.completeTask')}{' '}
                        </span>
                      </div>

                      <textarea
                        rows="1"
                        id="addInput"
                        name="addInput"
                        value={task.taskDescription}
                        onChange={(e) => {
                          if (task.taskId) {
                            let tempTasks = [...tasks]
                            for (let i = 0; i < tempTasks.length; i++) {
                              if (tempTasks[i].taskId === task.taskId) {
                                tempTasks[i].taskDescription = e.target.value
                                break
                              }
                            }
                            setTasks(tempTasks)
                          }
                        }}
                        onBlur={(e) => {
                          saveTask(task.taskId, e.target.value, task.dueDate)
                        }}
                        style={{
                          resize: 'none',
                          backgroundColor: 'white',
                          fontSize: '14px',
                          padding: '0.5rem',
                          borderRadius: '5px',
                          border: '1px solid lightgray',
                          width: '30rem',
                        }}
                      ></textarea>

                      <span style={{ color: 'grey' }}>{dayMonthFilter(task?.createdTs)}</span>

                      <MyNotebookDropdownMenu
                        type="task"
                        onClickDeleteTask={() => {
                          deleteTask(task.taskId)
                        }}
                        onClickConvertToAction={() => {
                          setOpenConvertToActionModal(true)
                          setSelectedTask(task)
                        }}
                        onClickConvertToMeetingTopic={() => {
                          setOpenConvertToMeetingTopicModal(true)
                          setSelectedTask(task)
                        }}
                      />
                    </div>
                  )
                })}
              {isLoading && (
                <div style={{ paddingTop: '2rem' }}>
                  <InPageLoader inComponent={true} isLoading={isLoading} />
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </>
  )
}
export default MyNotebookTasks

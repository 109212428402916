import React, { useState } from 'react'
import { useUpdateContext } from '../../context/updateContext'
import { Outlet } from 'react-router-dom'
import { redirectToHome } from '../../../../utils/auth'
// import ProgressBar from '../../../../components/ProgressBar'

import NextPreviousActions from '../NextPreviousActions'
import useUpdateWizardSteps, { stepsLabelsGenerator } from '../../hooks/useUpdateWizardSteps'
import useEditUpdateWizard from '../../../../api/mutation/editUpdateWizard'
import ErrorModal from '../../../../components/ErrorModal'
import { POST_TL_WIZARD_STATE } from '../../../../api/constant/route'
import { postHttpRequest, getHttpRequest } from '../../../../api/query/dynamicAPI'
import useGetObjectiveMilestones from '../../../../api/query/useGetObjectiveMilestones'
import JoyrideComponent from '../Joyride'
import {
  getConsolidatedConfigSettings,
  getCompanyConfigSettings,
  isAreaLeader,
} from '../../../../utils/auth'
import { STATUS, EVENTS } from 'react-joyride'

const UpdateWizard = ({ existingStep, steps = [] }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const { state, dispatch } = useUpdateContext()
  const {
    data: milestoneData,
    isLoading: isMilestonesLoading,
    error: milestonesError,
  } = useGetObjectiveMilestones(state?.data?.objectives?.map((obj) => parseInt(obj.id)) ?? [])

  const {
    currentStepIndex,
    // steps,
    handleNext,
    handlePrevious,
    // uwArray,
    isLastStep,
  } = useUpdateWizardSteps(existingStep, state, milestoneData)

  const stepsLabels = stepsLabelsGenerator()

  const mutationUpdateWizardSubmit = useEditUpdateWizard()

  const showToturial =
    isAreaLeader() ||
    (getCompanyConfigSettings('showKpiUpdatePageInUW') === true &&
      getConsolidatedConfigSettings('tl_skip_capacity_wiz'))
  const [isUwTutorialOff, setIsUwTutorialOff] = useState(true)

  const handleSubmit = async () => {
    let employeesObjAlloc = []
    for (let obj of state.data.objectives) {
      if (!obj.team) {
        continue
      }

      for (let member of obj.team) {
        if (!isNaN(member.value)) {
          employeesObjAlloc.push({
            eId: member.eId,
            objId: parseInt(obj.id),
            allocPct: member.value,
          })
        }
      }
    }
    let responseObj = {
      objectives: [],
      employees: state.data.employees,
      employeesObjAlloc: employeesObjAlloc,

      blockers: state.data.blockers,
      milestones: state.data.milestones,
      closeUwAction: state?.locationState?.showBGUonly == true ? false : true,
    }
    const objectives = state.data.objectives

    for (let i = 0; i < objectives.length; i++) {
      responseObj.objectives.push({ id: objectives[i].id })
      if (objectives[i].missingFte) {
        responseObj.objectives[i] = {
          ...responseObj.objectives[i],
          missingFte: objectives[i].missingFte,
        }
      }
    }

    await mutationUpdateWizardSubmit.mutateAsync(responseObj, {
      onSuccess: () => {
        localStorage.setItem('tfuwtl', '1')
        redirectToHome()
      },
      onError: () => {
        alert('There was an error submitting the wizard.')
        redirectToHome()
      },
    })
  }

  async function updateWizardState(tlWizardState, step) {
    const postObj = {}
    postObj.tlWizardState = tlWizardState
    postObj.step = step
    postHttpRequest(POST_TL_WIZARD_STATE, postObj)
      .then((response) => {
        if (response) {
          return
        } else {
        }
      })
      .catch((error) => {})
  }

  const handleCloseErrorModal = () => {
    dispatch({ type: 'CLOSE_ERROR_MODAL' })
  }
  // this func is essentially if there are no actions in allMyaction page...
  const handleJoyrideCallback = (data) => {
    const { type, status, index, action, lifecycle } = data
    if (action === 'next' && lifecycle === 'complete' && type === EVENTS.STEP_AFTER) {
      dispatch({
        type: 'UPDATE_TOUR_INNER_STEP_INDEX',
        tourInnerStepIndex: 0,
      })
      dispatch({
        type: 'SET_IS_TOUR_ACTIVE',
        isTourActive: false,
        tourIndex: 0,
      })
    }
  }
  React.useEffect(() => {
    dispatch({
      type: 'INIT_STEPS',
      steps: steps,
    })
    dispatch({
      type: 'UPDATE_TOUR_CALLBACK',
      customJoyRideCallBackFn: handleJoyrideCallback,
    })
  }, [])

  return (
    <>
      {state.isErrorModalOpen === true && (
        <ErrorModal
          isOpen={state.isErrorModalOpen}
          handleClose={handleCloseErrorModal}
          errorMessage={state.errorModalMessage}
        />
      )}
      {/* //Todo: refactor spacing */}
      {showToturial && !state.joyRide.isTutorialOff && <JoyrideComponent />}
      <NextPreviousActions
        screenPlacement="top"
        hideBackButton={state?.locationState?.isFromDashBoard ?? false}
        submitIsLoading={
          mutationUpdateWizardSubmit.isLoading || mutationUpdateWizardSubmit.isFetching
        }
        isLastStep={isLastStep()}
        currentStep={currentStepIndex}
        handleSubmit={handleSubmit}
        handleNext={() => {
          if (currentStepIndex === stepsLabels.TEAM_CAPACITY_ALLOC) {
            if (state.allocSubWiz.errorTeamCapAllocTable === true) {
              dispatch({ type: 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP', value: true })
              return
            }
          }
          setErrorMessage(null)
          handleNext(state?.locationState?.showBGUonly)
        }}
        handlePrevious={() => {
          handlePrevious(state?.locationState?.showBGUonly)
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      {/* Commenting out progress bar until we have time to fix it so it takes into consideration step skipping */}
      {/* <ProgressBar currentStep={currentStepIndex} steps={uwArray.filter(value => value === true)} /> */}
      <Outlet
        context={{
          setErrorMessage,
          handlePrevious,
        }}
      />
      <NextPreviousActions
        hideBackButton={state?.locationState?.isFromDashBoard ?? false}
        submitIsLoading={
          mutationUpdateWizardSubmit.isLoading || mutationUpdateWizardSubmit.isFetching
        }
        isLastStep={isLastStep()}
        currentStep={currentStepIndex}
        handleSubmit={handleSubmit}
        handleNext={() => {
          if (currentStepIndex === stepsLabels.TEAM_CAPACITY_ALLOC) {
            if (state.allocSubWiz.errorTeamCapAllocTable === true) {
              dispatch({ type: 'ALLOC_SUB_WIZ_SET_SELECT_ALL_EMP', value: true })
              return
            }
          }
          setErrorMessage(null)
          handleNext(
            state?.locationState?.showBGUonly,
            state?.locationState?.showOverdueKPIsPageOnly,
          )
        }}
        handlePrevious={() => {
          handlePrevious(
            state?.locationState?.showBGUonly,
            state?.locationState?.showOverdueKPIsPageOnly,
          )
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </>
  )
}

export default UpdateWizard

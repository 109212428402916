import React from 'react'
import Modal from '../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { getHttpRequest, postHttpRequest } from '../../../api/query/dynamicAPI'
import Toast from '../../../common/toast'
import InPageLoader from '../../../components/InPageLoader'

const RecommendedObjStatementModal = ({
  isModalOpen,
  closeModal,
  objId,
  ownerId,
  setEditedObj,
  loadAgain,
}) => {
  const { t } = useTranslation(['Common', 'ObjectiveMap'])
  const [isLoading, setIsLoading] = React.useState(false)
  const [existingObjStatement, setExistingObjStatement] = React.useState('')
  const [suggestedObjStatement, setSuggestedObjStatement] = React.useState('')

  React.useEffect(() => {
    if (objId) {
      getSuggestedObjective()
    }
  }, [])

  const textInputChangeHandler = (event) => {
    setSuggestedObjStatement(event.target.value)
  }

  const getSuggestedObjective = async () => {
    setIsLoading(true)
    try {
      let response = await postHttpRequest('/generate_objective_recommendations', {
        objId: objId,
      })
      if (response) {
        setExistingObjStatement(response.existingStatement)
        setSuggestedObjStatement(response.recommendation)
      }
    } catch (error) {
      console.log(error)
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    setIsLoading(false)
  }

  const saveSuggestedResponse = async (action) => {
    setIsLoading(true)
    if (action === 'reject' || action === 'ignore') {
      closeModal()
      return
    }
    let obj = {
      objId: objId,
      ownerId: ownerId, //must send this with current save function called in /generate_accepted_recommendation to edit obj
      objectiveType: 'cxo',
      objective_statement: existingObjStatement,
      recommendation: suggestedObjStatement,
    }

    try {
      let response = await postHttpRequest('/generate_accepted_recommendation', obj)
      if (response.ok) {
        setEditedObj({
          objId: parseInt(objId),
          statement: response.acceptedRecommendation,
        })
        closeModal()
      }
    } catch (error) {
      console.log(error)
      closeModal()
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    loadAgain()
    setIsLoading(false)
  }

  return (
    <>
      <Modal
        title={t('ObjectiveMap:recommendedObjStatementModal.title')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      >
        {isLoading && <InPageLoader inComponent={true} />}
        {!isLoading && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ display: 'flex', alignSelf: 'left' }}>
                  <b>{t('ObjectiveMap:recommendedObjStatementModal.currentObjectiveStatement')}:</b>
                </span>

                <textarea
                  className="rc-textarea"
                  rows="6"
                  id="multiSubActivityText"
                  name="multiSubActivityText"
                  value={existingObjStatement}
                  disabled={true}
                  style={{
                    resize: 'none',
                    marginTop: '1rem',
                    border: 'none',
                    borderRadius: '10px',
                    padding: '0.5rem',
                    backgroundColor: 'rgb(242, 242, 242)',
                    fontSize: '14px',
                    width: '26rem',
                  }}
                />
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ display: 'flex', alignSelf: 'left' }}>
                  <b>
                    {t('ObjectiveMap:recommendedObjStatementModal.suggestedObjectiveStatement')}:
                  </b>
                </span>

                <textarea
                  rows="6"
                  id="multiSubActivityText"
                  name="multiSubActivityText"
                  value={suggestedObjStatement}
                  onChange={(e) => {
                    textInputChangeHandler(e)
                  }}
                  style={{
                    resize: 'none',
                    marginTop: '1rem',
                    border: 'none',
                    borderRadius: '10px',
                    padding: '0.5rem',
                    backgroundColor: 'rgb(242, 242, 242)',
                    fontSize: '14px',
                    width: '26rem',
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2rem',
                width: '100%',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              {/* <button
                  className="sleek-button"
                  onClick={() => {
                    //resetForm()
                    //closeModal()
                  }}
                  type="button"
                >
                  {t('commonButtons.ignore')}
                </button> */}
              <div
                className="sleek-button sleek-blue"
                onClick={() => {
                  saveSuggestedResponse('reject')
                }}
              >
                {t('commonButtons.reject')}
              </div>
              <div
                className="sleek-button sleek-full-blue"
                onClick={() => {
                  saveSuggestedResponse('accept')
                }}
              >
                {t('commonButtons.accept')}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default RecommendedObjStatementModal

import React from 'react'
import { useTranslation } from 'react-i18next'
import { getKpiListGroupedByUserObjectives } from '../../api/services/kpi.services'
import './styles.scss'
import { dateFilter, mmddyyyyToStringmmdd, dayMonthFilter } from '../../utils/time'
import { shortenName } from '../../utils/general'
import KPIModal from '../KPI/KPIModal'
import UpdateKPIModal from '../KPI/UpdateKPIModal'
import KPIUpdatesModal from '../KPI/KPIUpdatesModal'
import InPageLoader from '../InPageLoader'
import { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { useUpdateContext } from '../../pages/Update/context/updateContext'
import { getCompanyConfigSettings } from '../../utils/auth'

const OverdueKPIs = ({}) => {
  const { t } = useTranslation(['Common', 'UpdateWizard'])
  const [data, setData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)

  const [kpiUpdateId, setKpiUpdateId] = React.useState(null)
  const [latestRagStatus, setLatestRagStatus] = React.useState(null)

  const [isKPIModalOpen, setIsKPIModalOpen] = React.useState(false)
  const [isUpdateKPIModalOpen, setIsUpdateKPIModalOpen] = React.useState(false)
  const [isOpenUpdateModal, setIsOpenUpdateModal] = React.useState(false)

  const [updateModalMode, setUpdateModalMode] = React.useState('update')
  const [selectedObjId, setSelectedObjId] = React.useState(null)
  const [selectedKpi, setSelectedKpi] = React.useState(null)

  const { state, dispatch } = useUpdateContext()
  const [isInTutorial, setIsInTutorial] = React.useState(false)
  const showMeetingDeck = getCompanyConfigSettings('showMeetingDeck') === true

  React.useEffect(() => {
    setIsLoading(true)
    loadData()
    setIsLoading(false)
  }, [])

  const loadData = async () => {
    const data = await getKpiListGroupedByUserObjectives()
    if (data && data?.kpiList) {
      setData(data.kpiList)
    }
  }

  React.useEffect(() => {
    if (data?.length > 0) {
      startTutorial()
    }
  }, [data])

  const handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setIsInTutorial(false)
      setIsUpdateKPIModalOpen(false)
      setUpdateModalMode('update')
      dispatch({
        type: 'UPDATE_TOUR_INNER_STEP_INDEX',
        tourInnerStepIndex: 0,
      })
      dispatch({
        type: 'SET_IS_TOUR_ACTIVE',
        isTourActive: false,
        tourIndex: 1,
      })
      setSelectedKpi(null)
      setSelectedObjId(null)
    } else if (type === EVENTS.STEP_AFTER) {
      if (action === 'next' && index === 1) {
        setUpdateModalMode('add')
        // wait for render is completed
        dispatch({
          type: 'SET_IS_TOUR_ACTIVE_AND_TOUR_KPI_STEP_INDEX',
          isTourActive: false,
          tourIndex: 1,
          tourInnerStepIndex: 2,
        })
        setIsInTutorial(true)

        setIsUpdateKPIModalOpen(true)
      } else {
        if (index === 2 && action === 'prev') {
          setIsUpdateKPIModalOpen(false)
        }
        let ind = index + (action === 'prev' ? -1 : 1)
        dispatch({
          type: 'UPDATE_TOUR_INNER_STEP_INDEX',
          tourInnerStepIndex: ind,
        })
      }
    }
  }

  const startTutorial = () => {
    if (state.isTutorialOff) return
    if (!state.joyRide.isToursActive[1]) return
    if (!showMeetingDeck) {
      dispatch({
        type: 'REMOVE_DOC_FROM_STEPS',
        id: '9',
      })
    }
    dispatch({
      type: 'UPDATE_TOUR_STEP_NAME',
      stepName: 'overdueKpiModal',
    })
    dispatch({
      type: 'UPDATE_TOUR_CALLBACK',
      customJoyRideCallBackFn: handleJoyrideCallback,
    })
    dispatch({
      type: 'UPDATE_TOUR_STEP_INDEX',
      tourStepIndex: 1,
    })
  }

  const convertEnumFrequency = (frequency) => {
    if (frequency === 0) {
      return t('kpi.unknown')
    } else if (frequency === 1) {
      return t('kpi.weekly')
    } else if (frequency === 2) {
      return t('kpi.biweekly')
    } else if (frequency === 3) {
      return t('kpi.monthly')
    }
  }

  const allKPIsEmpty = data?.every((obj) => !obj.kpis || obj.kpis.length === 0)

  return (
    <>
      <div style={{ width: '100%' }}>
        {isLoading && <InPageLoader inComponent={true} />}
        {(data?.length === 0 || allKPIsEmpty) && !isLoading && state.joyRide.isTutorialOff && (
          <span>{t('kpi.thereAreNoKPIsThatRequireUpdates')}</span>
        )}
        {(data?.length === 0 || allKPIsEmpty) && !isLoading && !state.joyRide.isTutorialOff && (
          <div>{t('UpdateWizard:overdueKpis.noKpisMsg')}</div>
        )}
        {data?.length > 0 &&
          data
            .filter((obj) => obj.kpis.length > 0)
            .map((obj, objIndex) => {
              return (
                <>
                  {obj.kpis.length > 0 && (
                    <div key={objIndex}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '0.5rem',
                        }}
                      >
                        <div style={{ fontWeight: 'bold' }}>{obj.objDescription}</div>
                        {/* Removed for now as logic to update list locally id complicated and needs to be thought through */}
                        {/* <div
                      class="fitted-button blue"
                      onClick={() => {
                        setSelectedObjId(obj.objId)
                        setIsKPIModalOpen(true)
                      }}
                    >
                      {t('kpi.seeAllKPIs')}
                    </div> */}
                      </div>
                      <div style={{ backgroundColor: 'black' }} className="divider"></div>

                      <div style={{ marginBottom: '3rem', paddingTop: '2rem' }}>
                        <div className="kpi-overdue-table">
                          <div className="kpi-overdue-table-header"></div>
                          <div
                            className="kpi-overdue-table-header"
                            style={{ backgroundColor: 'white' }}
                            id={`kpi-overdue-table-kpi-name-${objIndex}`}
                          >
                            {t('kpi.name')}
                          </div>
                          <div className="kpi-overdue-table-header">{t('kpi.unit')}</div>
                          <div className="kpi-overdue-table-header">{t('kpi.target')}</div>

                          <div className="kpi-overdue-table-header">{t('kpi.frequency')}</div>
                          <div className="kpi-overdue-table-header">
                            {t('kpi.dueDate')}
                            <span style={{ fontWeight: 'normal' }}> &#40;mm/dd&#41; </span>
                          </div>
                          <div className="kpi-overdue-table-header">{t('kpi.lastUpdated')}</div>
                          <div className="kpi-overdue-table-header">{t('kpi.madeBy')}</div>
                          <div className="kpi-overdue-table-header"></div>
                          {obj.kpis.map((kpi, index) => {
                            if (objIndex === 0 && selectedObjId === null) {
                              setSelectedObjId(obj.objId)
                            }
                            if (index === 0 && objIndex === 0 && selectedKpi === null) {
                              setSelectedKpi(kpi)
                            }
                            return (
                              <React.Fragment key={`kpi-${kpi.kpiId}`}>
                                <div className="kpi-status-table-cell">
                                  {kpi?.isUpdated &&
                                  (kpi?.latestRagStatus !== null ||
                                    kpi?.latestRagStatus !== undefined) ? (
                                    <div
                                      style={{
                                        backgroundColor:
                                          kpi?.latestRagStatus === -1
                                            ? 'lightgray'
                                            : kpi?.latestRagStatus === 0
                                              ? 'green'
                                              : kpi?.latestRagStatus === 1
                                                ? 'gold'
                                                : 'red',
                                        width: '2rem',
                                        height: '2rem',
                                        marginLeft: '1rem',
                                      }}
                                    ></div>
                                  ) : null}
                                </div>
                                <div className="kpi-status-table-cell">{kpi.name}</div>
                                <div className="kpi-status-table-cell">
                                  {kpi.unit === '' ? '---' : kpi.unit}
                                </div>
                                <div className="kpi-status-table-cell">
                                  {kpi.target === 0 ? '---' : kpi.target}
                                </div>
                                <div className="kpi-status-table-cell">
                                  {convertEnumFrequency(kpi.frequency)}
                                </div>
                                <div className="kpi-status-table-cell">
                                  {mmddyyyyToStringmmdd(kpi.targetDate)}
                                </div>
                                <div className="kpi-status-table-cell">
                                  {kpi.lastUpdateTs === 0
                                    ? '---'
                                    : dayMonthFilter(kpi.lastUpdateTs)}
                                </div>
                                <div className="kpi-status-table-cell">
                                  {shortenName(kpi.ownerName)}
                                </div>
                                <div
                                  id={`kpi-overdue-table-buttons-group-${objIndex}-${index}`}
                                  className="kpi-status-table-cell"
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '0.5rem',
                                    backgroundColor: 'white',
                                  }}
                                >
                                  <div
                                    className="fitted-button blue tooltip"
                                    style={{ backgroundColor: 'white' }}
                                    onClick={() => {
                                      setSelectedObjId(obj.objId)
                                      setSelectedKpi(kpi)
                                      setIsOpenUpdateModal(true)
                                    }}
                                  >
                                    <span className="material-symbols-outlined">visibility</span>
                                  </div>
                                  {kpi.isUpdated ? (
                                    <div
                                      className="fitted-button blue"
                                      onClick={() => {
                                        setUpdateModalMode('edit')
                                        setSelectedObjId(obj.objId)
                                        setSelectedKpi(kpi)
                                        setIsUpdateKPIModalOpen(true)
                                      }}
                                      style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      <span class="material-symbols-outlined">edit</span>
                                    </div>
                                  ) : (
                                    <div
                                      className="fitted-button blue"
                                      onClick={() => {
                                        setUpdateModalMode('add')
                                        setSelectedObjId(obj.objId)
                                        setSelectedKpi(kpi)
                                        setIsUpdateKPIModalOpen(true)
                                      }}
                                      style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                      <span class="material-symbols-outlined">add</span>
                                    </div>
                                  )}
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )
            })}

        {/* If we bring up back this modal on update wizard, 
      we need to add some props to locally add new kpi to list in the frontend withoit reloading backend
      so we don't loose any kpi that have been marked at isUpdated
       */}
        {/* {isKPIModalOpen && (
        <KPIModal
          objId={selectedObjId}
          handleCancel={() => {
            setIsKPIModalOpen(false)
          }}
          //reloadBackground
         
          
        />
      )} */}

        {isUpdateKPIModalOpen && (
          <UpdateKPIModal
            isInTutorial={isInTutorial}
            closeModal={() => setIsUpdateKPIModalOpen(false)}
            objId={selectedObjId}
            kpi={selectedKpi}
            mode={updateModalMode}
            setKpiUpdateId={setKpiUpdateId}
            setLatestRagStatus={(latestRagStatus) => {
              selectedKpi.latestRagStatus = latestRagStatus
            }}
            kpiUpdateId={kpiUpdateId}
            loadUpdates={() => {
              let tempData = [...data]
              for (let obj of tempData) {
                for (let kpi of obj.kpis) {
                  if (kpi.kpiId === selectedKpi.kpiId) {
                    kpi.lastUpdateTs = Math.floor(new Date().getTime() / 1000)
                    kpi.isUpdated = true
                    kpi.latestUpdateId = kpiUpdateId
                  }
                }
              }
              setData(tempData)
            }}
          />
        )}

        {isOpenUpdateModal && (
          <KPIUpdatesModal
            handleCancel={() => setIsOpenUpdateModal(false)}
            objId={selectedObjId}
            selectedKpiUpdate={selectedKpi}
            canAddUpdate={false}
            reloadBackground={() => {
              let tempData = [...data]
              for (let obj of tempData) {
                for (let kpi of obj.kpis) {
                  if (kpi.kpiId === selectedKpi.kpiId) {
                    kpi.lastUpdateTs = Math.floor(new Date().getTime() / 1000)
                    kpi.isUpdated = true
                  }
                }
              }
              setData(tempData)
            }}
          />
        )}
      </div>
    </>
  )
}

export default OverdueKPIs

import React, { useState } from 'react'

import { Handle, Position } from 'reactflow'
import './customNode.scss'
import { shortenName } from '../../../utils/general'

function SetupAgentNode({ data, isConnectable }) {
  return (
    <div className="nodrag text-updater-node">
      {data.main === 1 || data.main === 2 || data.main === 3 ? (
        <>
          {' '}
          <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
      <div
        className={`custom-box-node box-level-${data.main} ${
          data.isSearchedObjective ? 'searched-border' : ''
        }`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '130px',
        }}
      >
        <ul className="node-text-list">
          <li>
            <div style={{ display: 'flex', gap: '1.5rem', marginBottom: '0.2rem' }}>
              <div className="owner">{shortenName(data.owner)}</div>
            </div>
          </li>
          <li className="single-node">
            {/* <h2>Objective:</h2> */}
            <p className="goal-desc">{data.goal}</p>
          </li>
        </ul>
      </div>

      {data.main != 3 ? (
        <>
          <Handle type="source" position={Position.Bottom} id="b" isConnectable={isConnectable} />
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default SetupAgentNode

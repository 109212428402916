import React, { useState } from 'react'

import './customNode.scss'

function RecNode({ data }) {
  return (
    <div className="nodrag text-updater-node">
      <div
        className={`custom-box-node box-level-recommendation custom-box-node-assignee`}
        style={{ height: '130px' }}
      >
        <ul className="node-text-list">
          <li>
            <div style={{ display: 'flex', gap: '1.5rem', marginBottom: '0.2rem' }}>
              <div className="owner">Recommendation</div>
            </div>
          </li>
          <li className="single-node">
            {/* <h2>Objective:</h2> */}
            <p className="goal-desc">{data}</p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default RecNode

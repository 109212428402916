import React from 'react'
import { useTranslation } from 'react-i18next'
import '../../styles.scss'
import { getLevelObjsWithKpiCount } from '../../../../api/services/kpi.services'
import { useKPIContext } from '../../context/kpiContext'
import { kpiSubRoutes } from '../../../../utils/routes'
import { routes } from '../../../../utils/routes'
import { useNavigate } from 'react-router-dom'
import { useOutletContext } from 'react-router-dom'
import InPageLoader from '../../../../components/InPageLoader'
import { hasRole, getConsolidatedConfigSettings } from '../../../../utils/auth'
import { useLocation } from 'react-router-dom'

const KPIListOfObjectives = () => {
  const { t } = useTranslation(['KPIWizard', 'Common'])
  const { state, dispatch } = useKPIContext()
  const navigate = useNavigate()
  const [listObj, setListObj] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const { setErrorMessage, handlePrevious, handleNext } = useOutletContext()

  const canAddEditObjs = getConsolidatedConfigSettings('can_add_edit_objs')
  const currentLocation = useLocation()

  const enableL2Kpi = getConsolidatedConfigSettings('enable_l2_kpi') === true

  React.useEffect(() => {
    let level = setLevel()
    getObjectives(level)
  }, [])

  async function getObjectives(level) {
    setIsLoading(true)
    let result = await getLevelObjsWithKpiCount(level)
    setIsLoading(false)
    if (result && result?.objectives) {
      setListObj(result?.objectives)
    }
  }

  const setLevel = () => {
    if (hasRole('team')) {
      return 3
    } else if (hasRole('area') && enableL2Kpi) {
      return 2
    } else if (hasRole('area') && !enableL2Kpi) {
      return 3
    }
  }

  const launchWizard = (obj) => {
    //set choosen obj to wizard state
    dispatch({ type: 'SET_OBJ', obj: obj })

    //navigate to first question, which is timeframe
    handleNext()
  }

  return (
    <>
      {isLoading && <InPageLoader inComponent={true} />}
      {!isLoading && (
        <>
          {((hasRole('team') && canAddEditObjs) || hasRole('area')) && (
            <div style={{ marginBottom: '1rem' }}>
              <div
                className="sleek-button sleek-full-blue"
                onClick={() => {
                  navigate(`/${routes.demandintake}`, {
                    state: {
                      createNewObj: true,
                      path: currentLocation.pathname,
                    },
                  })
                }}
                style={{ display: 'flex', marginLeft: 'auto', marginBottom: '0.3rem' }}
              >
                <span class="material-symbols-outlined icon-size-l">add_circle</span>
                <span> {t('Common:commonButtons.objective')}</span>
              </div>
              <div className="divider"></div>
            </div>
          )}
          <div className="kpi-wizard-list_objectives-table">
            <div className="kpi-wizard-list_objectives-row header-row">
              <div className="chart-log-meeting-header title-border">{t('name')}</div>
              <div className="chart-log-meeting-header title-border">{t('numberOfKpis')}</div>
              <div className="chart-log-meeting-header title-border">{t('kpiWizardCompleted')}</div>
            </div>

            {listObj?.map((obj, index) => (
              <div key={index} className="kpi-wizard-list_objectives-row">
                <div
                  className="actions-activities-table-cell"
                  style={{ wordWrap: 'break-word', marginTop: 'auto', marginBottom: 'auto' }}
                >
                  {obj?.statement}
                </div>
                <div
                  className="actions-activities-table-cell"
                  style={{ wordWrap: 'break-word', marginTop: 'auto', marginBottom: 'auto' }}
                >
                  {obj?.kpiCount}
                </div>
                <div
                  className="actions-activities-table-cell"
                  style={{
                    wordWrap: 'break-word',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div class={`fitted-button checklist-done tooltip`}>
                    <span
                      className={`material-symbols-outlined ${
                        obj?.kpiWizardCompleted === false
                          ? 'checklist-done-grey'
                          : 'checklist-done-green'
                      }`}
                    >
                      done
                    </span>
                  </div>

                  {
                    <div
                      className="sleek-button sleek-full-blue"
                      onClick={() => {
                        launchWizard(obj)
                      }}
                    >
                      {t('launchWizard')}
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default KPIListOfObjectives

import React from 'react'
import { useTranslation } from 'react-i18next'
import ObjectiveMap from '../../pages/objectiveFlow'

const ReviewObjectiveMapPage = ({}) => {
  const { t } = useTranslation(['Common'])

  return <ObjectiveMap isReviewMode={true} />
}

export default ReviewObjectiveMapPage

import { getHttpRequest, postHttpRequest } from '../query/dynamicAPI'
import {
  EMPLOYEE_ADD_PEOPLETEAM,
  GET_ALL_EMPLOYEE,
  GET_TEAM_LIST,
  GET_EMPLOYEES_BY_LEVEL,
  API_ERROR_MESSAGE,
  GET_TEAM_FLOW,
  TEAM_UPDATE_Connection,
  TEAM_DELETE_Connection,
  EMPLOYEE_MOVE_EDIT,
  EMPLOYEE_UPDATE_RECEIVED_WIZARD,
  EMPLOYEE_DELETE,
  GET_ALL_EMPLOYEE_EXCLUDE_ICS,
  GET_REPORTING_EMPLOYEES,
  TEAM_LEADERS_FOR_AREA_LEADER,
  GET_SAME_LEVE_L2_LEADERS,
  GET_EMPLOYEES_MAGIC_IDS,
  GOV_AUTH,
} from '../../api/constant/route'
import Toast from '../../common/toast'

export async function getObjectivUserList(objectiveId) {
  try {
    const data = await getHttpRequest(`${GET_TEAM_LIST}/${objectiveId}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getAllEmployeeList(excludeIcs = false) {
  try {
    const value = excludeIcs ? GET_ALL_EMPLOYEE_EXCLUDE_ICS : GET_ALL_EMPLOYEE
    const data = await getHttpRequest(value)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getEmployeesListWithSupportTokens() {
  try {
    const data = await getHttpRequest(GET_EMPLOYEES_MAGIC_IDS)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function govAuthValidation() {
  try {
    const data = await getHttpRequest(GOV_AUTH)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getTeamLeaderList(levelType) {
  try {
    const data = await getHttpRequest(`${GET_EMPLOYEES_BY_LEVEL}${levelType}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getTeamFlow(teamLeaderId) {
  try {
    const data = await getHttpRequest(`${GET_TEAM_FLOW}/${teamLeaderId}`)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function teamUpdateConnection(postData) {
  try {
    const data = await postHttpRequest(`${TEAM_UPDATE_Connection}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function deleteConnection(postData) {
  try {
    const data = await postHttpRequest(`${TEAM_DELETE_Connection}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function updateReceivedWizard(postData) {
  try {
    const data = await postHttpRequest(
      `${EMPLOYEE_UPDATE_RECEIVED_WIZARD}/${postData.eId}`,
      postData,
    )
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function removeMemberPeopleManagment(eId) {
  try {
    const data = await postHttpRequest(`${EMPLOYEE_DELETE}/${eId}`, {})
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function moveEmployeePeopleManagment(eId, postData) {
  try {
    const data = await postHttpRequest(`${EMPLOYEE_MOVE_EDIT}/${eId}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function ADDEmployeePeopleManagment(postData) {
  try {
    const data = await postHttpRequest(`${EMPLOYEE_ADD_PEOPLETEAM}`, postData)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function sendEmailReminder(eIds, isGroupEmail = false) {
  try {
    const data = await postHttpRequest(`/actions_email_reminder`, {
      eIds: eIds,
      isGroupEmail: isGroupEmail,
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function postAccessToken(userId) {
  try {
    const data = await postHttpRequest(`/omni/chestnut1`, { eId: userId })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getReportingEmployeesList(eId = null, includeDelegate = false) {
  try {
    let queryParams = new URLSearchParams({})
    if (eId !== null) {
      queryParams.set('eId', eId)
    }
    if (includeDelegate) {
      queryParams.set('includeDelegate', 'true')
    }
    const data = await getHttpRequest(`${GET_REPORTING_EMPLOYEES}?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getTeamLeadersForAreaLeaders(
  eId = null,
  includeSelf = false,
  fromAddObjective = false,
) {
  try {
    let url =
      eId !== null ? `${TEAM_LEADERS_FOR_AREA_LEADER}/${eId}` : `${TEAM_LEADERS_FOR_AREA_LEADER}`
    let params = {}
    if (includeSelf) {
      params = { includeSelf: true }
    }
    if (fromAddObjective) {
      params = { fromAddObjective: true }
    }
    const data = await getHttpRequest(url, { params })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getSameLevelL2Leaders() {
  try {
    let url = `${GET_SAME_LEVE_L2_LEADERS}`
    const data = await getHttpRequest(url)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getUserConfigFlagMetadata() {
  try {
    let url = '/user_flags_metadata'
    const data = await getHttpRequest(url)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getAreaUserConfigFlagMetadata() {
  try {
    let url = '/area_user_flags_metadata'
    const data = await getHttpRequest(url)
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function getInternalSupportPermissionsList() {
  try {
    const data = await getHttpRequest('/get_internal_support_permissions_list')
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function setEmployeeWizardStatus(status) {
  try {
    const data = await postHttpRequest('/update_setup_wizard_status', {
      companySetUpAgentCompleted: status,
    })
    return data
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function finishCompanySetUp(role) {
  try {
    const data = await postHttpRequest('/finish_setup_agent', { role: role })
    return data
  } catch (e) {
    console.log('error', e)
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

export async function sendTlNotificationEmail(emailInfo, textAreaValue) {
  try {
    const res = await postHttpRequest('/send_tl_notification_emial', {
      emailInfo: emailInfo,
      text: textAreaValue,
    })
    return res
  } catch (e) {
    Toast.fire({
      icon: 'error',
      title: API_ERROR_MESSAGE,
    })
  }
}

import React from 'react'
import Modal from '../../../../../components/Modal'

import { useTranslation } from 'react-i18next'
import { dateFilter } from '../../../../../utils/time'
import Select from 'react-select'
import 'react-tooltip/dist/react-tooltip.css'
import useEditKanbanBoardChangeActivityStatus from '../../../../../api/mutation/useEditKanbanBoardChangeActivityStatus'
import './style.css'
import { getCompanyConfigSettings } from '../../../../../utils/auth'
import { linkifyString } from '../../../../../utils/general'
import SooDropdownMenu from '../../../../../components/SooDropdownMenu'
import AddEditActivityModal from '../../../../../components/AddEditActivityModal'
import Toast from '../../../../../common/toast'
import { getHttpRequest } from '../../../../../api/query/dynamicAPI'
import CommentsModal2 from '../../../../../components/CommentsModal2'
import { markActivityAsPriority } from '../../../../EmailReminderTab/components/utils.js'
import CardModal from '../../../../../components/CardModal'
import { removeMentionMarkupFromText } from '../../../../../utils/parseMarkup'
import InPageLoader from '../../../../../components/InPageLoader'

const BGUOpenCloseActionTableModal = ({
  isModalOpen,
  closeModal,
  data,
  refreshBGUScorecardFn = () => {},
  loadAgain,
  eIdsOfSelectedTls,
  isOpen,
  refreshMei = () => {},
  selectedViewMode = null,
  selectedMeetingId = null,
}) => {
  const { t } = useTranslation(['MeetingHub', 'Common'])
  const [peopleFilterValue, setPeopleFilterValue] = React.useState([])
  const [allAssignees, setAssignees] = React.useState([])
  const [allData, setAllData] = React.useState(null)
  const [dataToShow, setDataToShow] = React.useState(null)
  const [isCompletedBtns, setIsCompletedBtns] = React.useState({})
  const changeActivityStatusMutation = useEditKanbanBoardChangeActivityStatus()
  const productSettings = getCompanyConfigSettings('productSettings')
  const [isActivityModalOpen, setIsActivityModalOpen] = React.useState(false)
  const [objectiveIdSelected, setObjectiveIdSelected] = React.useState(null)
  const [taskIdSelected, setTaskIdSelected] = React.useState(null)

  const [isCommentModalOpen, setIsCommentModalOpen] = React.useState(false)
  const [objective, setObjective] = React.useState({})

  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)
  const [umtId, setUmtId] = React.useState(null)
  const [topicType, setTopicType] = React.useState(null)

  const [isLoading, setIsLoading] = React.useState(false)

  const handleChangePeopleFilter = (value) => {
    setPeopleFilterValue(value)
  }

  React.useEffect(() => {
    setIsLoading(true)
    refreshBGUActionsFn()
  }, [])

  React.useEffect(() => {
    if (dataToShow) {
      setIsLoading(false)
    }
  }, [dataToShow])

  React.useEffect(() => {
    if (allData) {
      let people = []
      allData?.forEach((action) => {
        action?.blockedObjectives?.forEach((blockedObj) =>
          blockedObj?.activities?.forEach((activity) => {
            if (activity?.assigneeList !== undefined) {
              people.push(...activity.assigneeList)
            }
          }),
        )
        action?.decisionObjectives?.forEach((decisionObj) =>
          decisionObj?.activities?.forEach((activity) => {
            if (activity?.assigneeList !== undefined) {
              people.push(...activity.assigneeList)
            }
          }),
        )
        action?.updateObjectives?.forEach((updateObj) =>
          updateObj?.activities?.forEach((activity) => {
            if (activity?.assigneeList !== undefined) {
              people.push(...activity.assigneeList)
            }
          }),
        )
        action?.adminTopicObjectives?.forEach((adminTopicObj) =>
          adminTopicObj?.activities?.forEach((activity) => {
            if (activity?.assigneeList !== undefined) {
              people.push(...activity.assigneeList)
            }
          }),
        )
      })
      // remove duplicate persons
      people = people?.filter(
        (person, index, arr) => arr.findIndex((val) => val.name === person.name) === index,
      )
      setAssignees(people)
      setDataToShow(allData)
    }
  }, [allData])

  const peopleFilterFn = (o) =>
    peopleFilterValue.every((person) =>
      o?.activities[0]?.assigneeList.some((assignee) => assignee.name == person.name),
    )
  const filterObjFn = (os) => os?.filter((o) => peopleFilterFn(o))

  const applyFilters = () => {
    let tempData = allData?.filter((action) => {
      let filteredBlockedObjectives = filterObjFn(action?.blockedObjectives)
      let filteredDecisionObjectives = filterObjFn(action?.decisionObjectives)
      let filteredUpdateObjectives = filterObjFn(action?.updateObjectives)
      let filteredAdminTopicObjectives = filterObjFn(action?.adminTopicObjectives)

      return (
        filteredBlockedObjectives.length +
          filteredDecisionObjectives.length +
          filteredUpdateObjectives.length +
          filteredAdminTopicObjectives.length >
        0
      )
    })

    setDataToShow(tempData)
  }

  const handleCompleteClick = (objectiveId, milestoneId, index, isOpen) => async (e) => {
    if (isCompletedBtns[e.target.id] === undefined || isCompletedBtns[e.target.id] === false) {
      if (isOpen === 0) {
        await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK', index, isOpen)
      } else {
        await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED', index, isOpen)
      }
    } else {
      if (isCompletedBtns[e.target.id] && isOpen == 0) {
        await changeActivityStatus(objectiveId, milestoneId, 'ON_TRACK', index, isOpen)
      } else {
        await changeActivityStatus(objectiveId, milestoneId, 'COMPLETED', index, isOpen)
      }
    }
    refreshBGUScorecardFn()
  }

  const changeActivityStatus = async (objectiveId, milestoneId, newStatus, index, isOpen) => {
    const postObject = {
      objectiveId,
      milestoneId,
      newStatus,
    }

    await changeActivityStatusMutation.mutateAsync(postObject, {
      onSuccess: (response) => {
        setIsCompletedBtns((prev) => ({
          ...prev,
          [milestoneId]: prev[milestoneId] === undefined ? true : !prev[milestoneId],
        }))
        loadAgain()
        refreshMei()
        if (isOpen === 0) {
          dataToShow[index].isOpen = 1
        } else {
          dataToShow[index].isOpen = 0
        }
      },
      onError: () => {},
    })
  }

  // Sort activities by date. We need to extract the activity from the objective first.
  const cmpSortDataByActionDate = (action1, action2) => {
    const getDate = (action) => {
      let ptr = null
      if (action.decisionObjectives?.length > 0) ptr = action.decisionObjectives[0]
      else if (action.blockedObjectives?.length > 0) ptr = action.blockedObjectives[0]
      else if (action.updateObjectives?.length > 0) ptr = action.updateObjectives[0]
      else if (action.adminTopicObjectives?.length > 0) ptr = action.adminTopicObjectives[0]
      else return null

      return ptr.activities[0].createdAt
    }

    let date1 = getDate(action1)
    let date2 = getDate(action2)

    if (date1 == null || date2 == null) return 0

    return date1 > date2 ? -1 : 1 // Reverse sort.
  }

  const refreshBGUActionsFn = async () => {
    let url = ''
    if (eIdsOfSelectedTls && eIdsOfSelectedTls.length !== 0) {
      url = url + '/' + eIdsOfSelectedTls.join(',')
    }
    try {
      const response = await getHttpRequest(`/bgu_open_closed_actions_scorecard_response`, {
        params: {
          viewMode: selectedViewMode,
          meetingId: selectedMeetingId,
        },
      })
      if (isOpen) {
        setAllData(response?.actions?.filter((action) => action.isOpen == 1))
      } else {
        setAllData(response?.actions?.filter((action) => action.isOpen == 0))
      }
      setPeopleFilterValue([])
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const updateDatasetWithFlagVal = (newFlagVal, milestoneId) => {
    const helper = (obj) => {
      if (obj && obj.length > 0) {
        if (obj[0].activities[0].milestoneId === milestoneId) {
          obj[0].activities[0].isPriority = newFlagVal
        }
      }
    }

    let newData = [...dataToShow]

    for (let i = 0; i < newData.length; i++) {
      let action = newData[i]
      helper(action.blockedObjectives)
      helper(action.decisionObjectives)
      helper(action.updateObjectives)
      helper(action.adminTopicObjectives)
    }
    setDataToShow(newData)
  }

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        closeModal={() => {
          closeModal()
        }}
        width={'90%'}
        style={{ padding: '0rem 0rem 1rem 0rem' }}
        title={t('scorecard.openCloseActionTable')}
      >
        <>
          {isActivityModalOpen === true && objectiveIdSelected && taskIdSelected && (
            <AddEditActivityModal
              isModalOpen={isActivityModalOpen}
              closeModal={() => {
                setIsActivityModalOpen(false)
                setObjectiveIdSelected(null)
                setTaskIdSelected(null)
              }}
              objectiveId={objectiveIdSelected}
              activityId={taskIdSelected}
              refreshBGUScorecardFn={refreshBGUScorecardFn}
              refreshBGUActionsFn={refreshBGUActionsFn}
              fromModal={true}
              hasTopicType={true}
              refreshMei={refreshMei}
            />
          )}
          {isTopicModalOpen === true && umtId && topicType && (
            <CardModal
              isModalOpen={isTopicModalOpen}
              closeModal={() => {
                setIsTopicModalOpen(false)
                setUmtId(null)
                setTopicType(null)
              }}
              umtId={umtId}
              topicType={topicType}
              hasTopicType={true}
            />
          )}
          {isLoading && <InPageLoader inComponent={true} />}
          {!isLoading && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                wordBreak: 'break-word',
              }}
            >
              <div style={{ width: '100%', marginBottom: '3rem', padding: '2rem' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '1rem',
                    marginBottom: '3rem',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '0.5rem',
                      textAlign: 'left',
                    }}
                  >
                    <label for="teamLeadersFilter">
                      <b>{t('Common:filter.ActionOwner')}:</b>
                    </label>
                    <div data-testid="multi-options-1" style={{ width: '18vw' }}>
                      <Select
                        value={peopleFilterValue}
                        isMulti
                        onChange={handleChangePeopleFilter}
                        maxMenuHeight={100}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.eId}
                        options={allAssignees}
                      />
                    </div>
                  </div>
                  <div
                    class="fitted-button blue"
                    onClick={() => {
                      applyFilters()
                    }}
                    onKeyDown={() => {
                      applyFilters()
                    }}
                    style={{ alignSelf: 'end' }}
                  >
                    <span>{t('Common:filter.applyFilter')}</span>
                  </div>
                </div>
                {dataToShow && dataToShow?.length > 0 ? (
                  <div
                    className="chart-log-meeting-action-table"
                    style={{
                      gridTemplateColumns: '2fr 2fr 2fr 1fr 1fr 0.8fr 0.7fr 0.5fr 0.5fr 0.5fr',
                    }}
                  >
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.objectiveTitle')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.topicTitle')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.actionStatementNew')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.createdBy')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.assignedTo')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.createdOn')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.dueDate')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.complete')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.priority')}
                    </div>
                    <div className="chart-log-meeting-header title-border">
                      {t('scorecard.more')}
                    </div>

                    {dataToShow?.sort(cmpSortDataByActionDate).map((action, actionIndex) => {
                      return (
                        <>
                          <>
                            {action?.blockedObjectives?.map((blocker, index) =>
                              blocker?.activities.map((activity, ind) => {
                                return (
                                  <React.Fragment key={`objective-${activity.milestoneId}`}>
                                    {/* <div></div> */}
                                    <div
                                      className="chart-log-meeting-description"
                                      style={{ gridColumn: '1 / 11' }}
                                    ></div>
                                    <>
                                      {/* <div></div> */}
                                      <>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {blocker.isObjCompleted ? (
                                            <div style={{ color: 'grey' }}>
                                              <b>{t('Common:completedCaps')}</b>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div>
                                            {productSettings &&
                                            blocker?.initiativeId !== 0 &&
                                            !blocker?.isGeneralObjective ? (
                                              <div>
                                                <span>
                                                  {blocker?.initiativeId
                                                    ?.toString()
                                                    ?.padStart(5, '0')}
                                                </span>
                                                <span
                                                  style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}
                                                >
                                                  {' '}
                                                  -
                                                </span>
                                                <span>{blocker.statement}</span>
                                              </div>
                                            ) : (
                                              <span>{blocker.statement}</span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="chart-log-blocker actions-activities-table-cell">
                                          {linkifyString(blocker.actionNeeded)}
                                        </div>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {linkifyString(
                                            removeMentionMarkupFromText(activity.description),
                                          )}
                                        </div>
                                        <div style={{ textAlign: 'left' }}>
                                          <span key={activity?.createdByAssignee?.name}>
                                            {activity?.createdByAssignee?.name ? (
                                              activity.createdByAssignee.name.replace(
                                                /(\b\w+\b)\s(\b\w)\w*\b/,
                                                '$1 $2',
                                              )
                                            ) : (
                                              <>---</>
                                            )}
                                          </span>
                                        </div>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className="actions-activities-table-cell"
                                        >
                                          {activity.assigneeList?.length > 0 ? (
                                            activity.assigneeList?.map((assignee, ind) => (
                                              <>
                                                <span key={assignee.name}>
                                                  {assignee.name.replace(
                                                    /(\b\w+\b)\s(\b\w)\w*\b/,
                                                    '$1 $2',
                                                  )}
                                                </span>
                                                {ind !== activity.assigneeList?.length - 1 && (
                                                  <span>, </span>
                                                )}
                                              </>
                                            ))
                                          ) : (
                                            <span>--</span>
                                          )}
                                        </div>
                                        {/* <div>{activity.assigneeList?.map(assignee => <b key={assignee.name}>{assignee.name}</b>)}</div> */}
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className="actions-activities-table-cell"
                                        >
                                          {dateFilter(activity.createdAt)}
                                        </div>
                                        <div>
                                          {activity.dueDate == 0
                                            ? '--'
                                            : dateFilter(activity.dueDate)}
                                        </div>
                                        <div
                                          style={{ display: 'flex', justifyContent: 'center' }}
                                          className="actions-activities-table-cell"
                                        >
                                          {action.isOpen == 1 && (
                                            <div
                                              class="fitted-button checklist"
                                              onClick={(e) => {
                                                handleCompleteClick(
                                                  blocker.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                              onKeyDown={(e) => {
                                                handleCompleteClick(
                                                  blocker.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                            >
                                              <span class="material-symbols-outlined grey-check">
                                                {' '}
                                                done
                                              </span>
                                            </div>
                                          )}

                                          {action.isOpen == 0 && (
                                            <div
                                              class="fitted-button checklist"
                                              onClick={(e) => {
                                                handleCompleteClick(
                                                  blocker.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                              onKeyDown={(e) => {
                                                handleCompleteClick(
                                                  blocker.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                            >
                                              <span class="material-symbols-outlined green-check ">
                                                done
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          <PriorityFlag
                                            activity={activity}
                                            updateDatasetWithFlagVal={updateDatasetWithFlagVal}
                                          />
                                        </div>
                                        <div>
                                          <SooDropdownMenu
                                            fromModal={true}
                                            fromAMA={true}
                                            onClickEdit={() => {
                                              setObjectiveIdSelected(blocker.objectiveId)
                                              setTaskIdSelected(activity.milestoneId)
                                              setIsActivityModalOpen(true)
                                            }}
                                            onClickComment={() => {
                                              setObjectiveIdSelected(blocker.objectiveId)
                                              setTaskIdSelected(activity.milestoneId)
                                              setIsCommentModalOpen(true)
                                            }}
                                            onClickSeeTopic={() => {
                                              setTopicType(blocker.type)
                                              setUmtId(blocker.umtId)
                                              setIsTopicModalOpen(true)
                                            }}
                                          />
                                        </div>
                                      </>
                                    </>
                                  </React.Fragment>
                                )
                              }),
                            )}
                          </>
                          <>
                            {action?.decisionObjectives?.map((decision, index) =>
                              decision?.activities.map((activity, ind) => {
                                return (
                                  <React.Fragment key={`objective-${activity.milestoneId}`}>
                                    {/* <div></div> */}
                                    <div
                                      className="chart-log-meeting-description"
                                      style={{ gridColumn: '1 / 11' }}
                                    ></div>
                                    <>
                                      {/* <div></div> */}
                                      <>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {decision.isObjCompleted ? (
                                            <div style={{ color: 'grey' }}>
                                              <b>{t('Common:completedCaps')}</b>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div>
                                            {productSettings &&
                                            decision?.initiativeId !== 0 &&
                                            !decision?.isGeneralObjective ? (
                                              <div>
                                                <span>
                                                  {decision?.initiativeId
                                                    .toString()
                                                    ?.padStart(5, '0')}
                                                </span>
                                                <span
                                                  style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}
                                                >
                                                  {' '}
                                                  -
                                                </span>
                                                <span>{decision.statement}</span>
                                              </div>
                                            ) : (
                                              <span>{decision.statement}</span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="chart-log-decision actions-activities-table-cell">
                                          {linkifyString(decision.actionNeeded)}
                                        </div>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {linkifyString(
                                            removeMentionMarkupFromText(activity.description),
                                          )}
                                        </div>
                                        <div style={{ textAlign: 'left' }}>
                                          <span key={activity?.createdByAssignee?.name}>
                                            {activity?.createdByAssignee?.name ? (
                                              activity.createdByAssignee.name.replace(
                                                /(\b\w+\b)\s(\b\w)\w*\b/,
                                                '$1 $2',
                                              )
                                            ) : (
                                              <>---</>
                                            )}
                                          </span>
                                        </div>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {activity.assigneeList?.length > 0 ? (
                                            activity.assigneeList?.map((assignee, ind) => (
                                              <>
                                                <span key={assignee.name}>
                                                  {assignee.name.replace(
                                                    /(\b\w+\b)\s(\b\w)\w*\b/,
                                                    '$1 $2',
                                                  )}
                                                </span>
                                                {ind !== activity.assigneeList?.length - 1 && (
                                                  <span>, </span>
                                                )}
                                              </>
                                            ))
                                          ) : (
                                            <span>--</span>
                                          )}
                                        </div>

                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {dateFilter(activity.createdAt)}
                                        </div>
                                        <div>
                                          {' '}
                                          {activity.dueDate == 0
                                            ? '--'
                                            : dateFilter(activity.dueDate)}{' '}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                          {action.isOpen == 1 && (
                                            <div
                                              class="fitted-button checklist-done"
                                              onClick={(e) => {
                                                handleCompleteClick(
                                                  decision.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                              onKeyDown={(e) => {
                                                handleCompleteClick(
                                                  decision.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                            >
                                              <span class="material-symbols-outlined checklist-done-grey">
                                                {' '}
                                                done
                                              </span>
                                            </div>
                                          )}

                                          {action.isOpen == 0 && (
                                            <div
                                              class="fitted-button checklist-done"
                                              onClick={(e) => {
                                                handleCompleteClick(
                                                  decision.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                              onKeyDown={(e) => {
                                                handleCompleteClick(
                                                  decision.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                            >
                                              <span class="material-symbols-outlined checklist-done-green">
                                                done
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          <PriorityFlag
                                            activity={activity}
                                            updateDatasetWithFlagVal={updateDatasetWithFlagVal}
                                          />
                                        </div>
                                        <div>
                                          <SooDropdownMenu
                                            fromModal={true}
                                            fromAMA={true}
                                            onClickSeeTopic={() => {
                                              setTopicType(decision.type)
                                              setUmtId(decision.umtId)
                                              setIsTopicModalOpen(true)
                                            }}
                                            onClickEdit={() => {
                                              setObjectiveIdSelected(decision.objectiveId)
                                              setTaskIdSelected(activity.milestoneId)
                                              setIsActivityModalOpen(true)
                                            }}
                                            onClickComment={() => {
                                              setObjectiveIdSelected(decision.objectiveId)
                                              setTaskIdSelected(activity.milestoneId)
                                              setIsCommentModalOpen(true)
                                            }}
                                          />
                                        </div>
                                        {/* <div></div> */}
                                      </>
                                    </>
                                  </React.Fragment>
                                )
                              }),
                            )}
                          </>
                          <>
                            {action?.updateObjectives?.map((update, index) =>
                              update?.activities.map((activity, ind) => {
                                return (
                                  <React.Fragment key={`objective-${activity.milestoneId}`}>
                                    {/* <div></div> */}
                                    <div
                                      className="chart-log-meeting-description"
                                      style={{ gridColumn: '1 / 11' }}
                                    ></div>
                                    <>
                                      {/* <div></div> */}
                                      <>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {update.isObjCompleted ? (
                                            <div style={{ color: 'grey' }}>
                                              <b>{t('Common:completedCaps')}</b>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div>
                                            {productSettings &&
                                            update?.initiativeId !== 0 &&
                                            !update?.isGeneralObjective ? (
                                              <div>
                                                <span>
                                                  {update?.initiativeId
                                                    .toString()
                                                    ?.padStart(5, '0')}
                                                </span>
                                                <span
                                                  style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}
                                                >
                                                  {' '}
                                                  -
                                                </span>
                                                <span>{update.statement}</span>
                                              </div>
                                            ) : (
                                              <span>{update.statement}</span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="chart-log-update actions-activities-table-cell">
                                          {linkifyString(update.actionNeeded)}
                                        </div>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {linkifyString(
                                            removeMentionMarkupFromText(activity.description),
                                          )}
                                        </div>
                                        <div style={{ textAlign: 'left' }}>
                                          <span key={activity?.createdByAssignee?.name}>
                                            {activity?.createdByAssignee?.name ? (
                                              activity.createdByAssignee.name.replace(
                                                /(\b\w+\b)\s(\b\w)\w*\b/,
                                                '$1 $2',
                                              )
                                            ) : (
                                              <>---</>
                                            )}
                                          </span>
                                        </div>

                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {activity.assigneeList?.length > 0 ? (
                                            activity.assigneeList?.map((assignee, ind) => (
                                              <>
                                                <span key={assignee.name}>
                                                  {assignee.name.replace(
                                                    /(\b\w+\b)\s(\b\w)\w*\b/,
                                                    '$1 $2',
                                                  )}
                                                </span>
                                                {ind !== activity.assigneeList?.length - 1 && (
                                                  <span>, </span>
                                                )}
                                              </>
                                            ))
                                          ) : (
                                            <span>--</span>
                                          )}
                                        </div>

                                        <div style={{ textAlign: 'left' }}>
                                          {dateFilter(activity.createdAt)}
                                        </div>
                                        <div>
                                          {' '}
                                          {activity.dueDate == 0
                                            ? '--'
                                            : dateFilter(activity.dueDate)}{' '}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                          {action.isOpen === 1 && (
                                            <div
                                              class="fitted-button checklist"
                                              onClick={(e) => {
                                                handleCompleteClick(
                                                  update.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                              onKeyDown={(e) => {
                                                handleCompleteClick(
                                                  update.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                            >
                                              <span class="material-symbols-outlined grey-check">
                                                {' '}
                                                done
                                              </span>
                                            </div>
                                          )}

                                          {action.isOpen === 0 && (
                                            <div
                                              class="fitted-button checklist"
                                              onClick={(e) => {
                                                handleCompleteClick(
                                                  update.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                              onKeyDown={(e) => {
                                                handleCompleteClick(
                                                  update.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                            >
                                              <span class="material-symbols-outlined green-check ">
                                                done
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          <PriorityFlag
                                            activity={activity}
                                            updateDatasetWithFlagVal={updateDatasetWithFlagVal}
                                          />
                                        </div>
                                        <div>
                                          <SooDropdownMenu
                                            fromModal={true}
                                            fromAMA={true}
                                            onClickSeeTopic={() => {
                                              setTopicType(update.type)
                                              setUmtId(update.umtId)
                                              setIsTopicModalOpen(true)
                                            }}
                                            onClickEdit={() => {
                                              setObjectiveIdSelected(update.objectiveId)
                                              setTaskIdSelected(activity.milestoneId)
                                              setIsActivityModalOpen(true)
                                            }}
                                            onClickComment={() => {
                                              setObjectiveIdSelected(update.objectiveId)
                                              setTaskIdSelected(activity.milestoneId)
                                              setIsCommentModalOpen(true)
                                            }}
                                          />
                                        </div>

                                        {/* <div></div> */}
                                      </>
                                    </>
                                  </React.Fragment>
                                )
                              }),
                            )}
                          </>

                          <>
                            {action?.adminTopicObjectives?.map((update, index) =>
                              update?.activities.map((activity, ind) => {
                                return (
                                  <React.Fragment key={`objectiveAb-${activity.milestoneId}`}>
                                    {/* <div></div> */}
                                    <div
                                      className="chart-log-meeting-description"
                                      style={{ gridColumn: '1 / 11' }}
                                    ></div>
                                    <>
                                      {/* <div></div> */}
                                      <>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {update.isObjCompleted ? (
                                            <div style={{ color: 'grey' }}>
                                              <b>{t('Common:completedCaps')}</b>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div>
                                            {productSettings &&
                                            update?.initiativeId !== 0 &&
                                            !update?.isGeneralObjective ? (
                                              <div>
                                                <span>
                                                  {update?.initiativeId
                                                    .toString()
                                                    ?.padStart(5, '0')}
                                                </span>
                                                <span
                                                  style={{ padding: '0rem 0.2rem 0rem 0.2rem' }}
                                                >
                                                  {' '}
                                                  -
                                                </span>
                                                <span>{update.statement}</span>
                                              </div>
                                            ) : (
                                              <span>{update.statement}</span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="chart-log-admin-topic actions-activities-table-cell">
                                          {linkifyString(update.actionNeeded)}
                                        </div>
                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {linkifyString(
                                            removeMentionMarkupFromText(activity.description),
                                          )}
                                        </div>
                                        <div style={{ textAlign: 'left' }}>
                                          <span key={activity?.createdByAssignee?.name}>
                                            {activity?.createdByAssignee?.name ? (
                                              activity.createdByAssignee.name.replace(
                                                /(\b\w+\b)\s(\b\w)\w*\b/,
                                                '$1 $2',
                                              )
                                            ) : (
                                              <>---</>
                                            )}
                                          </span>
                                        </div>

                                        <div
                                          style={{ textAlign: 'left' }}
                                          className={'actions-activities-table-cell'}
                                        >
                                          {activity.assigneeList?.length > 0 ? (
                                            activity.assigneeList?.map((assignee, ind) => (
                                              <>
                                                <span key={assignee.name}>
                                                  {assignee.name.replace(
                                                    /(\b\w+\b)\s(\b\w)\w*\b/,
                                                    '$1 $2',
                                                  )}
                                                </span>
                                                {ind !== activity.assigneeList?.length - 1 && (
                                                  <span>, </span>
                                                )}
                                              </>
                                            ))
                                          ) : (
                                            <span>--</span>
                                          )}
                                        </div>

                                        <div style={{ textAlign: 'left' }}>
                                          {dateFilter(activity.createdAt)}
                                        </div>
                                        <div>
                                          {' '}
                                          {activity.dueDate == 0
                                            ? '--'
                                            : dateFilter(activity.dueDate)}{' '}
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                          {action.isOpen === 1 && (
                                            <div
                                              class="fitted-button checklist"
                                              onClick={(e) => {
                                                handleCompleteClick(
                                                  update.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                              onKeyDown={(e) => {
                                                handleCompleteClick(
                                                  update.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                            >
                                              <span class="material-symbols-outlined grey-check">
                                                {' '}
                                                done
                                              </span>
                                            </div>
                                          )}

                                          {action.isOpen === 0 && (
                                            <div
                                              class="fitted-button checklist"
                                              onClick={(e) => {
                                                handleCompleteClick(
                                                  update.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                              onKeyDown={(e) => {
                                                handleCompleteClick(
                                                  update.objectiveId,
                                                  activity.milestoneId,
                                                  actionIndex,
                                                  action.isOpen,
                                                )(e)
                                              }}
                                            >
                                              <span class="material-symbols-outlined green-check ">
                                                done
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        <div>
                                          <PriorityFlag
                                            activity={activity}
                                            updateDatasetWithFlagVal={updateDatasetWithFlagVal}
                                          />
                                        </div>
                                        <div>
                                          <SooDropdownMenu
                                            fromModal={true}
                                            fromAMA={true}
                                            onClickSeeTopic={() => {
                                              setTopicType(update.type)
                                              setUmtId(update.umtId)
                                              setIsTopicModalOpen(true)
                                            }}
                                            onClickEdit={() => {
                                              setObjectiveIdSelected(update.objectiveId)
                                              setTaskIdSelected(activity.milestoneId)
                                              setIsActivityModalOpen(true)
                                            }}
                                            onClickComment={() => {
                                              setObjectiveIdSelected(update.objectiveId)
                                              setTaskIdSelected(activity.milestoneId)
                                              setIsCommentModalOpen(true)
                                            }}
                                          />
                                        </div>

                                        {/* <div></div> */}
                                      </>
                                    </>
                                  </React.Fragment>
                                )
                              }),
                            )}
                            <div class="row-border" style={{ gridColumn: '1 / 11' }}></div>
                          </>
                        </>
                      )
                    })}
                  </div>
                ) : (
                  <div style={{ padding: '2rem' }}>{t('decisionLog.thereAreNoLogsAtThisTime')}</div>
                )}
              </div>
            </div>
          )}
        </>
      </Modal>
      {isCommentModalOpen && (
        <CommentsModal2
          objective={{ id: objectiveIdSelected, ...objective }}
          taskId={taskIdSelected}
          handleCancel={() => {
            setIsCommentModalOpen(false)
          }}
          setIsRefreshObjective={() => {}}
        />
      )}
    </>
  )
}

const PriorityFlag = ({ activity, updateDatasetWithFlagVal }) => {
  return (
    <>
      {!activity.isPriority && (
        <button class="icon-button-no-border" style={{ display: 'inline-block' }}>
          <span
            class="material-symbols-outlined icon-size"
            onClick={() => {
              markActivityAsPriority(true, activity.milestoneId)
              updateDatasetWithFlagVal(true, activity.milestoneId)
            }}
            onKeyDown={() => {
              markActivityAsPriority(true, activity.milestoneId)
              updateDatasetWithFlagVal(true, activity.milestoneId)
            }}
          >
            flag
          </span>
        </button>
      )}
      {activity.isPriority && (
        <button class="icon-button-no-border" style={{ display: 'inline-block' }}>
          <span
            class="material-symbols-outlined icon-size"
            style={{ color: 'red' }}
            onClick={() => {
              markActivityAsPriority(false, activity.milestoneId)
              updateDatasetWithFlagVal(false, activity.milestoneId)
            }}
            onKeyDown={() => {
              markActivityAsPriority(false, activity.milestoneId)
              updateDatasetWithFlagVal(false, activity.milestoneId)
            }}
          >
            flag
          </span>
        </button>
      )}
    </>
  )
}

export default BGUOpenCloseActionTableModal

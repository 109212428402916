import React from 'react'
// import { useDemandIntakeContext } from '../../context/demandIntakeContext'
import CloseIcon from '../../../assets/close-icon-white.svg'
import PlusIcon from '../../../assets/plus.svg'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { getHttpRequest } from '../../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import { getCompanyConfigSettings } from '../../../utils/auth'

const ObjTeamMappingTable = ({ contextHandler }) => {
  const { t } = useTranslation(['Common'])
  // const { state, dispatch } = useDemandIntakeContext()
  const { state, dispatch } = contextHandler()
  const productSettings = getCompanyConfigSettings('productSettings')

  const handleDelete = (oId, mId) => {
    const clone = [...state.data.objectives]
    const currentObjectiveIndex = clone.findIndex((o) => o.id === oId)
    const cloneEmployees = [...state.data.employees]
    if (currentObjectiveIndex > -1) {
      const teamClone = [...state.data.objectives[currentObjectiveIndex].team]
      const currentMemberIndex = teamClone.findIndex((t) => t.eId === mId)
      let totaltAllocToDelete = teamClone[currentMemberIndex].value
      if (currentMemberIndex > -1) {
        teamClone.splice(currentMemberIndex, 1)
        clone[currentObjectiveIndex].team = teamClone
        dispatch({ type: 'UPDATE_OBJECTIVES', objectives: clone })

        const currentEmployeeIndex = cloneEmployees.findIndex((e) => e.eId == mId)
        if (currentEmployeeIndex > -1) {
          let totalAlloc = cloneEmployees[currentEmployeeIndex].totalAlloc
          cloneEmployees[currentEmployeeIndex].totalAlloc = totalAlloc - totaltAllocToDelete
          dispatch({ type: 'UPDATE_EMPLOYEES', employees: cloneEmployees })
        }
      }
    }
  }

  const removeSharedObjective = async (objective) => {
    let allocation =
      objective?.team
        ?.filter((x) => x.value && x.canDelete)
        ?.map((t) => ({ eId: t.eId, allocPct: t.value, objId: objective.id })) || []
    const data = await getHttpRequest('/remove_shared_rights_on_objective', {
      params: {
        allocation: JSON.stringify(allocation),
        initiativeId: objective.initiativeId,
        teamLeaderId: state?.locationState?.teamLeaderId,
      },
    })
    if (data.success) {
      const clone = [...state.data.objectives]
      const currentObjectiveIndex = clone.findIndex((o) => o.id === objective.id)
      if (currentObjectiveIndex > -1) {
        clone.splice(currentObjectiveIndex, 1)
        dispatch({ type: 'UPDATE_OBJECTIVES', objectives: clone })
      }
    }
  }

  async function confirmRemoveSharedObjective(objective) {
    Swal.fire({
      title: t('Common:modalMessages.areYouSureRemoveX', { X: objective.statement }),
      text: '',
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        removeSharedObjective(objective)
      }
    })
  }

  return (
    <>
      <div>
        <div
          class="fitted-button blue"
          onClick={() => dispatch({ type: 'ALLOC_SUB_WIZ__OPEN_ADD_OBJECTIVE_MODAL' })}
        >
          {t('objTeamMappingTable.addShared')}
        </div>
      </div>

      <ul className="team-objectives-head-list" style={{ marginBottom: '0rem' }}>
        <li className="head-item">{t('objTeamMappingTable.objective')}</li>
        <li className="head-item" style={{ marginLeft: '1rem' }}>
          {t('objTeamMappingTable.teamMembers')}
        </li>
      </ul>
      <ul className="team-objectives-head-list body-objective-list">
        {state?.data?.objectives
          ?.filter((x) => !x?.showAssignedActivitiesOnly)
          .map((objective, index) => (
            <>
              <li className="body-item-objective" style={{ borderBottom: '1px solid #aaa' }}>
                {objective.isShared && <span>SHARED - </span>}
                <div style={{ display: 'inline' }}>
                  {productSettings &&
                    objective?.initiativeId !== 0 &&
                    !objective?.isGeneralObjective && (
                      <div style={{ display: 'inline', marginRight: '0' }}>
                        {objective?.initiativeId.toString().padStart(5, '0')} -&nbsp;
                      </div>
                    )}
                  <div style={{ display: 'inline' }}>{objective.statement}</div>
                </div>
                <br />
                {objective.isShared && (
                  <>
                    <div
                      style={{ marginTop: '0.2rem' }}
                      class="fitted-button blue"
                      onClick={() => confirmRemoveSharedObjective(objective)}
                    >
                      {t('objTeamMappingTable.removeShared')}
                    </div>
                  </>
                )}
                <div
                  style={{ marginTop: '0.5rem' }}
                  class="fitted-button blue"
                  data-testid={`Add Team Member-${index}`}
                  onClick={() =>
                    dispatch({
                      type: 'ALLOC_SUB_WIZ__OPEN_TEAMMEMBER_FORM_MODAL',
                      id: objective.id,
                    })
                  }
                >
                  {t('objTeamMappingTable.addTeamMember')}
                </div>
                <div>&nbsp;</div>
              </li>
              <li style={{ borderBottom: '1px solid #aaa' }}>
                <ul className="inner-member-list">
                  {objective.team &&
                    objective.team.map((u) => {
                      return (
                        <li className="body-item">
                          <div
                            className="member-icon-wrapper"
                            style={{ ...(u.canDelete ? {} : { opacity: 0.6 }) }}
                            data-testid={`${u.name}-member`}
                          >
                            {u.name}
                            {u.canDelete && (
                              <div
                                class="fitted-button red"
                                onClick={() => handleDelete(objective.id, u.eId)}
                              >
                                <span class="material-symbols-outlined icon-size">close</span>
                              </div>
                            )}
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </li>
            </>
          ))}
      </ul>
    </>
  )
}

export default ObjTeamMappingTable

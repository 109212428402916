import React, { useEffect, useState } from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import ObjectiveMapByObjective from '../objectiveFlow/objectiveMode'
import {
  getListOfObjectivesFlow,
  getPicaasoProcess,
  getPicaasoProcessForTeamLeader,
  savePicassoObjectivesForTeamLeader,
} from '../../api/services/objectives.services'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import useDeleteObjective_v2 from '../../api/mutation/deleteObjectiveHome_v2'
import Toast from '../../common/toast'
import useEditObjectiveComplete_v2 from '../../api/mutation/editObjectiveCompleteHome_v2'
import { getEmailFromName } from '../../utils/general'
import ChatBox from './Chatbox'
import SendMessageModal from '../../components/ConfirmEmailModal/SendMessageModal'
import { useNavigate } from 'react-router-dom'
import MatchEmployeesModal from '../../components/MatchEmployeesModal'
import useSocket from '../../common/useSocket'
import { useMicVAD } from '@ricky0123/vad-react'
import './styles.css'
import { json } from 'd3'
import { finishCompanySetUp } from '../../api/services/employee.services'

const TeamLeaderSetupAiAgentSocket = () => {
  const [objectiveList, setObjectiveList] = useState([])
  const [objId, setObjId] = useState(null)
  const [singleObjId, setSingleObjId] = useState(null)

  const { t } = useTranslation(['Common', 'ObjectiveMap'])
  const [messageList, setMessageList] = useState([{ assistant_message: '' }])
  const [messageId, setMessageId] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [logId, setLogId] = useState(0)
  const [nextQuestion, setNextQuestion] = useState(null)
  const [newEmployee, setNewEmployee] = useState([])
  const [isObjectiveMapFinished, setIsObjectiveMapFinished] = useState(false)
  const [isConfirmEmailModalOpen, setIsConfirmEmailModalOpen] = useState(false)
  const [isSendEmialModalOpen, setIsSendEmailModalOpen] = useState(false)
  const [newEmployeeWithId, setNewEmployeeWithId] = useState([])
  const [degradedCanvas, setDegradedCanvas] = useState(null)

  const socket = useSocket('tl_agent')
  const mediaRecorderRef = React.useRef(null)
  const audioContextRef = React.useRef(null)
  const [isSocketStarted, setIsSocketStarted] = useState(false)
  const bufferQueueRef = React.useRef([])

  const audioPlayerRef = React.useRef(null)
  const mediaSourceRef = React.useRef(null)
  const sourceBufferRef = React.useRef(null)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [isSetUpCompleted, setIsSetUpCompleted] = React.useState(false)
  const firstRendered = React.useRef(true)
  const [userState, setUserState] = React.useState(null)
  const isAudioPausedRef = React.useRef(false)
  const [isAudioPaused, setIsAudioPaused] = React.useState(false)
  const [justWaitingForUser, setJustWaitingForUser] = useState(null)
  const [loadingObjectiveMap, setLoadingObjectiveMap] = useState(false)
  const [showEng, setShowEng] = useState(true)
  const [existingHierachy, setExistingHierachy] = useState(null)
  const [existingTopics, setExistingTopics] = useState(null)

  // this is to handle the case when you click pause without saying anything, then se;f.start_ts in the backend
  // will be null because onSpeechStart is not fired. we need currentStartTs (do processing if it's null) to handle this case
  const currentStartTs = React.useRef(null)

  const vad = useMicVAD({
    startOnLoad: false,
    onSpeechStart: (audio) => {
      if (!isAudioPausedRef.current) {
        setJustWaitingForUser(false)
        console.log('User started talking')
        setUserState('speaking')
        if (
          audioContextRef.current &&
          audioPlayerRef.current &&
          audioPlayerRef.current.currentTime
        ) {
          audioPlayerRef.current.pause()
          audioPlayerRef.current.currentTime = 0
          bufferQueueRef.current = []
          if (sourceBufferRef.current && sourceBufferRef.current.updating) {
            sourceBufferRef.current.addEventListener('updateend', resetMediaSource, { once: true })
          } else {
            resetMediaSource()
          }
        }
        currentStartTs.current = Date.now()
        socket.emit('vad_interrupt', {
          event: 'speech_start',
          isRecording: true,
          timestamp: Date.now(),
          first_request: false,
          isObjectiveMapFinished: isObjectiveMapFinished,
        })
      } else {
        console.log('User started but is paused')
      }
    },
    onSpeechEnd: (audio) => {
      if (!isAudioPausedRef.current) {
        setJustWaitingForUser(false)
        console.log('User stopped talking')
        setUserState('waiting')
        setIsLoading(true)
        socket.emit('vad_interrupt', {
          event: 'speech_end',
          isRecording: true,
          timestamp: Date.now(),
          first_request: false,
          isObjectiveMapFinished: isObjectiveMapFinished,
          currentStartTs: currentStartTs.current,
        })
        currentStartTs.current = null
      } else {
        console.log('User stopped but is paused')
      }
    },
    redemptionFrames: 25,
    positiveSpeechThreshold: 0.85,
    negativeSpeechThreshold: 0.6,
  })

  useEffect(() => {
    console.log('isLoading', isLoading)
  }, [isLoading])

  useEffect(() => {
    console.log('isAudioPaused', isAudioPaused)
    isAudioPausedRef.current = isAudioPaused
  }, [isAudioPaused])

  const startStreaming = async () => {
    setIsSocketStarted(true)
  }

  const initializeAudioMedia = async () => {
    console.log('zzzzz')

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)()
    }

    // set up mic input
    const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    const audioSourceNode = audioContextRef.current.createMediaStreamSource(audioStream)

    // setAudioStreamForVisual(audioStream)

    const destinationNode = audioContextRef.current.createMediaStreamDestination()
    audioSourceNode.connect(destinationNode)
    mediaRecorderRef.current = new MediaRecorder(destinationNode.stream)

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        let audiodata = {
          data: event.data,
          // millisec
          ts: Date.now(),
          isPaused: isAudioPausedRef.current,
        }
        // console.log('audio_chunk_input', audiodata)
        socket.emit('audio_chunk_input', audiodata)
      }
    }

    mediaRecorderRef.current.start(250)
    console.log('yyyyy')
  }

  const onTtsComplete = (data) => {
    setLoadingObjectiveMap(true)
    console.log('tts_complete')
    // socket.emit('started_recording', {'started_recording': true})
    console.log(data.text)

    setMessageList((prev) => {
      let copyMessageList = prev.map((m) => ({ ...m }))
      if (copyMessageList.length === 0) {
        copyMessageList.push({ assistant_message: data.text })
        return copyMessageList
      }
      let lastChatBubble = copyMessageList[copyMessageList.length - 1]
      lastChatBubble.assistant_message += ' ' + data.text
      return copyMessageList
    })
    // setMessageList(prev => [...prev, { assistant_message: data.text }])
  }

  const onAudioChunkOutput = async (data) => {
    setUserState('listening')
    setIsLoading(false)
    if (showEng) {
      const arrayBuffer = new Uint8Array(data.chunk).buffer
      bufferQueueRef.current.push(arrayBuffer)
      if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
        appendNextChunk()
      }
    }
  }

  const onFinalJson = (data) => {
    setJustWaitingForUser(true)
    setLoadingObjectiveMap(false)
    console.log('data.json_str =', data.json_str)
    let response = JSON.parse(data.json_str)
    let employeeList = []
    if (response) {
      // let parsedMessage = JSON.parse(response.message)
      // setMessageList(parsedMessage)
      setObjectiveList(response.canvasResponse)
      let degradedCanvas = degradeCanvas(JSON.parse(JSON.stringify(response.canvasResponse)))
      setDegradedCanvas(degradedCanvas)
      setMessageId(response.messageId)
      setLogId(response.logId)
      // let parsedMessage = JSON.parse(response.message)
      // setMessageList(parsedMessage)
      setNextQuestion(response.nextQuestionForUser)
      //setNewEmployee(response.newEmployee)
      if (!isObjectiveMapFinished) {
        setNewEmployee(response.directReportList)
        let level3_cards = response.canvasResponse.levelCards.filter((card) => card.level === 3)[0]
        if (level3_cards?.level) {
          for (let group of level3_cards.cardDisplayGroups) {
            for (let card of group.cards) {
              if (employeeList.some((e) => e.name === card.owner)) {
                continue
              } else {
                employeeList.push({ name: card.owner, id: card.ownerId })
              }
            }
          }
        }
        setNewEmployeeWithId(employeeList)
      }
      setIsObjectiveMapFinished(response.finishObjectiveHierachy)
      if (response.finishObjectiveHierachy) {
        socket.emit('update_shared_state', { isObjectiveMapFinished: true })
      }
    }
  }

  function addSourceBuffer() {
    sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/aac')
    sourceBufferRef.current.addEventListener('updateend', appendNextChunk)
  }

  function initializeMediaSource() {
    const audioPlayer = audioPlayerRef.current
    mediaSourceRef.current = new MediaSource()
    const url = URL.createObjectURL(mediaSourceRef.current)
    audioPlayer.src = url

    mediaSourceRef.current.addEventListener('sourceopen', addSourceBuffer)
  }

  function resetMediaSource() {
    if (mediaSourceRef.current.readyState === 'open') {
      mediaSourceRef.current.endOfStream()
    }
    // if (sourceBufferRef.current) {
    //     sourceBufferRef.current.removeEventListener('updateend', appendNextChunk);
    //     sourceBufferRef.current = null;
    // }
    initializeMediaSource()
  }

  function appendNextChunk() {
    // console.log('sourceBufferRef.current.updating: ', sourceBufferRef.current.updating)
    if (
      mediaSourceRef.current &&
      bufferQueueRef.current.length > 0 &&
      !sourceBufferRef.current.updating &&
      mediaSourceRef.current.readyState === 'open'
    ) {
      const chunk = bufferQueueRef.current.shift()
      sourceBufferRef.current.appendBuffer(chunk)
    }
    attemptAutoPlay()
  }

  function attemptAutoPlay() {
    const audioPlayer = audioPlayerRef.current
    if (!isPlaying && audioPlayer.readyState >= 3) {
      // HAVE_FUTURE_DATA
      audioPlayer
        .play()
        .then(() => {
          // console.log('Playing audio...');
          setIsPlaying(true)
        })
        .catch((e) => console.error('Autoplay failed:', e))
    }
  }

  const onReceiveTranscript = (data) => {
    // add a dummy assistant message so it can be appended in onttscComplete
    let newMessages = [{ user_message: data.text }, { assistant_message: '' }]
    console.log('newMessages', newMessages)
    setMessageList((prev) => [...prev, ...newMessages])
    // setMessageList(prev => [...prev, ...[{ user_message: data.text }, { assistant_message: '' }]])
    // setMessageList(prev => [...prev, { user_message: data.text }])
  }

  const onTranscriptCompleted = (data) => {}

  const onQuestionText = (data) => {
    // setIsLoading(false)
    // setMessageList(prev => [...prev, { assistant_message: data.text }])
  }

  const cleanupSocket = () => {
    vad.pause()
    socket.off('audio_chunk_output', onAudioChunkOutput)
    socket.off('tts_complete', onTtsComplete)
    socket.off('final_json', onFinalJson)
    socket.off('user_transcript', onReceiveTranscript)
    socket.off('transcript_completed', onTranscriptCompleted)
    socket.off('question_text', onQuestionText)
    socket.disconnect()
    if (mediaSourceRef.current && mediaSourceRef.current.readyState === 'open') {
      mediaSourceRef.current.endOfStream()
      mediaSourceRef.current = null
    }
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop()
      if (audioContextRef.current) {
        audioContextRef.current.close()
        audioContextRef.current = null
      }
    }
  }

  useEffect(() => {
    if (!isSocketStarted) return
    ;(async () => {
      await initializeAudioMedia()
      initializeMediaSource()
      vad.start()
      socket.connect()
      socket.emit('enable_socket_connection', {
        name: localStorage.getItem('tfmnm'),
        eId: localStorage.getItem('tfei'),
        language: showEng ? 'en' : 'es',
        existingTopics: existingTopics,
        existingHierachy: existingHierachy,
      })
      socket.on('audio_chunk_output', onAudioChunkOutput)
      socket.on('tts_complete', onTtsComplete)
      socket.on('final_json', onFinalJson)
      socket.on('user_transcript', onReceiveTranscript)
      socket.on('question_text', onQuestionText)
      setIsLoading(true)
      setUserState('waiting')
      socket.emit('vad_interrupt', {
        first_request: true,
        isRecording: false,
        isObjectiveMapFinished: isObjectiveMapFinished,
        isPaused: false,
      })

      return () => {
        cleanupSocket()
      }
    })()
  }, [isSocketStarted])

  useEffect(() => {
    sendMessages()
  }, [])

  const sendMessages = async (audioText = null) => {
    setIsLoading(true)
    setMessageList([])
    const response = await getPicaasoProcessForTeamLeader(
      '',
      true,
      nextQuestion,
      messageId,
      logId,
      isObjectiveMapFinished,
      true,
    )
    if (response) {
      setObjectiveList(response.canvasResponse)
      let degradedCanvas = degradeCanvas(JSON.parse(JSON.stringify(response.canvasResponse)))
      setDegradedCanvas(degradedCanvas)
      setMessageId(response.messageId)
      setLogId(response.logId)
      // let parsedMessage = JSON.parse(response.message)
      // setMessageList(parsedMessage)
      setNextQuestion(response.nextQuestionForUser)
      setIsObjectiveMapFinished(response.finishObjectiveHierachy)
      setExistingHierachy(response.existingHierachy)
      setExistingTopics(response.existingTopics)
    } else {
      setMessageList((prevMessageList) => [
        ...prevMessageList,
        { assistant_message: t('Common:companyAiSetupAgent.noResponse') },
      ])
    }
    setIsLoading(false)
  }

  const degradeCanvas = (canvas) => {
    let level_cards = canvas.levelCards
    let newLevelCards = level_cards
      .filter((level_card) => level_card.level !== 1)
      .map((level_card) => {
        if (level_card.level === 2 || level_card.level === 3) {
          level_card.level -= 1
          return level_card
        }
      })
    canvas.levelCards = newLevelCards
    return canvas
  }

  const saveObjectives = async () => {
    const response = await savePicassoObjectivesForTeamLeader(objectiveList.levelCards)
    const res = await finishCompanySetUp('team')
    console.log(response)
    // navigate(`/${routes.teamMeetingview}`, {
    //   state: { fromSetUpAgent: true },
    // })
    // navigate(0)
  }

  return (
    <ApplicationLayout fromSetUpAgent={true}>
      {isConfirmEmailModalOpen && (
        <MatchEmployeesModal
          isModalOpen={isConfirmEmailModalOpen}
          closeModal={() => {
            setIsConfirmEmailModalOpen(false)
          }}
          newEmployee={newEmployee}
          objectiveList={objectiveList}
          setIsSendEmailModalOpen={setIsSendEmailModalOpen}
          newEmployeeWithId={newEmployeeWithId}
          setIsSetUpCompleted={setIsSetUpCompleted}
        />
      )}
      {isSendEmialModalOpen && (
        <SendMessageModal
          isModalOpen={isSendEmialModalOpen}
          closeModal={() => {
            setIsSendEmailModalOpen(false)
          }}
        />
      )}
      <div style={{ display: 'block', marginTop: '3rem', display: 'flex', flexDirection: 'row' }}>
        <audio ref={audioPlayerRef} style={{ display: 'none' }}></audio>

        <div style={{ width: '65%', overflow: 'hidden', marginTop: '3rem' }}>
          {loadingObjectiveMap && <div className="obj_map_loader" />}
          <ObjectiveMapByObjective
            objectiveList={degradedCanvas}
            activitiesModalClickHander={() => {}}
            setIsObjHistoryOpen={() => {}}
            setObjId={setObjId}
            setIsAddEditObjModalOpen={() => {}}
            setIsL1L2ModalOpen={() => {}}
            setLastClickedLevel={() => {}}
            editedObj={() => {}}
            lastClickedLevel={0}
            setObjModalMode={() => {}}
            setObjData={() => {}}
            setActivitiesModalMode={() => {}}
            setIsNotificationModalOpen={() => {}}
            setIsRequestModalOpen={() => {}}
            singleObjId={singleObjId}
            setSingleObjId={setSingleObjId}
            setIsKPIModalOpen={() => {}}
            setIsViewFilesModalOpen={() => {}}
            setOpenOnSpecificKpi={() => {}}
            loadAgain={() => {}}
            ShowDeleteObjectiveModal={() => {}}
            ShowCompleteObjectiveModal={() => {}}
            expandedMode={true}
            fromSetUpAgent={true}
          />
        </div>
        <div
          style={{
            width: '30%',
            marginRight: '2rem',
            marginTop: '3rem',
            marginLeft: '1rem',
            borderLeft: '1px solid grey',
            borderRight: '1px solid grey',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '1.15rem',
              backgroundColor: '#13599B',
              color: 'white',
              opacity: '0.74',
              height: '3rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <span class="material-symbols-outlined">chat_bubble</span>
            <span> {showEng ? 'Your AI Consultant' : 'Su consultor de IA'} </span>
            <span>{showEng ? t('aiMemo.eng') : t('aiMemo.esp')}</span>
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
              onClick={() => {
                setShowEng((prev) => !prev)
              }}
            >
              {!showEng && (
                <span
                  style={{ fontSize: '35px', color: 'rgb(176, 173, 173)', cursor: 'pointer' }}
                  class="material-symbols-outlined"
                >
                  toggle_off
                </span>
              )}

              {showEng && (
                <span
                  style={{ fontSize: '35px', color: 'rgb(176, 173, 173)', cursor: 'pointer' }}
                  class="material-symbols-outlined"
                >
                  toggle_on
                </span>
              )}
            </div>
          </div>

          {!isSocketStarted && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
                marginLeft: '1.5rem',
              }}
            >
              <div
                onClick={() => setIsSocketStarted(true)}
                class="sleek-button sleek-full-blue"
                style={{
                  display: 'flex',
                  width: showEng ? '6rem' : '12rem',
                  fontSize: '1rem',
                  height: '2rem',
                  marginTop: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {showEng ? 'Click to Start' : 'Haga clic para comenzar'}
              </div>
            </div>
          )}

          <ChatBox messageList={messageList} isLoading={isLoading} />

          {isSocketStarted && ( // true ||
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
                marginLeft: '1.5rem',
              }}
            >
              {userState === 'speaking' && ( // true ||
                <SinusoidalWave width={300} height={100} speed={3} color="blue" />
              )}

              {justWaitingForUser && (
                <SmoothConcentricCircles size={100} color="blue" pulseSpeed={0.5} />
              )}
            </div>
          )}

          {isObjectiveMapFinished && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
                marginLeft: '1.5rem',
              }}
            >
              <div
                onClick={saveObjectives}
                class="sleek-button sleek-full-blue"
                style={{
                  display: 'flex',
                  width: '3rem',
                  fontSize: '1rem',
                  height: '2rem',
                  marginTop: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {t('commonButtons.finalize')}
              </div>
            </div>
          )}

          {isSocketStarted && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
                marginLeft: '1.5rem',
              }}
            >
              <div
                onClick={() => {
                  setIsAudioPaused((p) => !p)
                }}
                class="sleek-button sleek-full-blue"
                style={{
                  display: 'flex',
                  width: '3rem',
                  fontSize: '1rem',
                  height: '2rem',
                  marginTop: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {isAudioPaused ? (showEng ? 'Resume' : 'Reanudar') : showEng ? 'Pause' : 'Pausa'}
              </div>
            </div>
          )}
        </div>
      </div>
    </ApplicationLayout>
  )
}

const SinusoidalWave = ({ width = 300, height = 100, speed = 1, color = 'blue' }) => {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setOffset((prevOffset) => (prevOffset + speed) % width)
    }, 16) // ~60 fps for smooth animation

    return () => clearInterval(intervalId)
  }, [speed, width])

  const wavelength = width / 6
  const baseAmplitude = height / 4

  const points = Array.from({ length: width }, (_, x) => {
    const centerX = width / 2
    const distanceFromCenter = Math.abs(x - centerX)
    const amplitudeModifier = 1 - Math.pow(distanceFromCenter / (width / 2), 2)

    const amplitude = baseAmplitude * amplitudeModifier
    const y = Math.sin((x + offset) * ((2 * Math.PI) / wavelength)) * amplitude + height / 2

    return `${x},${y}`
  }).join(' ')

  // Generate a lighter version of the color for the gradient
  const lighterColor = (color) => {
    const hex = color.replace('#', '')
    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)
    // console.log(`rgb(${Math.min(255, r + 100)}, ${Math.min(255, g + 100)}, ${Math.min(255, b + 100)})`)
    // return `rgb(${Math.min(255, r + 100)}, ${Math.min(255, g + 100)}, ${Math.min(255, b + 100)})`;
    return `rgb(100,200,255)`
  }

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <linearGradient id="waveGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={lighterColor(color)} />
          <stop offset="50%" stopColor={color} />
          <stop offset="100%" stopColor={lighterColor(color)} />
        </linearGradient>
      </defs>
      <polyline points={points} fill="none" stroke="url(#waveGradient)" strokeWidth="3" />
    </svg>
  )
}

const SmoothConcentricCircles = ({ size = 100, color = 'blue', pulseSpeed = 1.5 }) => {
  const circles = [0, 1, 2] // We'll create 3 circles
  const animationDuration = 1 / pulseSpeed

  return (
    <svg width={size} height={size} viewBox="0 0 100 100">
      <style>
        {`
          @keyframes pulse {
            0% {
              r: 10;
              opacity: 0;
            }
            10% {
              opacity: 0.8;
            }
            100% {
              r: 50;
              opacity: 0;
            }
          }
        `}
      </style>
      {circles.map((_, index) => (
        <circle
          key={index}
          cx="50"
          cy="50"
          fill="none"
          stroke={color}
          strokeWidth="2"
          style={{
            animation: `pulse ${animationDuration}s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
            animationDelay: `${(index * animationDuration) / circles.length}s`,
          }}
        />
      ))}
    </svg>
  )
}

export default TeamLeaderSetupAiAgentSocket

import React from 'react'
import Modal from '../../../../../../../components/Modal'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../../../../../utils/routes'
import { redirectToHome } from '../../../../../../../utils/auth'

const MeiModal = ({
  isModalOpen,
  closeModal,
  data = {},
  fromTLDashboard = false,
  refreshMei = () => {},
  rawData = {},
  // showNoKpi = false in CxoMgmtScoreL2Beta
  showNoKpi = true,
}) => {
  const currentUser = localStorage.getItem('tfei')
  const showRawData = [1158].some((elem) => parseInt(currentUser) === elem)

  const { t } = useTranslation(['Common', 'Dashboard'])
  const {
    failedPreparedFigure = -1,
    failedCoordinatedFigure = -1,
    failedEnoughActionsClosedFigure = -1,
    failedClosingTimeAcceptableFigure = -1,
    kpiStatementType = 0,
    // default thresholds
    thresholds = {
      BenchmarkTopicsCounts: 1,
      BenchmarkCompletionPercentage: 0.4,
      BenchmarkCoordiatedAvg: 1,
    },
  } = data

  const navigate = useNavigate()

  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false)
  const [openActions, setOpenActions] = React.useState(null)
  const [isHelpModalOpen, setIsHelpModalOpen] = React.useState(false)

  const HelpElement = () => {
    return (
      <span
        style={{
          color: '#0926D5',
          textDecoration: 'underline',
        }}
      >
        <span
          style={{ cursor: 'pointer', fontSize: '18px' }}
          onClick={() => {
            setIsHelpModalOpen(true)
          }}
          onKeyDown={() => {
            setIsHelpModalOpen(true)
          }}
        >
          {t('managementExecIndex.seeMethodology')}.
        </span>
      </span>
    )
  }

  const statusColor = (data) => {
    if (data?.mei_status?.is_on_vacation) {
      return 'gray'
    } else if (data?.mei_status?.is_good) {
      return 'green'
    } else {
      return 'red'
    }
  }

  function divideByZeroHandling(x, y) {
    if (y === 0) {
      return 0
    }
    return x / y
  }

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        closeModal={() => {
          closeModal()
        }}
        width={'80%'}
        style={{ padding: '0rem 0rem 1rem 0rem', zIndex: '200' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {statusColor(data) === 'red' && (
            <div className="mei-modal-section-title mei-modal-section-col">
              <div className="mei-modal-section-row">
                <span> {t('mei.myMEIis')} </span>
                &nbsp;
                <span className="mei-modal-red-mei"> &nbsp; RED &nbsp; </span>
              </div>
              <div>{t('mei.toGetToGreenINeedTo')} </div>
            </div>
          )}

          {statusColor(data) === 'green' && (
            <div className="mei-modal-section-row mei-modal-section-title">
              <span>{t('mei.myMEIis')} </span>
              &nbsp;
              <span className="mei-modal-green-mei"> &nbsp; GREEN &nbsp;</span>
            </div>
          )}

          {statusColor(data) === 'gray' && (
            <div className="mei-modal-section-row mei-modal-section-title">
              <span>{t('mei.myMEIis')} </span>
              &nbsp;
              <span className="mei-modal-grey-mei"> &nbsp; GRAY &nbsp;</span>
            </div>
          )}

          <div
            className="mei-modal-section-col"
            style={{ marginLeft: '2rem', marginRight: '2rem' }}
          >
            {data?.to_be_green_mei?.num_more_topic_to_be_added > 0 && (
              <div className="mei-modal-section-row">
                <span className="mei-modal-common-text">{t('mei.add')} </span>
                &nbsp;
                <span className="mei-modal-highlight-number">
                  {data?.to_be_green_mei?.num_more_topic_to_be_added}
                </span>
                &nbsp;
                <span className="mei-modal-common-text">{t('mei.moreTopics')}</span>
                <span
                  className="mei-modal-common-text"
                  onClick={() => {
                    redirectToHome()
                    closeModal()
                  }}
                >
                  {' '}
                  &nbsp;
                  <span>{'('}</span>
                  <span className="mei-link">{t('mei.addTopics')}</span>
                  <span>{')'}</span>
                </span>
              </div>
            )}

            {data?.to_be_green_mei?.num_more_actions_to_be_added > 0 && (
              <div className="mei-modal-section-row">
                <span className="mei-modal-common-text">{t('mei.add')}</span>
                &nbsp;
                <span className="mei-modal-highlight-number">
                  {data?.to_be_green_mei?.num_more_actions_to_be_added}
                </span>
                &nbsp;
                <span className="mei-modal-common-text">{t('mei.moreNewActions')}</span>
                <span
                  className="mei-modal-common-text"
                  onClick={() => {
                    redirectToHome()
                    closeModal()
                  }}
                >
                  &nbsp;
                  <span>{'('}</span>
                  <span className="mei-link">{t('mei.seeOpenTopics')}</span>
                  <span>{')'}</span>
                </span>
              </div>
            )}

            {data?.to_be_green_mei?.num_more_actions_to_be_completed > 0 && (
              <div className="mei-modal-section-row">
                <span className="mei-modal-common-text">{t('mei.complete')}</span>
                &nbsp;
                <span className="mei-modal-highlight-number">
                  {data?.to_be_green_mei?.num_more_actions_to_be_completed}
                </span>
                &nbsp;
                <span className="mei-modal-common-text">{t('mei.moreActions')}</span>
                <span
                  className="mei-modal-common-text"
                  onClick={() => {
                    navigate(`/${routes.emailReminder}`)
                    closeModal()
                  }}
                >
                  {' '}
                  &nbsp;
                  <span>{'('}</span>
                  <span className="mei-link">{t('mei.seeMyOpenActions')}</span>
                  <span>{')'}</span>
                </span>
              </div>
            )}

            {data?.to_be_green_mei?.overdue_kpi_count > 0 && (
              <div className="mei-modal-section-row">
                <span className="mei-modal-common-text">{t('mei.update')}</span>
                &nbsp;
                <span className="mei-modal-highlight-number">
                  {data?.to_be_green_mei?.overdue_kpi_count}
                </span>
                &nbsp;
                <span className="mei-modal-common-text">{t('mei.kpis')}</span>
                <span
                  className="mei-modal-common-text"
                  onClick={() => {
                    navigate(`/${routes.update}`, {
                      state: { showOverdueKPIsPageOnly: true, isFromDashBoard: true },
                    })
                    closeModal()
                  }}
                >
                  {' '}
                  &nbsp;
                  <span>{'('}</span>
                  <span className="mei-link">{t('mei.clickHere')}</span>
                  <span>{')'}</span>
                </span>
              </div>
            )}
          </div>
          <br />
          <div className="divider"></div>
          {/* <br />
          <div className="mei-modal-section-col">
            <span className="mei-modal-section-title">{t('mei.my4WeekSummary')}</span>
            <div
              className="mei-modal-section-row"
              style={{ justifyContent: 'space-between', marginLeft: '2rem', marginRight: '2rem' }}
            >
              <div className="mei-modal-section-col" style={{ gap: '1rem' }}>
                <span className="mei-modal-highlight-number" style={{ alignSelf: 'center' }}>
                  {data?.rawData?.topicsAdded}
                </span>
                <span className="mei-modal-common-text">{t('mei.newTopics')}</span>
              </div>
              <div className="mei-modal-section-col" style={{ gap: '1rem' }}>
                <span className="mei-modal-highlight-number" style={{ alignSelf: 'center' }}>
                  {data?.rawData?.numActionsCreated}
                </span>
                <span className="mei-modal-common-text">{t('mei.newActions')}</span>
              </div>
              <div className="mei-modal-section-col" style={{ gap: '1rem' }}>
                <span className="mei-modal-highlight-number" style={{ alignSelf: 'center' }}>
                  {data?.rawData?.numActionsClosed}
                </span>
                <span className="mei-modal-common-text">{t('mei.completedActions')}</span>
              </div>
            </div>
          </div>
          <div style={{width:'100%',  textAlign:'left', marginTop:'1rem', wordBreak:'break-word'}}>
                <div style={{ fontSize: '12px', width: 'fit-content' }}>
                  {t('mei.clarification')}
                </div>
            </div>
          <br />
          <div className="divider"></div> */}
          <br />

          <div className="mei-modal-section-col" style={{ gap: '0.4rem' }}>
            <span className="mei-modal-section-title">{t('mei.myMEIBreakdown')}</span>
            <div className="mei-modal-section-row">
              <span className="mei-modal-breakdown-category">Prepared</span>
            </div>
            <div className="mei-modal-section-row" style={{ gap: '1rem' }}>
              {/* <span className="mei-modal-highlight-number" style={{ marginLeft: '1rem' }}>
                {parseFloat(failedPreparedFigure).toFixed(2)}
              </span> */}
              <span className="mei-modal-common-text" style={{ textAlign: 'left' }}>
                {t('Dashboard:managementExecIndex.helpIconTextPrepared', {
                  X: thresholds.BenchmarkTopicsCounts.toFixed(1),
                })}
              </span>
            </div>
            <div className="mei-modal-section-row">
              <span className="mei-modal-breakdown-category">Coordinated</span>
            </div>
            <div className="mei-modal-section-row" style={{ gap: '1rem' }}>
              {/* <span className="mei-modal-highlight-number" style={{ marginLeft: '1rem' }}>
                {parseFloat(failedCoordinatedFigure).toFixed(2)}
              </span> */}
              <span className="mei-modal-common-text" style={{ textAlign: 'left' }}>
                {t('Dashboard:managementExecIndex.helpIconTextCoordinated', {
                  X: thresholds.BenchmarkCoordiatedAvg.toFixed(1),
                })}
              </span>
            </div>

            <div className="mei-modal-section-row">
              <span className="mei-modal-breakdown-category">Task Master</span>
            </div>
            <div className="mei-modal-section-row" style={{ gap: '1rem' }}>
              {/* <span className="mei-modal-highlight-number" style={{ marginLeft: '1rem' }}>
                {parseFloat(failedEnoughActionsClosedFigure * 100).toFixed(0)}%
              </span> */}
              <span className="mei-modal-common-text" style={{ textAlign: 'left' }}>
                {t('Dashboard:managementExecIndex.helpIconTextTaskmaster', {
                  X: (thresholds.BenchmarkCompletionPercentage * 100).toFixed(1),
                })}
              </span>
            </div>

            <div className="mei-modal-section-row">
              <span className="mei-modal-breakdown-category">KPIs</span>
            </div>
            <div className="mei-modal-section-row" style={{ gap: '1rem' }}>
              {/* <span className="mei-modal-highlight-number" style={{ marginLeft: '1rem' }}>
                {parseFloat(failedEnoughActionsClosedFigure * 100).toFixed(0)}%
              </span> */}
              <span className="mei-modal-common-text" style={{ textAlign: 'left' }}>
                {t('Dashboard:managementExecIndex.helpIconTextKPIS')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default MeiModal

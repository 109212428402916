import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { employeeMatching } from '../../api/services/objectives.services'
import TextArea from 'rc-textarea'
import Select from 'react-select'
import InPageLoader from '../InPageLoader'

const MatchEmployeesModalWithObjectives = ({
  isModalOpen,
  closeModal,
  newEmployee,
  objectiveList,
  newEmployeeWithId,
  socket,
}) => {
  const { t } = useTranslation(['ObjectiveMap', 'commonButtons'])
  // const [newEmployeeWithIdInfo, setNewEmployeeWithIdInfo] = useState([])
  const [newEmployeeWithIdInfo, setNewEmployeeWithIdInfo] = useState(
    newEmployeeWithId?.map((employee) => {
      return {
        name: employee.name,
        id: employee.id,
        gpt_name: employee.name,
      }
    }),
  )
  const [employeeMatchingResult, setEmployeeMatchingResult] = useState()
  const [selectedEmployee, setSelectedEmployee] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isSavingObjectives, setIsSavingObjectives] = useState(false)
  const [newObjectives, setNewObjectives] = useState([])

  useEffect(() => {
    getEmployeeMathcing()
  }, [])

  const formatInputObjectives = () => {
    if (!objectiveList?.levelCards) return []

    const level3Cards = objectiveList.levelCards.filter((obj) => obj.level === 3)

    if (level3Cards.length === 0) return []

    const cardDisplayGroups = level3Cards[0].cardDisplayGroups

    if (cardDisplayGroups.length === 0) return []

    return cardDisplayGroups
      .map((group) =>
        group.cards.map((card) => ({
          description: card.goal,
          owner: card.owner,
        })),
      )
      .flat(Infinity)
  }

  // useEffect(() => {
  //   console.log('employeeMatchingResult', employeeMatchingResult)
  // }, [employeeMatchingResult])

  const handleNameChange = (index, employee, event) => {
    // console.log('event', event)
    // console.log('employeeL ', employee)
    // console.log('selectedEmployee: ', selectedEmployee)
    setSelectedEmployee({
      ...selectedEmployee,
      [event.value]: event,
    })
    const originalObjectives = [...newObjectives]
    let obj = originalObjectives[index]
    obj.realEId = event.value
    setNewObjectives(originalObjectives)
  }

  const saveObjectives = () => {
    if (socket) {
      console.log('emit save_picasso_objectives')
      setIsSavingObjectives(true)
      console.log('newObjectivesWithOwnerInfo: ', newObjectives)
      socket.emit('save_picasso_objectives', {
        newObjectivesWithOwnerInfo: newObjectives,
      })
    }
  }

  const getEmployeeMathcing = async () => {
    // console.log('newEmployeeWithIdInfo', newEmployeeWithIdInfo)
    const result = await employeeMatching(newEmployeeWithIdInfo?.map((employee) => employee.name))
    setEmployeeMatchingResult(result)
    let selectedEmployee = {}
    let newmapping = []
    for (const key in result) {
      selectedEmployee[result[key][0].eid] = {
        label: result[key][0].name,
        value: result[key][0].eid,
      }
      let employee = newEmployeeWithIdInfo.find((employee) => employee.name === key)
      if (employee) {
        let newEmployeeMapping = {
          ...employee,
          eid: result[key][0].eid,
          name: result[key][0].name,
        }
        newmapping.push(newEmployeeMapping)
      }
    }
    let objectives = formatInputObjectives()
    //   const updatedObjectives = objectives.map(objective => {
    //     return {
    //         ...objective,
    //         owner: newmapping.find(employee => employee.gpt_name === objective.owner)?.gpt_name
    //     };
    // });
    // console.log('newmapping: ', newmapping)
    const objectivesEidMap = objectives.map((objective) => {
      return {
        ...objective,
        realEId: newmapping.find((employee) => employee.gpt_name === objective.owner).eid,
        fakeOwner: objective.owner,
      }
    })

    // console.log('updatedObjectives: ', objectivesEidMap)
    setNewObjectives(objectivesEidMap)
    setNewEmployeeWithIdInfo(newmapping)
    setSelectedEmployee(selectedEmployee)
    setIsLoading(false)
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="70rem"
      modalContainerStyle={{ width: '93%' }}
      showCard={true}
      title={t('ObjectiveMap:modalTitle.comfirmEmailTitle')}
      titleStyle={{ color: 'rgb(91,132,203)' }}
      modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
      includeCloseIcon={false}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'left', marginTop: '0.5rem' }}>
          <span>{t('ObjectiveMap:setUpMessages.objectivesAndNameConfirmation')}</span>
        </div>
        <div className="review-generated-topics-list" style={{ marginTop: '1.3rem' }}>
          <div className="review-generated-objective-employee-list-row ">
            <div
              className="review-generated-topics-list-header"
              style={{ paddingLeft: '0.5rem', fontSize: '1.3rem' }}
            >
              {t('ObjectiveMap:setUpMessages.objectives')}
            </div>
            <div className="review-generated-topics-list-header" style={{ fontSize: '1.3rem' }}>
              {t('ObjectiveMap:setUpMessages.name')}
            </div>
          </div>
          {isLoading && <InPageLoader inComponent={true} />}

          {!isLoading &&
            newObjectives.map((obj, index) => {
              // let newObjective = newObjectives.find((obj) => obj.owner === employee)
              // if (!newObjective) return null
              // let employee = obj.owner
              // console.log('employeeMatchingResult[employee]: ', employeeMatchingResult[employee])
              // let objectiveByOwner = newObjectives.filter(obj => obj.owner === employee)
              let employee = obj.fakeOwner
              // console.log('obj: ', obj)
              // console.log('employeeMatchingResult[employee]: ', employeeMatchingResult[employee])
              return (
                <div key={index} className="review-generated-objective-employee-list-row ">
                  <div className="review-generated-topics-list-item">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {/* {objectiveByOwner.map((objective, index) => {
                      return (
                        <div key={index} style={{ marginTop: '1rem' }}>
                            {objective.description}
                        </div>
                    )})}   */}
                      {obj.description}
                    </div>
                  </div>
                  <div className="review-generated-topics-list-item">
                    {employeeMatchingResult && (
                      <Select
                        options={employeeMatchingResult[employee]?.map((employee) => {
                          return {
                            value: employee.eid,
                            label: employee.name,
                          }
                        })}
                        value={selectedEmployee[obj.realEId]}
                        onChange={(selectedOption) =>
                          handleNameChange(index, employee, selectedOption)
                        }
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '20rem',
                            fontSize: '1.3rem',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            position: 'absolute',
                            zIndex: 9999,
                            width: '20rem',
                            left: '0',
                            top: '100%',
                          }),
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                      />
                    )}
                  </div>
                  <div class="row-border" style={{ marginTop: '0.5rem' }}></div>
                </div>
              )
            })}
        </div>
        {!isLoading && (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
              <div
                onClick={saveObjectives}
                className="sleek-button sleek-full-blue"
                style={{ fontSize: '1rem', height: '2rem', cursor: 'pointer' }}
              >
                {isSavingObjectives && (
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '1rem',
                      width: '0.5rem',
                      height: '0.5rem',
                      margin: '0',
                      borderTopColor: 'white',
                    }}
                    className="loading-spinner"
                  ></span>
                )}
                {t('Common:commonButtons.next')}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default MatchEmployeesModalWithObjectives

// const url = "http://127.0.0.1:5000"

// export const socket = io(url, {
//     autoConnect: false,
// })

import { useEffect, useState, useRef } from 'react'
import { io } from 'socket.io-client'
import { getHttpRequest } from '../api/query/dynamicAPI'

const useSocket = (useCase, setToken) => {
  const [socket, setSocket] = useState(null)
  const retry = useRef(0)

  useEffect(() => {
    fetchSocketUrl()
    return () => {
      if (socket) {
        socket.disconnect()
      }
    }
  }, [])

  const fetchSocketUrl = async () => {
    if (retry.current > 1) {
      return
    }

    try {
      let response = await getHttpRequest('/audio_bot_socket_url')
      let url = response.url
      setToken(response.token)
      let socket = io(url, {
        autoConnect: false,
        query: {
          use_case: useCase, // Pass the useCase as a query parameter
        },
      })
      setSocket(socket)
    } catch (error) {
      console.log(error)
      retry.current += 1
      fetchSocketUrl()
    }
  }

  return socket
}

export default useSocket

import React, { useState, useEffect } from 'react'
import './customNode.scss'

function AddLevel1ObjButton({ data, isConnectable }) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <div
          className="fitted-button blue tooltip"
          onClick={() => {
            data.addLevel1ObjButtonHandler()
          }}
        >
          <span className="material-symbols-outlined">add</span>
        </div>
      </div>
    </>
  )
}

export default AddLevel1ObjButton

import React, { useEffect, useState } from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import { getListOfObjectivesFlow, getPicaasoProcess } from '../../api/services/objectives.services'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import useDeleteObjective_v2 from '../../api/mutation/deleteObjectiveHome_v2'
import Toast from '../../common/toast'
import useEditObjectiveComplete_v2 from '../../api/mutation/editObjectiveCompleteHome_v2'
import { getEmailFromName } from '../../utils/general'
import ChatBox from './Chatbox'
import SendMessageModal from '../../components/ConfirmEmailModal/SendMessageModal'
import { useNavigate } from 'react-router-dom'
import MatchEmployeesModal from '../../components/MatchEmployeesModal'
import useSocket from '../../common/useSocket'
import './styles.css'
import useAudioAgentPlugin from '../../components/AudioAgentPlugin'
import ObjectiveMapSetupAgent from '../objectiveFlow/SetupAgentMode'
import { setEmployeeWizardStatus, finishCompanySetUp } from '../../api/services/employee.services'
import { routes } from '../../utils/routes'
import { getConsolidatedConfigSettings } from '../../utils/auth'
import MatchEmployeesModalWithObjectives from '../../components/MatchEmployeesModalWithObjectives'

const CompanySetupAiAgentSocketWithPlugin = () => {
  const [objectiveList, setObjectiveList] = useState([])
  const [objId, setObjId] = useState(null)
  const [singleObjId, setSingleObjId] = useState(null)
  const [showObjMap, setShowObjMap] = useState(true)

  const { t } = useTranslation(['Common', 'ObjectiveMap'])

  const [newEmployee, setNewEmployee] = useState([])
  const [isObjectiveMapFinished, setIsObjectiveMapFinished] = useState(false)
  const [isConfirmEmailModalOpen, setIsConfirmEmailModalOpen] = useState(false)
  const [isSendEmialModalOpen, setIsSendEmailModalOpen] = useState(false)
  const [newEmployeeWithId, setNewEmployeeWithId] = useState([])
  const [degradedCanvas, setDegradedCanvas] = useState(null)
  const [isSetUpCompleted, setIsSetUpCompleted] = React.useState(false)
  const [loadingObjectiveMap, setLoadingObjectiveMap] = useState(false)
  const isFirstValidL1ObjGeneration = React.useRef(null)
  // const enableRecommendations = getConsolidatedConfigSettings('enable_recommendations_in_setup_agent') === true
  const [token, setToken] = useState(null)

  const socket = useSocket('al_agent', setToken)
  const navigate = useNavigate()
  const isDemo = localStorage.getItem('is_demo') === 'true'

  const onFinalJson = (data) => {
    if (data) {
      setLoadingObjectiveMap(false)
      // console.log('data.json_str =', data.json_str)
      let response = data
      let employeeList = []
      if (response.canvasResponse) {
        setObjectiveList(response.canvasResponse)
        let degradedCanvas = degradeCanvas(JSON.parse(JSON.stringify(response.canvasResponse)))

        // Signals that we have some data now for L1.
        if (degradedCanvas) {
          if (isFirstValidL1ObjGeneration.current === null) {
            isFirstValidL1ObjGeneration.current = true
            audioAgentPlugin.updateSharedState({ isFirstValidL1ObjGeneration: true })
          } else {
            isFirstValidL1ObjGeneration.current = false
            audioAgentPlugin.updateSharedState({ isFirstValidL1ObjGeneration: false })
          }
        }

        setDegradedCanvas(degradedCanvas)

        if (!isObjectiveMapFinished && response.directReportList.length > 0) {
          console.log('response.directReportList', response.directReportList)
          setNewEmployee(response.directReportList)
          let level3_cards = response.canvasResponse.levelCards.filter(
            (card) => card.level === 3,
          )[0]
          if (level3_cards?.level) {
            for (let group of level3_cards.cardDisplayGroups) {
              for (let card of group.cards) {
                if (employeeList.some((e) => e.name === card.owner)) {
                  continue
                } else {
                  employeeList.push({ name: card.owner, id: card.ownerId })
                }
              }
            }
          }
          console.log('employeeList', employeeList)
          setNewEmployeeWithId(employeeList)
        }
      }
      setIsObjectiveMapFinished(response.finishObjectiveHierachy)
      if (response.finishObjectiveHierachy) {
        audioAgentPlugin.updateSharedState({ isObjectiveMapFinished: true })
      }
    }
  }

  const reloadObjectiveMap = (value) => {
    setLoadingObjectiveMap(value)
  }

  const closeModal = () => {
    setIsConfirmEmailModalOpen(false)
  }

  const saveObjectivesCallback = async () => {
    // let result = await setEmployeeWizardStatus(true)
    // let res = await finishCompanySetUp('area')
    // console.log(result)
    closeModal()
    navigate(`/${routes.meetingMultiView}`, {
      state: { fromSetUpAgent: true },
    })
    navigate(0)
    // setIsSendEmailModalOpen(true)
  }

  const audioAgentPlugin = useAudioAgentPlugin({
    socket: socket,
    speechEndCallback: () => {},
    onFinalJsonCallback: onFinalJson,
    reload: reloadObjectiveMap,
    socketStartedCallback: () => {},
    saveObjectivesCallback: saveObjectivesCallback,
    use_case: 'al_agent',
    token: token,
    undoCallback: () => {
      setShowObjMap(false)
      const timeout = setTimeout(() => setShowObjMap(true), 1000)
    },
  })

  const degradeCanvas = (canvas) => {
    let level_cards = canvas.levelCards
    let newLevelCards = level_cards
      .filter((level_card) => level_card.level !== 1)
      .map((level_card) => {
        if (level_card.level === 2 || level_card.level === 3) {
          level_card.level -= 1
          return level_card
        }
      })
    canvas.levelCards = newLevelCards
    return canvas
  }

  const saveObjectives = async () => {
    audioAgentPlugin.setIsAudioPaused(true)
    let newEmplooyeeInfo = newEmployee?.map((employee) => {
      return {
        name: employee,
        email: getEmailFromName(employee),
      }
    })
    let newEmployeeWithIdInfo = newEmployeeWithId?.map((employee) => {
      return {
        name: employee.name,
        email: getEmailFromName(employee.name),
        id: employee.id,
      }
    })
    // const response = await savePicassoObjectives(objectiveList.levelCards, newEmplooyeeInfo, newEmployeeWithIdInfo)
    // navigate(`/${routes.meetingMultiView}`,{
    //     state: {fromSetUpAgent: true}
    // })
    // navigate(0)
    setIsConfirmEmailModalOpen(true)
  }

  return (
    <ApplicationLayout fromSetUpAgent={true}>
      {isConfirmEmailModalOpen && isDemo && (
        <MatchEmployeesModal
          isModalOpen={isConfirmEmailModalOpen}
          closeModal={() => {
            setIsConfirmEmailModalOpen(false)
          }}
          newEmployee={newEmployee}
          objectiveList={objectiveList}
          setIsSendEmailModalOpen={setIsSendEmailModalOpen}
          newEmployeeWithId={newEmployeeWithId}
          setIsSetUpCompleted={setIsSetUpCompleted}
          updateSharedCache={audioAgentPlugin.updateSharedState}
          fromSocket={true}
          socket={socket}
        />
      )}
      {isConfirmEmailModalOpen && !isDemo && (
        <MatchEmployeesModalWithObjectives
          isModalOpen={isConfirmEmailModalOpen}
          closeModal={() => {
            setIsConfirmEmailModalOpen(false)
          }}
          newEmployee={newEmployee}
          objectiveList={objectiveList}
          setIsSendEmailModalOpen={setIsSendEmailModalOpen}
          newEmployeeWithId={newEmployeeWithId}
          setIsSetUpCompleted={setIsSetUpCompleted}
          updateSharedCache={audioAgentPlugin.updateSharedState}
          fromSocket={true}
          socket={socket}
        />
      )}
      {isSendEmialModalOpen && (
        <SendMessageModal
          isModalOpen={isSendEmialModalOpen}
          closeModal={() => {
            setIsSendEmailModalOpen(false)
          }}
        />
      )}
      <div style={{ display: 'block', marginTop: '3rem', display: 'flex', flexDirection: 'row' }}>
        {audioAgentPlugin.audioPlayer}

        <div style={{ width: '65%', overflow: 'hidden', marginTop: '3rem' }}>
          {loadingObjectiveMap && <div className="obj_map_loader" />}
          {showObjMap && (
            <ObjectiveMapSetupAgent objectiveList={degradedCanvas} expandedMode={true} />
          )}
        </div>
        <div
          style={{
            width: '30%',
            marginRight: '2rem',
            marginTop: '3rem',
            marginLeft: '1rem',
            borderLeft: '1px solid grey',
            borderRight: '1px solid grey',
            borderRadius: '10px 10px 0 0',
            height: '100vh',
          }}
        >
          {/* {enableRecommendations && audioAgentPlugin.checkboxRec} */}
          {audioAgentPlugin.chatBox}

          {isObjectiveMapFinished && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '1.5rem',
              }}
            >
              <div
                onClick={saveObjectives}
                class="sleek-button sleek-full-blue"
                style={{
                  display: 'flex',
                  width: '3rem',
                  fontSize: '1rem',
                  height: '2rem',
                  marginTop: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {t('commonButtons.finalize')}
              </div>
            </div>
          )}
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default CompanySetupAiAgentSocketWithPlugin

import React, { useEffect, useState } from 'react'
import Header from './Header'
import ApplicationLayout from '../ApplicationLayout'
import { useLocation } from 'react-router-dom'
import { getConsolidatedConfigSettings, hasRole } from '../../utils/auth'
import { useTranslation } from 'react-i18next'
//import objectiveSample from '../../assets/objective_map_sample.png'
import objectiveSample from '../../assets/obj_map_sample.svg'

const CompanySetupIntroScreen = () => {
  const [singleIntro, setSingleIntro] = useState(true)
  const location = useLocation()
  const { state } = location
  const { t } = useTranslation(['Common'])
  const proxy_tl_mode = getConsolidatedConfigSettings('proxy_tl_mode') === true

  useEffect(() => {
    if (state) {
      const { teamLeaderIntro } = state
      console.log('teamLeaderIntro', teamLeaderIntro)
      setSingleIntro(teamLeaderIntro)
    }
  }, [state])
  const [pageNumber, setPageNumber] = useState(0)
  const [language, setLanguage] = useState('en')
  const text = {
    en: {
      welcome: 'Welcome!',
    },
    es: {
      welcome: '¡Bienvenido!',
    },
  }

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value)
  }

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1)
  }

  const goToPreviousPage = () => {
    if (pageNumber === 0) {
      return
    }
    setPageNumber(pageNumber - 1)
  }

  const startWizard = () => {
    if (hasRole('team')) {
      window.location.href = '/TeamSetupConvoWithPlugin'
    } else if (hasRole('cxo')) {
      window.location.href = '/SetupConvoWithPlugin'
    } else if (hasRole('area')) {
      if (proxy_tl_mode) {
        window.location.href = '/TeamSetupConvoWithPlugin'
      } else {
        window.location.href = '/SetupConvoWithPlugin'
      }
    }
  }

  const FormattedText = ({ textKey }) => {
    const parts = t(textKey).split(/(<b>.*?<\/b>)/g)
    return (
      <>
        {parts.map((part, index) => {
          if (part.startsWith('<b>') && part.endsWith('</b>')) {
            return <strong key={index}>{part.slice(3, -4)}</strong>
          }
          return <React.Fragment key={index}>{part}</React.Fragment>
        })}
      </>
    )
  }

  const getIntroScreen = (pageNumber) => {
    if (singleIntro) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: '0 10rem',
            gap: '1rem',
          }}
        >
          <div style={{ fontSize: '20px', color: '#5582cb' }}>
            {t('companySetupSingleIntro.welcome')}
          </div>
          <div style={{ fontSize: '16px', color: 'black', textAlign: 'left' }}>
            <FormattedText textKey="companySetupSingleIntro.topText" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', justifyItems: 'flex-start', width: '100%' }}>
              <img
                className="objective_map_sample"
                src={objectiveSample}
                alt="TransforML Logo"
                style={{
                  width: '70%',
                  height: 'auto',
                }}
              />
            </div>

            <div style={{ fontSize: '16px', textAlign: 'left' }}>
              <div style={{ marginTop: '3rem' }}>
                <FormattedText textKey="companySetupSingleIntro.howTo" />
                <ol>
                  <li>
                    {' '}
                    <FormattedText textKey="companySetupSingleIntro.point1" />
                  </li>
                  <li>
                    {' '}
                    <FormattedText textKey="companySetupSingleIntro.point2" />
                  </li>
                  <li>
                    {' '}
                    <FormattedText textKey="companySetupSingleIntro.point3" />
                  </li>
                  <li>
                    {' '}
                    <FormattedText textKey="companySetupSingleIntro.point4" />
                  </li>
                </ol>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div>
                  <div
                    onClick={startWizard}
                    className="sleek-button sleek-full-orange"
                    style={{
                      fontSize: '1rem',
                      height: '2rem',
                      cursor: 'pointer',
                      marginTop: '2rem',
                    }}
                  >
                    {t('companySetupSingleIntro.getStarted')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (pageNumber === 0) {
      return (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5rem' }}
        >
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold', color: '#5A7BD5', fontSize: '2.5rem' }}>
              English
            </span>
            <input
              type="radio"
              name="language"
              value="en"
              checked={language === 'en'}
              onChange={handleLanguageChange}
            />
          </label>
          <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold', color: '#5A7BD5', fontSize: '2.5rem' }}>
              Español
            </span>
            <input
              type="radio"
              name="language"
              value="es"
              checked={language === 'es'}
              onChange={handleLanguageChange}
            />
          </label>
        </div>
      )
    } else if (pageNumber === 1) {
      return (
        <div>
          <h1>{text[language]['welcome']}</h1>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
        {!singleIntro && (
          <div
            style={{
              width: '4rem',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={goToPreviousPage}
          >
            <span style={{ fontSize: '2rem', fontWeight: 'bold', marginTop: '15rem' }}>{'<'}</span>
          </div>
        )}

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: '1rem',
          }}
        >
          {getIntroScreen(pageNumber)}
        </div>

        {!singleIntro && (
          <div
            style={{
              width: '4rem',
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={goToNextPage}
          >
            <span style={{ fontSize: '2rem', fontWeight: 'bold', marginTop: '15rem' }}>{'>'}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default CompanySetupIntroScreen

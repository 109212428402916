import Modal from '../Modal'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { getEmailFromName } from '../../utils/general'
import { employeeMatching, savePicassoObjectives } from '../../api/services/objectives.services'
import TextArea from 'rc-textarea'
import Select from 'react-select'
import { set } from 'lodash'
import { lab } from 'chroma-js'
import { finishCompanySetUp, setEmployeeWizardStatus } from '../../api/services/employee.services'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/routes'

const MatchEmployeesModal = ({
  isModalOpen,
  closeModal,
  newEmployee,
  objectiveList,
  setIsSendEmailModalOpen,
  newEmployeeWithId,
  updateSharedCache = () => {},
  fromSocket = false,
  socket,
}) => {
  console.log('newEmployee', newEmployee)
  const navigate = useNavigate()
  const { t } = useTranslation(['ObjectiveMap', 'commonButtons'])
  const [newEmployeeInfo, setNewEmployeeInfo] = useState(
    newEmployee?.map((employee) => {
      return {
        name: employee,
        email: '',
      }
    }),
  )
  const [newEmployeeWithIdInfo, setNewEmployeeWithIdInfo] = useState(
    newEmployeeWithId?.map((employee) => {
      return {
        name: employee.name,
        id: employee.id,
        gpt_name: employee.name,
      }
    }),
  )
  const [employeeMatchingResult, setEmployeeMatchingResult] = useState()
  const [selectedEmployee, setSelectedEmployee] = useState()

  useEffect(() => {
    getEmployeeMathcing()
  }, [])

  useEffect(() => {}, [employeeMatchingResult])

  const handleNameChange = (index, employee, event) => {
    // const newEmployees = [...newEmployeeInfo];
    // newEmployees[index].name = event.target.value;
    // setNewEmployeeInfo(newEmployees);
    // if (newEmployeeWithId.length > 0) {
    //     const newEmployeesWithId = [...newEmployeeWithIdInfo];
    //     newEmployeesWithId[index].name = event.target.value;
    //     setNewEmployeeWithIdInfo(newEmployeesWithId);
    // }
    console.log('index', index)
    console.log('employee', employee)
    console.log('event', event)
    setSelectedEmployee({
      ...selectedEmployee,
      [employee]: event,
    })
    const newEmployees = [...newEmployeeInfo]
    newEmployees[index].name = event.label
    setNewEmployeeInfo(newEmployees)
    if (newEmployeeWithId.length > 0) {
      const newEmployeesWithId = [...newEmployeeWithIdInfo]
      newEmployeesWithId[index].name = event?.label
      newEmployeesWithId[index].eid = event?.value
      newEmployeesWithId[index].gpt_name = newEmployee[index]
      setNewEmployeeWithIdInfo(newEmployeesWithId)
    }
  }

  useEffect(() => {
    console.log('newEmployeeWithIdInfo', newEmployeeWithIdInfo)
  }, [newEmployeeWithIdInfo, newEmployeeInfo])

  const handleEmailChange = (index, event) => {
    const newEmployees = [...newEmployeeInfo]
    newEmployees[index].email = event.target.value
    setNewEmployeeInfo(newEmployees)
    if (newEmployeeWithId.length > 0) {
      const newEmployeesWithId = [...newEmployeeWithIdInfo]
      newEmployeesWithId[index].email = event.target.value
      setNewEmployeeWithIdInfo(newEmployeesWithId)
    }
  }

  // const saveObjectives = async () => {
  //   // const response = await savePicassoObjectives(
  //   //   objectiveList.levelCards,
  //   //   newEmployeeInfo,
  //   //   newEmployeeWithIdInfo,
  //   //   true,
  //   // )
  //   let result = await setEmployeeWizardStatus(true)
  //   let res = await finishCompanySetUp('area')
  //   console.log(result)
  //   closeModal()
  //   navigate(`/${routes.meetingMultiView}`, {
  //     state: { fromSetUpAgent: true },
  //   })
  //   navigate(0)
  //   // setIsSendEmailModalOpen(true)
  // }

  const saveObjectives = () => {
    if (socket) {
      console.log('emit save_picasso_objectives')
      socket.emit('save_picasso_objectives', {
        newEmployeeWithIdInfo: newEmployeeWithIdInfo,
      })
      navigate(`/${routes.meetingMultiView}`, {
        state: { fromSetUpAgent: true },
      })
      navigate(0)
    }
  }

  const getEmployeeMathcing = async () => {
    console.log('newEmployeeWithIdInfo', newEmployeeWithIdInfo)
    const result = await employeeMatching(newEmployeeWithIdInfo?.map((employee) => employee.name))
    setEmployeeMatchingResult(result)
    let selectedEmployee = {}
    let newmapping = []
    for (const key in result) {
      selectedEmployee[key] = { label: result[key][0].name, value: result[key][0].eid }
      let employee = newEmployeeWithIdInfo.find((employee) => employee.name === key)
      if (employee) {
        let newEmployeeMapping = {
          ...employee,
          eid: result[key][0].eid,
          name: result[key][0].name,
        }
        newmapping.push(newEmployeeMapping)
      }
    }
    setNewEmployeeWithIdInfo(newmapping)
    setSelectedEmployee(selectedEmployee)
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      width="70rem"
      modalContainerStyle={{ width: '93%' }}
      showCard={true}
      title={t('ObjectiveMap:modalTitle.comfirmEmailTitle')}
      titleStyle={{ color: 'rgb(91,132,203)' }}
      modalTitleContainerStyle={{ marginBottom: '0rem', justifyContent: 'flex-start' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'left', marginTop: '2rem' }}>
          <span style={{ fontSize: '1.7rem' }}>
            {t('ObjectiveMap:setUpMessages.emailAndNameConfirmation')}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '20rem',
            marginTop: '1rem',
          }}
        >
          <div style={{ flexDirection: 'column' }}>
            <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              {t('ObjectiveMap:setUpMessages.name')}
            </div>
            {newEmployee?.map((employee, index) => (
              <div key={index} style={{ marginTop: '1rem' }}>
                {/* <input
                                    type="text"
                                    defaultValue={employee}
                                    onChange={(e) => handleNameChange(index, e)}
                                /> */}
                <div className="review-generated-topics-list-item">
                  {employeeMatchingResult && (
                    <Select
                      options={employeeMatchingResult[employee]?.map((employee) => {
                        return {
                          value: employee.eid,
                          label: employee.name,
                        }
                      })}
                      value={selectedEmployee[employee]}
                      onChange={(selectedOption) =>
                        handleNameChange(index, employee, selectedOption)
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '20rem',
                          textAlign: 'center',
                          fontSize: '1.3rem',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          position: 'absolute',
                          zIndex: 9999,
                          width: '20rem',
                          left: '0',
                          top: '100%',
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuPosition="absolute"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div style={{ flexDirection: 'column' }}>
            <div style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>
              {t('ObjectiveMap:setUpMessages.email')}
            </div>
            {newEmployee?.map((employee, index) => (
              <div key={index} style={{ marginTop: '1rem' }}>
                {/* <input
                                    type="text"
                                    defaultValue={getEmailFromName(employee)}
                                    onChange={(e) => handleEmailChange(index, e)}
                                /> */}
                <div className="review-generated-topics-list-item">
                  {employeeMatchingResult && selectedEmployee && (
                    <TextArea
                      type="text"
                      onChange={(e) => {
                        handleEmailChange(index, e)
                      }}
                      value={
                        employeeMatchingResult[employee]?.find(
                          (emp) => emp.eid === Number(selectedEmployee?.[employee]?.value),
                        )?.email
                      }
                      style={{
                        width: '20rem',
                        height: '1rem',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1.3rem',
                      }}
                      editable={false}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <div
            onClick={saveObjectives}
            className="sleek-button sleek-full-blue"
            style={{ fontSize: '1rem', height: '2rem', cursor: 'pointer' }}
          >
            {t('Common:commonButtons.next')}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MatchEmployeesModal

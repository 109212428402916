import { useState, React, useEffect } from 'react'
import Modal from '../Modal'
import { colors } from '../../utils/colors'
import InPageLoader from '../InPageLoader'
// import AudioRecorder from '../AudioRecorder'
import { useTranslation } from 'react-i18next'
import useAddMultipleMeetingTopicsUsingAudio from '../../api/mutation/addMultipleItemsUsingAudio'
import Toast from '../../common/toast'
import AudioRecorderV2 from '../AudioRecorderV2'
import { addDotAfterSentence } from '../../utils/general'
import AudioRecorderV3 from '../AudioRecorderV3'

const AudioToTextButtonV2 = ({
  startRecording,
  setAudioText = () => {},
  displayText = '',
  answer = '',
  setIsRecording = () => {},
  fromSetUpAgent = false,
  setCancelVoice = () => {},
}) => {
  const { t } = useTranslation(['Common'])

  const [isLoading, setIsLoading] = useState(false)
  const [displayError, setDisplayError] = useState(false)
  const [isStartting, setIsStarting] = useState(false)
  const [userInputEnd, setUserInputEnd] = useState(false)

  const mutationAddMultipleMeetingTopicsUsingAudio = useAddMultipleMeetingTopicsUsingAudio()

  const generateItems = async (audio) => {
    setIsLoading(true)
    try {
      await mutationAddMultipleMeetingTopicsUsingAudio.mutateAsync(
        { audioFile: audio, callType: 'text' },
        {
          onSuccess: (response) => {
            doSuccessSteps(response)
          },
          onError: (e) => {
            doFailureSteps()
          },
        },
      )
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongPleaseTryAgain'),
      })
    }
  }

  const doSuccessSteps = (response) => {
    setAudioText((answer != null ? answer : '') + addDotAfterSentence(answer) + response ?? '')
    setIsLoading(false)
  }

  const doFailureSteps = () => {
    setDisplayError(true)
    setIsLoading(false)
  }

  useEffect(() => {}, [userInputEnd])

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            wordBreak: 'break-word',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AudioRecorderV3
            handleSubmit={generateItems}
            isNewDesign={false}
            start={startRecording}
            displayText={displayText}
            setIsStarting={setIsStarting}
            userInputEnd={userInputEnd}
            setUserInputEnd={setUserInputEnd}
            fromSetUpAgent={true}
            setIsRecording={setIsRecording}
            setCancelVoice={setCancelVoice}
          />
          {displayError && !fromSetUpAgent && (
            <span style={{ color: 'red' }}>
              {t('Common:modalMessages.somethingWentWrongPleaseTryAgain')}
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default AudioToTextButtonV2

import { useEffect, useState } from 'react'
import ApplicationLayout from '../../components/ApplicationLayout'
import ObjectiveMapByObjective from '../objectiveFlow/objectiveMode'
import {
  getListOfObjectivesFlow,
  getPicaasoProcess,
  savePicassoObjectives,
} from '../../api/services/objectives.services'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import useDeleteObjective_v2 from '../../api/mutation/deleteObjectiveHome_v2'
import Toast from '../../common/toast'
import useEditObjectiveComplete_v2 from '../../api/mutation/editObjectiveCompleteHome_v2'
import TextArea from 'rc-textarea'
import { getEmailFromName } from '../../utils/general'
import ChatBox from './Chatbox'
import AudioToTextButtonV2 from '../../components/AudioToTextButtonV2'
import ConfirmEmailModal from '../../components/ConfirmEmailModal'
import SendMessageModal from '../../components/ConfirmEmailModal/SendMessageModal'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../utils/routes'
import MatchEmployeesModal from '../../components/MatchEmployeesModal'
import { useRef } from 'react'
import { set } from 'lodash'
import { formatTime } from '../../utils/time'

const CompanySetupAiAgent = () => {
  const [objectiveList, setObjectiveList] = useState([])
  const [addedObj, setAddedObj] = useState(0)
  const [lastClickedLevel, setLastClickedLevel] = useState(0)
  const [editedObj, setEditedObj] = useState(null)
  const [objData, setObjData] = useState({})
  const [activitiesModalMode, setActivitiesModalMode] = useState('')
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false)
  const [isRequestUpdateModalOpen, setIsRequestModalOpen] = useState(false)
  const [singleObjId, setSingleObjId] = useState(null)
  const [isKPIModalOpen, setIsKPIModalOpen] = useState(false)
  const [isViewFilesModalOpen, setIsViewFilesModalOpen] = useState(false)
  const [openOnSpecificKpi, setOpenOnSpecificKpi] = useState(false)
  const mutationEditObjectiveComplete_v2 = useEditObjectiveComplete_v2()

  const [answer, setAnswer] = useState('')
  const [audioText, setAudioText] = useState('')
  const { t } = useTranslation(['Common', 'ObjectiveMap'])
  const [messageList, setMessageList] = useState([])
  const [firstRequest, setFirstRequest] = useState(true)
  const [messageId, setMessageId] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [logId, setLogId] = useState(0)
  const [nextQuestion, setNextQuestion] = useState(null)
  const [inputMode, setInputMode] = useState('type')
  const [newEmployee, setNewEmployee] = useState([])
  const [isRecording, setIsRecording] = useState(false)
  const [isObjectiveMapFinished, setIsObjectiveMapFinished] = useState(false)
  const [isConfirmEmailModalOpen, setIsConfirmEmailModalOpen] = useState(false)
  const [isSendEmialModalOpen, setIsSendEmailModalOpen] = useState(false)
  const [newEmployeeWithId, setNewEmployeeWithId] = useState([])
  const [degradedCanvas, setDegradedCanvas] = useState(null)
  const [emailInfo, setEmailInfo] = useState([])
  const navigate = useNavigate()
  const [seconds, setSeconds] = useState(0)
  const secondsRef = useRef(0)
  const [cancelVoice, setCancelVoice] = useState(false)

  useEffect(() => {
    getObjList()
  }, [addedObj])

  useEffect(() => {
    // setAnswer(prev => prev + addDotAfterSentence(prev) + audioText)
    console.log('cancelVoice', cancelVoice)
    if (audioText !== '' && !cancelVoice) {
      sendMessages(audioText)
    }
    setCancelVoice(false)
  }, [audioText])

  useEffect(() => {
    if (firstRequest) {
      sendMessages()
    }
  }, [])

  useEffect(() => {
    if (isRecording) {
      const interval = setInterval(() => {
        if (secondsRef.current < 60) {
          setSeconds((prevSeconds) => {
            secondsRef.current = prevSeconds + 1
            return secondsRef.current
          })
        } else {
          clearInterval(interval)
        }
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    } else {
      setSeconds(0)
      secondsRef.current = 0
    }
  }, [isRecording])

  async function getObjList() {
    const result = await getListOfObjectivesFlow('', '')
    // setObjectiveList(result)
  }

  const loadAgain = () => {
    setAddedObj((prev) => prev + 1)
  }

  const ShowDeleteObjectiveModal = async (id, level, numChildren) => {
    if (level === 2 && numChildren > 0) {
      const hasChildrenConfirm = await Swal.fire({
        title: t('ObjectiveMap:confirmationMsgs.objectiveHasChildrenDeletion'),
        icon: 'info',
        confirmButtonText: t('Common:commonButtons.ok'),
      })
      return
    }
    const resultConfirm = await Swal.fire({
      title: t('ObjectiveMap:confirmationMsgs.confirmDeleteObjective'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (resultConfirm.isConfirmed) {
      deleteObj(id, id)
    }
  }

  const deleteObj = async (objId, originalId, lastCopy = false) => {
    let postObj = { objId: objId, lastCopy: lastCopy, originalId: originalId }
    await useDeleteObjective_v2.mutateAsync(postObj, {
      onSuccess: () => {
        loadAgain()
      },
      onError: () => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  const ShowCompleteObjectiveModal = async (id, level, numChildren) => {
    if (level === 2 && numChildren > 0) {
      const hasChildrenConfirm = await Swal.fire({
        title: t('ObjectiveMap:confirmationMsgs.objectiveHasChildrenCompletion'),
        icon: 'info',
        confirmButtonText: t('Common:commonButtons.ok'),
      })
      return
    }
    const resultConfirm = await Swal.fire({
      title: t('ObjectiveMap:confirmationMsgs.confirmCompleteObjective'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (resultConfirm.isConfirmed) {
      completeObjective(id, id)
    }
  }

  const completeObjective = async (objId, originalId) => {
    let postObj = { objId: objId, originalId: originalId }
    await mutationEditObjectiveComplete_v2.mutateAsync(postObj, {
      onSuccess: (response) => {
        loadAgain()
      },
      onError: (response) => {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      },
    })
  }

  const handleChangeAnswer = (e) => {
    setAnswer(e.target.value)
  }

  const degradeCanvas = (canvas) => {
    let level_cards = canvas.levelCards
    let newLevelCards = level_cards
      .filter((level_card) => level_card.level !== 1)
      .map((level_card) => {
        if (level_card.level === 2 || level_card.level === 3) {
          level_card.level -= 1
          return level_card
        }
      })
    canvas.levelCards = newLevelCards
    return canvas
  }

  const sendMessages = async (audioText = null) => {
    let employeeList = []
    setIsLoading(true)
    let answerText = audioText ? audioText : answer
    setAnswer('')
    setMessageList([...messageList, { user_message: answerText }])
    const response = await getPicaasoProcess(
      answerText,
      firstRequest,
      nextQuestion,
      messageId,
      logId,
      isObjectiveMapFinished,
    )
    if (response) {
      console.log('response', response)
      let systemMessage = response.systemMessage.replaceAll('\\n', '\n')
      console.log('System Message', systemMessage)
      let gptResponse = response.gptResponse.replaceAll('\\n', '\n')
      console.log('GPT Response', gptResponse)

      setObjectiveList(response.canvasResponse)
      let degradedCanvas = degradeCanvas(JSON.parse(JSON.stringify(response.canvasResponse)))
      setDegradedCanvas(degradedCanvas)
      setMessageId(response.messageId)
      setLogId(response.logId)
      let parsedMessage = JSON.parse(response.message)
      setMessageList(parsedMessage)
      setNextQuestion(response.nextQuestionForUser)
      //setNewEmployee(response.newEmployee)
      if (!isObjectiveMapFinished) {
        setNewEmployee(response.directReportList)
        let level3_cards = response.canvasResponse.levelCards.filter((card) => card.level === 3)[0]
        if (level3_cards?.level) {
          for (let group of level3_cards.cardDisplayGroups) {
            for (let card of group.cards) {
              if (employeeList.some((e) => e.name === card.owner)) {
                continue
              } else {
                employeeList.push({ name: card.owner, id: card.ownerId })
              }
            }
          }
        }
        setNewEmployeeWithId(employeeList)
      }
      setIsObjectiveMapFinished(response.finishObjectiveHierachy)
    } else {
      setMessageList((prevMessageList) => [
        ...prevMessageList,
        { assistant_message: t('Common:companyAiSetupAgent.noResponse') },
      ])
    }
    if (firstRequest) {
      setFirstRequest(false)
    }
    setIsLoading(false)
  }

  const saveObjectives = async () => {
    let newEmplooyeeInfo = newEmployee?.map((employee) => {
      return {
        name: employee,
        email: getEmailFromName(employee),
      }
    })
    let newEmployeeWithIdInfo = newEmployeeWithId?.map((employee) => {
      return {
        name: employee.name,
        email: getEmailFromName(employee.name),
        id: employee.id,
      }
    })
    // const response = await savePicassoObjectives(objectiveList.levelCards, newEmplooyeeInfo, newEmployeeWithIdInfo)
    // navigate(`/${routes.meetingMultiView}`,{
    //     state: {fromSetUpAgent: true}
    // })
    // navigate(0)
    console.log('blah')
    setIsConfirmEmailModalOpen(true)
  }

  return (
    <ApplicationLayout fromSetUpAgent={true}>
      {isConfirmEmailModalOpen && localStorage.getItem('is_demo') === 'true' && (
        <ConfirmEmailModal
          isModalOpen={isConfirmEmailModalOpen}
          closeModal={() => {
            setIsConfirmEmailModalOpen(false)
          }}
          newEmployee={newEmployee}
          objectiveList={objectiveList}
          setIsSendEmailModalOpen={setIsSendEmailModalOpen}
          newEmployeeWithId={newEmployeeWithId}
          setEmailInfo={setEmailInfo}
        />
      )}
      {isConfirmEmailModalOpen && localStorage.getItem('is_demo') !== 'true' && (
        <MatchEmployeesModal
          isModalOpen={isConfirmEmailModalOpen}
          closeModal={() => {
            setIsConfirmEmailModalOpen(false)
          }}
          newEmployee={newEmployee}
          objectiveList={objectiveList}
          setIsSendEmailModalOpen={setIsSendEmailModalOpen}
          newEmployeeWithId={newEmployeeWithId}
        />
      )}
      {isSendEmialModalOpen && (
        <SendMessageModal
          isModalOpen={isSendEmialModalOpen}
          closeModal={() => {
            setIsSendEmailModalOpen(false)
          }}
          emailInfo={emailInfo}
        />
      )}
      <div style={{ display: 'block', marginTop: '3rem', display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '65%', overflow: 'hidden', marginTop: '3rem' }}>
          <ObjectiveMapByObjective
            objectiveList={degradedCanvas}
            activitiesModalClickHander={() => {}}
            setIsObjHistoryOpen={() => {}}
            setObjId={() => {}}
            setIsAddEditObjModalOpen={() => {}}
            setIsL1L2ModalOpen={() => {}}
            setLastClickedLevel={setLastClickedLevel}
            editedObj={editedObj}
            lastClickedLevel={lastClickedLevel}
            setObjModalMode={() => {}}
            setObjData={setObjData}
            setActivitiesModalMode={setActivitiesModalMode}
            setIsNotificationModalOpen={setIsNotificationModalOpen}
            setIsRequestModalOpen={setIsRequestModalOpen}
            singleObjId={singleObjId}
            setSingleObjId={setSingleObjId}
            setIsKPIModalOpen={setIsKPIModalOpen}
            setIsViewFilesModalOpen={setIsViewFilesModalOpen}
            setOpenOnSpecificKpi={setOpenOnSpecificKpi}
            loadAgain={loadAgain}
            ShowDeleteObjectiveModal={ShowDeleteObjectiveModal}
            ShowCompleteObjectiveModal={ShowCompleteObjectiveModal}
            expandedMode={true}
            fromSetUpAgent={true}
          />
        </div>
        <div
          style={{
            width: '30%',
            marginRight: '2rem',
            marginTop: '3rem',
            marginLeft: '1rem',
            borderLeft: '1px solid grey',
            borderRight: '1px solid grey',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '1.15rem',
              backgroundColor: '#13599B',
              color: 'white',
              opacity: '0.74',
              height: '3rem',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingLeft: '1rem',
              gap: '1rem',
            }}
          >
            <span class="material-symbols-outlined">chat_bubble</span>
            <span> {t('Common:companyAiSetupAgent.yourAiConsultant')} </span>
          </div>
          <ChatBox messageList={messageList} isLoading={isLoading} />
          {
            <div
              className="review-generated-topics-list-item"
              style={{
                width: '95%',
                marginTop: '1rem',
                marginLeft: '1rem',
                marginRight: '1rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {isRecording === false && (
                <TextArea
                  type="text"
                  onChange={(e) => {
                    handleChangeAnswer(e)
                  }}
                  value={answer}
                  style={{
                    height: '5rem',
                    backgroundColor: 'white',
                    width: '90%',
                    border: '1px solid grey',
                  }}
                  spellCheck={false}
                />
              )}
              {answer && (
                <div
                  onClick={(e) => {
                    sendMessages()
                  }}
                  style={{
                    cursor: 'pointer',
                    color: 'rgb(91,132,203)',
                    fontSize: '1.1rem',
                    marginTop: '0.5rem',
                    alignItems: 'center',
                    marginLeft: '0.5rem',
                  }}
                >
                  <span class="material-symbols-outlined icon-size-xl">send</span>
                </div>
              )}

              {answer === '' && (
                <div
                  style={{
                    cursor: 'pointer',
                    color: 'rgb(91,132,203)',
                    fontSize: '1.1rem',
                    marginTop: '0.5rem',
                    alignItems: 'center',
                    marginLeft: '0.5rem',
                    width: isRecording ? '100%' : 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {isRecording && (
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '20%',
                      }}
                    >
                      {formatTime(seconds)}
                    </div>
                  )}
                  <div style={{ width: '80%' }}>
                    <AudioToTextButtonV2
                      startRecording={false}
                      setAudioText={setAudioText}
                      setIsRecording={setIsRecording}
                      fromSetUpAgent={true}
                      setCancelVoice={setCancelVoice}
                    />
                  </div>
                </div>
              )}
            </div>
          }
          {isRecording && (
            <div
              style={{
                marginTop: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                color: 'rgb(91,132,203)',
                fontSize: '1.1rem',
              }}
            >
              <span style={{ fontSize: '0.8rem' }}>
                {' '}
                {t('Common:companyAiSetupAgent.upTO60Seconds')}
              </span>
            </div>
          )}
          {/* {isObjectiveMapFinished && } */}
          {isObjectiveMapFinished && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
                marginLeft: '1.5rem',
              }}
            >
              {/* <div onClick={saveObjectives}
                            class="sleek-button sleek-full-blue"
                            style={{ display: 'flex', width: '1rem', fontSize: '1rem', height: '2rem', marginTop: '1rem', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                        >
                            {t('commonButtons.back')}
                        </div> */}
              <div
                onClick={saveObjectives}
                class="sleek-button sleek-full-blue"
                style={{
                  display: 'flex',
                  width: '3rem',
                  fontSize: '1rem',
                  height: '2rem',
                  marginTop: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {t('commonButtons.finalize')}
              </div>
            </div>
          )}
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default CompanySetupAiAgent
